export interface IValidateFooterNavItem {
  message: string;
  type: ValidateSittingType;
}

export interface IValidateFooterNav {
  isValid: boolean;
  items: IValidateFooterNavItem[];
}

export enum ValidateSittingType {
  Covers = 1,
  Service = 2,
  Date = 3,
  Time = 4,
  Section = 5,
  MenuOptions = 6,
  CoversPending = 7
}
