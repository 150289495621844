import React, { ReactElement } from 'react';
import {Observable, from, of} from "rxjs";
import {switchMap, map, catchError, first} from "rxjs/operators";
import {
    bookingStatusType,
    IBookingPayment,
    servicePaymentType,
    IErrorResponse,
    IManageBookingSubset,
    IPaymentDetailsGenericData,
    IProcessPayment,
    IResponse,
    IPrepareEwayData,
    IPrepareEwayFunctionData,
    IFunctionPaymentSummaryResponseData,
    IEwayForm,
    ICreditCardEncrypted,
    ISavePreAuthData,
    ISavePreAuthResponseData,
    IProcessStripePayment,
    bookingErrorType,
    IStripePaidData,
    IProcessEwayPaymentResponse,
    IEwaySummaryResponseData,
    loadStatus,
    IHasPromoCodeResponseData,
    IHasPromoCode, IPromoCodeResponseData, IApplyPromoCode,
    ISetupPreAuth3DResponseData,
    IFinalizePreAuth3DData,
    ISetupPreAuth3DData,
    ISetupPreAuth3DResponse,
    IFinalizePreAuth3DResponse
} from "shared-types/index";
import {IntlService} from "shared-services/intl-service";
import { noCase } from 'change-case';
import ExternalLinkContainer from 'internal-components/ExternalLink/container';
import { externalLinkType } from 'shared-components/external-link/types';
import {PaymentApiRequests} from "./paymentApiRequests";
import {ErrorMessageService} from "shared-services/error-message-service";
import { IFinalizePreAuthExistingCardData, IFinalizePreAuthExistingCardResponse } from './payment.types';

const NS = 'PaymentService';

/** Error collection processed during form validation. */
export type TPaymentServiceErrors = {[error: string]: string};

interface IVenueSubset {
    id: number;
    preAuthReleasingWindow: number;
}


export class PaymentService {

    static isAmex(cardNumber: string): boolean {
        return cardNumber[0] === '3';
    }

    /**
     * Rejects string with anything but numbers
     */
    static validateNumbersOnly(val: string): boolean {
        return !/^[0-9]*$/.test(val);
    }

    /**
     * Adds errors for CVC, talking into account different rules for AMEX
     */
    static addCVCValidationErrors(amexCardEntered: boolean, cvc: string, errors: TPaymentServiceErrors): void {
        const amexCVCLength = 4;
        const otherCVCLength = 3;

        if (!cvc) {
            errors.cvc = 'You forgot to enter your CVC number';
        } else if (this.validateNumbersOnly(cvc)) {
            errors.cvc = 'Please only enter digits';
        } else {
            if (cvc.length < (amexCardEntered ? amexCVCLength : otherCVCLength)) {
                errors.cvc = 'Your number is too short';
            } else if (cvc.length > (amexCardEntered ? amexCVCLength : otherCVCLength)) {
                errors.cvc = 'Your number is too long';
            }
        }
    }

    /**
     * Adds errors for card number, talking into account different rules for AMEX
     */
    static addCardNumberValidationErrors(amexCardEntered: boolean, cardNumber: string, errors: TPaymentServiceErrors): void {
        const amexCardNumberLength = 15;
        const otherCardNumberLength = 16;

        if (!cardNumber) {
            errors.cardNumber = 'You forgot to enter your card number';
        } else if (this.validateNumbersOnly(cardNumber)) {
            errors.cardNumber = 'Please only enter digits';
        } else {
            if (cardNumber.length < (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
                errors.cardNumber = 'Your number is too short';
            } else if (cardNumber.length > (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
                errors.cardNumber = 'Your number is too long';
            }
        }
    }

    /**
     * Adds errors for expiry year or month
     */
    static addExpiryValidationErrors(expiry: string, type: 'Month' | 'Year', errors: TPaymentServiceErrors): void {
        if (!expiry) {
            errors[`expiry${type}`] = `You forgot to enter your expiry ${type.toLowerCase()}`;
        } else if (PaymentService.validateNumbersOnly(expiry)) {
            errors[`expiry${type}`] = 'Please only enter digits';
        } else {
            const value: number = parseInt(expiry, 10);
            let invalid: boolean = expiry.length !== 2;
            if (type === 'Month' && (value < 1 || value > 12)) {
                invalid = true;
            }

            if (invalid) {
                errors[`expiry${type}`] = `${type} is invalid`;
            }
        }
    }

    /**
     * Determines if booking is in Payment Pending state.
     * @param status
     * @param payment
     */
    static isPaymentPending(status: bookingStatusType, payment: IBookingPayment): boolean {
        return status === bookingStatusType.pendingPayment ||
            payment && (
                // there is a bug where a service can still have price when paymentType is noPayment, so must check both
                payment.price > 0 && payment.paymentType !== servicePaymentType.noPayment
            ) && (
                !payment.amountPaid ||
                payment.amountPaid < payment.price
            );

    }

    static checkForUnconfirmedAndPaid(booking: IManageBookingSubset): boolean {
        /**
         * 'isPaid' here checks if the booking has been fully paid for. This can include a pre-auth that the customer
         * has confirmed via the payment gateway - however, if the pre-auth has been released, then it is no longer considered
         * paid.
         */
        const isPaid = booking.payment
            && booking.payment.price > 0
            && booking.payment.amountPaid
            && booking.payment.amountPaid >= booking.payment.price;

        return booking.status === bookingStatusType.unconfirmed && isPaid;

    }

    static getStandbyPaidNoTableMessage(phone: string, currency?: string, amountPaid = 0, isPreAuth = false): string {
        return `This booking is on the standby list and does not have an allocated table${
            amountPaid > 0
                ? `, but you have ${ isPreAuth ? 'pre-authorised' : 'made' } a payment of ${IntlService.currencyValueAsString(amountPaid, currency)}. Please check the status of your booking with the venue on ${phone}.`
                : `. Please confirm with the venue before making payment on ${phone}.`
        }`;
    }

    static getPaymentMessage(paymentType: servicePaymentType, currency: string, price: number, priceIsPerPerson = true, bgColor?: string): ReactElement {
        const isPreAuth = paymentType === servicePaymentType.preAuth;
        return <>A
        {!isPreAuth ? <> {noCase(paymentType)} </> : null}
            {isPreAuth ? <> credit card Booking Guarantee </> : null}
            <>of </>
            <span className="secondary-text">
        {IntlService.currencyValue(price, currency)}
      </span>
            &nbsp;{priceIsPerPerson ? 'per person' : '' } is required.
            {isPreAuth ?
                <> Funds will be verified, but not charged to your card at this time.&nbsp;
                    <ExternalLinkContainer label={'View Booking Guarantee Policy.'} type={externalLinkType.preAuth} bgColor={bgColor} />
                </>
                : null
            }</>
    }


    static processStripe3DSecurePayment(
        bookingId: string,
        stripe: stripe.Stripe,
        venueId: number,
        card: stripe.elements.Element
    ): Observable<IProcessPayment> {

        return PaymentApiRequests.paymentIntent3DSecure(bookingId, venueId)
            .pipe(
                first(),
                switchMap(({data}: IResponse<string>) => this.makeStripePayment(
                    // stripePayment$
                    from(stripe.confirmCardPayment(data, {payment_method: {card}})),

                    // successPaidCallback
                    (paymentIntent: stripe.paymentIntents.PaymentIntent) => {
                        const amountAs2Decimals = paymentIntent ? paymentIntent.amount / 100 : null;
                        return PaymentApiRequests.finilisePayment3DSecure(bookingId, venueId, paymentIntent.id, amountAs2Decimals);
                    })
                ))
    }


    static processStripe3DSecurePFPayment(
        eventId: string,
        stripe: stripe.Stripe,
        venueId: number,
        card: stripe.elements.Element
    ): Observable<IProcessPayment> {

        return PaymentApiRequests.getPaymentIntent3DSecureForPF(eventId, venueId)
            .pipe(
                first(),
                switchMap(({data}: IResponse<string>) => this.makeStripePayment(
                    // stripePayment$
                    from(stripe.confirmCardPayment(data, {payment_method: {card}})),

                    // successPaidCallback
                    (paymentIntent: stripe.paymentIntents.PaymentIntent) => {
                        const amountAs2Decimals = paymentIntent ? paymentIntent.amount / 100 : null;
                        return PaymentApiRequests.finalisePayment3DSecureForPF(eventId, venueId, paymentIntent.id, amountAs2Decimals);
                    })
                ))
    }

    static processStripePreAuth(
        bookingId: string, venue: IVenueSubset,
        stripeInstance: stripe.Stripe,
        card: stripe.elements.Element
    ): Observable<IProcessStripePayment> {

        return PaymentService.proccessPreAuthStripe3D(bookingId, venue.id, stripeInstance, card).pipe(
            map(data => ({
                successPayload: data,
                errorPreauthPayload: null
            })),
            catchError(err => {
                console.warn("Error during stripe3d preauth");
                return of({
                    errorStripePayload: {
                        stripeError: err,
                        backEndError: null
                    }
                })
            }
            ));
    }

    static proccessPreAuthStripe3D(
        bookingId: string,
        venueId: number,
        stripe: stripe.Stripe,
        card: stripe.elements.Element): Observable<any>{

          return PaymentService.setupPreAuthStripe3D(bookingId, venueId).pipe(
        switchMap((setupResponse: ISetupPreAuth3DResponse) => {
          return PaymentService.finalizePreAuthStripe3D(bookingId,venueId,setupResponse.data,stripe, card);
        })
      );
    }

    static setupPreAuthStripe3D(
      bookingId: string,
      venueId: number
    ) : Observable<ISetupPreAuth3DResponse>{
      //TODO:
      const setupPreAuthRequest: ISetupPreAuth3DData ={
        venueId: venueId,
        bookingId: bookingId
      }
      return PaymentApiRequests.setupPreAuthStripe3D(setupPreAuthRequest);
    }

    static finalizePreAuthStripe3D(
      bookingId: string,
      venueId: number,
      setupPreAuthResponse: ISetupPreAuth3DResponseData,
      stripe: stripe.Stripe,
      card: stripe.elements.Element
      ): Observable<IFinalizePreAuth3DResponse> {
      return  from(stripe.confirmCardSetup(setupPreAuthResponse.clientSecret, {payment_method: {card: card}}))
      .pipe(
          switchMap((setupIntentResponse: stripe.SetupIntentResponse) => {
            if (setupIntentResponse.error) {
                throw setupIntentResponse.error;
              }
            const finalizeRequest: IFinalizePreAuth3DData = {
                venueId: venueId,
                bookingId: bookingId,
                tokenCustomerId: setupPreAuthResponse.tokenCustomerId,
                setupIntentId: setupPreAuthResponse.setupIntentId
            }
            return PaymentApiRequests.finalizePreAuthStripe3D(finalizeRequest)
          } )
      );
    }


    static payNowEway(
        venueId: number,
        bookingId: string
    ): Observable<{success: boolean, data: IPrepareEwayData | IErrorResponse}> {
        // does not include private functions
        return PaymentApiRequests.payNow({venueId, bookingId}).pipe(
            first(),
            map(({data}) => ({success: true, data})),
            catchError((err: {response: IErrorResponse}) => {
                const error = {...err};
                console.log('error.response', error.response)
                return of({success: false, data: error.response});
            })
        )
    }


    static payPFNowEway(
        venueId: number,
        eventId: string
    ): Observable<{success: boolean, data: IPrepareEwayFunctionData | IErrorResponse}> {
        // does not include private functions
        return PaymentApiRequests.eventPaynow({venueId, eventId}).pipe(
            first(),
            map(({data}) => ({success: true, data})),
            catchError((err: {response: IErrorResponse}) => {
                const error = {...err};
                console.log('error.response', error.response)
                return of({success: false, data: error.response});
            })
        )
    }

    /**
     * processes eway payment and retrieves the payment summary info
     */
    static handleEwayStandardPayment(
      venueId: number,
      eventId: string,
      bookingId: string,
      formEl: HTMLFormElement
    ): Observable<{
        success?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData,
        errorType?: bookingErrorType,
        errorWithCode?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData,
        genericError?: IErrorResponse
    }> {
        return this.processEwayStandardPayment(formEl)
          .pipe(
            first(),
            switchMap(({error, success}: {error?: bookingErrorType, success?: IProcessEwayPaymentResponse}) => {
                if (error) {
                    return of({errorType: error});
                }
                return this.getEwayPaymentSummary(venueId, bookingId, eventId, success.AccessCode);
            })
          );
    }

    /**
     * processes eway payment and retrieves the payment summary info
     */
    static handleEwayPreauthPayment(
      venueId: number,
      bookingId: string,
      customerId: string,
      formEl: HTMLFormElement,
      clientSideEncryptKey: string,
      preAuthReleasingWindow: number
    ): Observable<{
        success?: ISavePreAuthResponseData,
        errorType?: bookingErrorType,
        errorMessage?: string
    }> {
        const ewayForm: IEwayForm = {
            EWAY_CARDNAME: formEl.EWAY_CARDNAME.value,
            EWAY_CARDNUMBER: formEl.EWAY_CARDNUMBER.value,
            EWAY_CARDEXPIRYMONTH: formEl.EWAY_CARDEXPIRYMONTH.value,
            EWAY_CARDEXPIRYYEAR: formEl.EWAY_CARDEXPIRYYEAR.value,
            EWAY_CARDCVN: formEl.EWAY_CARDCVN.value,
        }

        const params: ISavePreAuthData = {
            venueId,
            bookingId,
            customerId,
            creditCardDto: this.getEncryptedCardDetails(ewayForm, clientSideEncryptKey),
            preAuthReleasingWindow: preAuthReleasingWindow || 24
        };

        return PaymentApiRequests.savePreAuth(params)
          .pipe(
              first(),
              map((response) => {
                  console.log('savePreAuth response', response)
                  return {success: response.data}
              }),
              catchError((err: {response: IErrorResponse}) => {
                  const error = {...err};
                  console.warn(NS, 'savePreAuth error (eway) ', error);
                  return of({
                      errorType: ErrorMessageService.getPaymentErrorTypeFromStatus(error.response.status),
                      errorMessage: error.response?.data?.message || ''
                  })
              })
        );
    }

    static handleHasPromoCode(bookingId: string, venueId: number): Observable<IHasPromoCode> {
        return PaymentApiRequests.hasPromoCode(bookingId, venueId)
          .pipe(
            first(),
            map(({data}: IResponse<IHasPromoCodeResponseData>) => {
                if (data) {
                    return {status: loadStatus.success, showPromoCode: data.showPromoCode};
                }
                console.warn(NS, 'handleHasPromoCode response has no data');
                return {status: loadStatus.failed, showPromoCode: false};
            })
          )
    }
    static handleApplyPromoCode(promotionCode: string, bookingId: string, venueId: number): Observable<IApplyPromoCode> {
        return PaymentApiRequests.applyPromoCode(promotionCode, bookingId, venueId)
          .pipe(
            first(),
            map((response: IResponse<IPromoCodeResponseData & {message?: string}>) => {
                console.log(NS, 'response.data 1', response)
                if (response.data) {
                    return {
                        codeFailMsg: null,
                        payment: {
                            ...response.data,
                            promotionCode
                        }
                    };
                }

                if (response.status === 404) {
                    console.warn(NS, 'handleApplyPromoCode response returned 404');
                    return {
                        codeFailMsg: bookingErrorType.bookingExpired,
                        payment: null
                    }
                }


                const msg = response.data ? response.data.message : null;
                return {
                    codeFailMsg: msg || 'Unknown error',
                    payment: null
                }
            }),
            catchError((err: {response: IErrorResponse}) => {
                const error = {...err};
                console.warn('handleApplyPromoCode error', error);
                return of({
                    codeFailMsg: 'Unknown error',
                    payment: null
                })
            })
          )
    }


    /**
     * Pretty anaemic, only added here to prevent adding additional dependencies on PaymentApiRequests
     * Handles finalizing a preauth payment with an existing card for stripe3d
     */
    static finalizePreAuth3DExistingCard(params: IFinalizePreAuthExistingCardData) :Observable<IFinalizePreAuthExistingCardResponse> {
        return PaymentApiRequests.finalizePreAuth3DExistingCard(params);
    }

    /**
     * Handles finalizing a preauth payment with an existing card for Eway
     */
    static finalizePreAuthEwayExistingCard(params: IFinalizePreAuthExistingCardData): Promise<IFinalizePreAuthExistingCardResponse> {
        return PaymentApiRequests.finalizePreAuthEwayExistingCard(params);
    }

    private static processEwayStandardPayment(
      formEl: HTMLFormElement
    ): Observable<{error?: bookingErrorType, success?: IProcessEwayPaymentResponse}> {
        return new Observable(observer => {

            if (!(window as any).eWAY) {
                console.warn(NS, 'Eway not loaded');
                observer.next({error: bookingErrorType.UKNOWN_USERPAYMENTERROR});
                return;
            }

            (window as any).eWAY.process(formEl, {
                autoRedirect: false,
                onComplete: (data: IProcessEwayPaymentResponse) => {
                    if (data.Errors) {
                        console.warn(NS, 'onComplete', 'data.Errors', data.Errors)
                        // @toDo work out what to do with these based on codes above
                        // errorService.processEwayError(data.Errors);
                    } else {
                        observer.next({success: data});
                    }
                },
                onError: (error: any) => {
                    console.warn(NS, 'onError', error);
                    observer.next({error: bookingErrorType.paymentServerError});
                },
                onTimeout: (error: any) => {
                    console.warn(NS, 'onTimeout', error);
                    observer.next({error: bookingErrorType.paymentTimeout});
                }
            });
        });
    }


    private static getEwayPaymentSummary(
      venueId: number,
      bookingId?: string,
      eventId?: string,
      ewayAccessCode?: string
    ): Observable<{
        success?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData,
        errorWithCode?: IEwaySummaryResponseData | IFunctionPaymentSummaryResponseData,
        genericError?: IErrorResponse,
    }> {

        if (eventId) { // this is for private functions only, not regular events
            return this.getFunctionPaymentSummaryWithError(venueId, null, eventId, ewayAccessCode)
              .pipe(
                map(({data, status, statusText}) => ({
                    success: data.success ? data : null,
                    errorWithCode: data.success ? null : data,
                    genericError: data ? null : {status, statusText}
                }))
              )
        }

        return PaymentApiRequests.ewayPaymentSummary(bookingId, venueId, ewayAccessCode)
          .pipe(
            first(),
            map(({data}) => ({success: data})),
            catchError((err: {response: IErrorResponse}) => {
                const error = {...err};
                console.warn(NS, 'getPaymentSummary error', error.response);
                return of({genericError: error.response});
            })
          )
    }

    // this is for private functions only, not regular events
    private static getFunctionPaymentSummaryWithError(
        venueId: number,
        stripePaymentToken?: string,
        eventId?: string,
        ewayAccessCode?: string
    ): Observable<IResponse<IFunctionPaymentSummaryResponseData>> {
        return PaymentApiRequests.getFunctionPaymentSummary(venueId, eventId, ewayAccessCode, stripePaymentToken)
            .pipe(
                first(),
                catchError(err => {
                    return of({
                        statusText: err.StatusText,
                        status: err.status,
                        data: null
                    })
                })
            );
    }


    /**
     * Payment logic for either Stripe 3D Secure or standard Stripe payment
     * stripePayment$: either `PaymentService.paymentIntent3DSecure` or `PaymentService.processStripePayment`
     * backendPayment$: either `PaymentService.finilisePayment3DSecure` or `payNow`
     */
    private static makeStripePayment(
        stripePayment$: Observable<stripe.PaymentMethodResponse>,
        successPaidCallback: (paymentIntent: stripe.paymentIntents.PaymentIntent) => Observable<{data: IStripePaidData}>
    ): Observable<IProcessPayment> {

        return stripePayment$
            .pipe(
                switchMap((stripeResponse: stripe.PaymentMethodResponse | stripe.PaymentIntentResponse) => {

                    return (stripeResponse.error
                            ? of(null)
                            : successPaidCallback((stripeResponse as stripe.PaymentIntentResponse).paymentIntent || null) // paymentIntent will be null for non 3d secure payments
                    )
                        .pipe(
                            // tap(response => console.log(NS, 'processStripePayment', response)),
                            map((response: IResponse<IStripePaidData>) => ({
                                backendPayData: response ? response.data : null,
                                stripeResponse
                            })),
                            catchError(err => {
                                const error = {...err};
                                console.warn(NS, 'paynow error', error)

                                return of({
                                    backendPayData: {error: error.response},
                                    stripeResponse
                                });
                            })
                        );
                }),
                map(({backendPayData, stripeResponse}) => {
                    const backendErrorResponse = backendPayData && (backendPayData as { error: IErrorResponse });
                    const hasPayNowError = !!(backendErrorResponse && backendErrorResponse.error);
                    if (stripeResponse.error || hasPayNowError) {
                        return {
                            errorPayload: {
                                stripeError: hasPayNowError ? null : stripeResponse.error,
                                backEndError: hasPayNowError ? backendErrorResponse.error : null
                            }
                        }
                    }

                    /**
                     * SUCCESS
                     */
                    const {transactionId, amountPaid} = backendPayData as IStripePaidData;
                    return {
                        successPayload: {
                            transactionId,
                            amountPaid,
                            response: stripeResponse
                        }
                    }
                }),
                catchError((err: { response: IErrorResponse }) => {
                    const error = {...err};
                    console.warn(NS, 'processStripePayment error', error);

                    return of({
                        errorPayload: {
                            stripeError: null,
                            backEndError: error.response
                        }
                    })
                })
            );
    }


    private static getEncryptedCardDetails(ewayForm: IEwayForm, clientSideEncryptKey: string): ICreditCardEncrypted {

        return {
            title: '',
            name: ewayForm.EWAY_CARDNAME,
            number: (window as any).eCrypt.encryptValue(ewayForm.EWAY_CARDNUMBER, clientSideEncryptKey),
            expiryMonth: ewayForm.EWAY_CARDEXPIRYMONTH,
            expiryYear: ewayForm.EWAY_CARDEXPIRYYEAR,
            cvn: (window as any).eCrypt.encryptValue(ewayForm.EWAY_CARDCVN, clientSideEncryptKey)
        };
    }



}
