import moment from "moment";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ISchedule, IScheduleTime } from "shared-types/WidgetTypes";

/** Moment.js style time format to use if not declared by the venue localization. */
const DEFAULT_TIME_FORMAT = "h:mm a";

/** Moment.js style time format for specific locales. */
const LOCALE_FORMAT = {
  "en-CA": "h:mm a",
  "fr-CA": "H:mm",
  fr: "H [h] mm",
};

/** Regular expressions that match times of the form "3:15pm". */
const reTimeFormat = /^(\d+):(\d+)\s*(am|pm)$/;

/**
 * Returns a delegate that localizes {@link IScheduleTime} or `string` items
 * using the given time formatting string. If {@link item} is a `string`, then
 * it is assumed to be in the form "3:15pm".
 * @param timeFormat Moment.js style format string to use to format the times.
 */
export const localizeTime =
  (timeFormat: string | undefined) =>
  <T extends Pick<IScheduleTime, "name" | "time"> | string>(item: T): T => {
    if (typeof item === "object" && "name" in item && "time" in item) {
      // IScheduleTime item.
      const i = item as Pick<IScheduleTime, "name" | "time">;
      return { ...i, name: moment(i.time).format(timeFormat) } as T;
    } else {
      // String item of the form "3:15pm".
      const match = reTimeFormat.exec(item as string);
      if (match) {
        let h = +match[1];
        const m = +match[2];
        const pm = match[3].startsWith("p");
        if (h === 12 && !pm) {
          // 12:00am --> 0:00
          h -= 12;
        } else if (h !== 12 && pm) {
          //  1:00pm  -> 13:00 (but 12:00pm -> 12:00)
          h += 12;
        }
        const strH = h.toString().padStart(2, "0");
        const strM = m.toString().padStart(2, "0");
        return moment(`${moment().format("YYYY-MM-DD")} ${strH}:${strM}`).format(
          timeFormat
        ) as T;
      }
    }
    return item;
  };

/**
 * Hook that returns a localizer function for {@link IScheduleTime}-related time
 * items and returns the time based on the current locale.
 */
export const useLocalizeTime = () => {
  const { i18n } = useTranslation();
  const timeFormat =
    LOCALE_FORMAT[i18n.language as keyof typeof LOCALE_FORMAT] ??
    DEFAULT_TIME_FORMAT;

  const localize = useCallback(localizeTime(timeFormat), [timeFormat]);
  return {
    localizeTime: localize,
  };
};

// /**
//  * Hook that returns a localizer function that accepts a string of the form
//  * "3:15pm" and returns the time based on the current locale.
//  */
// export const useTimeFormatter = () => {
//   const { i18n } = useTranslation();
//   const timeFormat =
//     LOCALE_FORMAT[i18n.language as keyof typeof LOCALE_FORMAT] ??
//     DEFAULT_TIME_FORMAT;
//   const timeFormatter = useCallback(
//     (time: string): string => {
//       const match = reTimeFormat.exec(time);
//       if (match) {
//         const h = +match[1] + (match[3].startsWith("p") ? 12 : 0);
//         const m = +match[2];
//         return moment(`${moment().format("YYYY-MM-DD")} ${h}:${m}`).format(
//           timeFormat
//         );
//       }
//       return time;
//     },
//     [i18n.language]
//   );
//   return {
//     timeFormatter,
//   };
// };
