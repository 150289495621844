import {ClassNameMap, StyleRules} from "@material-ui/styles/withStyles";
import {makeStyles} from "@material-ui/styles";
import {IWidgetTheme} from "app/models";
import {ThemeColorsService} from "app/services/theme/themeColors.service";
import {createStyles} from "@material-ui/core";

const NS = 'DayPicker';

export function getDynamicStyles(isNarrow: boolean): ClassNameMap<"calender" | "customToolbarIsOutlinedDark" | "customToolbarIsOutlinedLight" | "customToolbar" | "customToolbarIsWide" | "customToolbarIsOutlinedDarkLandscape"> {

  /**
   * Note: see src/app/services/theme/themeColors.service.ts for modifications to Material UI theme styles
   */

  const useStyles = makeStyles((theme: IWidgetTheme) => {

    const {primary, textColor1, textColor2} = ThemeColorsService.getCommonThemeStyles(theme);

    let calender: any = {
      '& .MuiPickersBasePicker-container': {
        justifyContent: 'center'
      },
      '& .MuiPickersDay-daySelected': {
        backgroundColor: textColor2,
        color: theme.palette.getContrastText(textColor2),
      },
      '& .MuiIconButton-root': {
        padding: 7
      },
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        backgroundColor: 'transparent'
      }
    };

    if (isNarrow) {
      calender = {
        ...calender,
        '& .MuiPickersCalendarHeader-dayLabel': {
          width: 35
        },
        '& .MuiPickersDay-day': {
          width: 35
        },
        '& .MuiPickersBasePicker-pickerView': {
          minWidth: 300,
          maxWidth: 300
        },
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
          backgroundColor: 'transparent',
          minWidth: 300
        }
      }
    }

    return createStyles({
      calender,
      customToolbar: {
        backgroundColor: textColor1,
        color: theme.palette.getContrastText(textColor1),
      },
      customToolbarIsOutlinedLight: {
        color: textColor2,
        backgroundColor: 'white',
        borderBottom:`1px solid ${primary[200] || primary.main}`,
        '& h1,h2,h3,h4,h5': { // target all the headings
          color: textColor1
        },
        '& h6': {
          color: textColor2
        }
      },
      customToolbarIsOutlinedDark: {
        backgroundColor: 'transparent',
        borderBottom:`1px solid white`,
        '& h1,h2,h3,h4,h5': { // target all the headings
          color: textColor1
        },
        '& h6': {
          color: textColor2
        }
      },
      customToolbarIsWide: {
        borderBottom:`none`,
        borderRight:`1px solid ${primary[200] || primary.main}`,
      },
      customToolbarIsOutlinedDarkLandscape: {
        borderRight:`1px solid white`,
      },

    })
  });

  return useStyles();
}
