import React from "react";
import {IMenuOptionPaymentType} from "../types";
import {renderIf} from "shared-services/react-utils-service/index";
import {Typography} from "@material-ui/core";
import style from "../narrow/menuOptionNarrow.style.module.scss";
import {SimplePaletteColorOptions} from "@material-ui/core/styles";
import {PricingTypes, servicePaymentType} from "shared-types/index";
import { Trans, useTranslation } from "react-i18next";

export const MenuOptionPaymentType: React.FC<IMenuOptionPaymentType> = ({
  theme, paymentTypeError, paymentType, bgColor, pricingType,
  extrasHasPayment, currencyValue, bookingCancellationTimeWindowInHours, hoursText
}: IMenuOptionPaymentType & { children?: any }) => {
  const { t } = useTranslation("payment");
  return (
    <>
      {renderIf(paymentTypeError, () => (
        <Typography
          data-testid="payment-calc-error-message"
          variant="body1"
          className={style.paymentText}
          style={{color: (theme.palette.error as SimplePaletteColorOptions).main}}
        >
          <Trans t={t}>
            Unable to calculate payment type. Check connection.
          </Trans>
        </Typography>
      ), () => (
        renderIf(paymentType && pricingType, (
          <Typography variant="body1" className={style.paymentText} data-testid="payment-text">
            {
              (() => {
                switch (paymentType) {
                  case servicePaymentType.preAuth:
                    return pricingType === PricingTypes.PerPax ? (
                      <Trans t={t}>
                        A Booking Guarantee of {{currencyValue}} is required per person.
                      </Trans>
                    ): (
                      <Trans t={t}>
                        A Booking Guarantee of {{currencyValue}} is required per booking.
                      </Trans>
                    );

                  case servicePaymentType.fullPayment:
                    return pricingType === PricingTypes.PerPax ? (
                      <Trans t={t}>
                        A full payment is required per person.
                      </Trans>
                    ) : (
                      <Trans t={t}>
                        A full payment is required per booking.
                      </Trans>
                    );

                  case servicePaymentType.deposit:
                    return pricingType === PricingTypes.PerPax ? (
                      <Trans t={t}>
                        A deposit is required per person.
                      </Trans>
                    ) : (
                      <Trans t={t}>
                        A deposit is required per booking.
                      </Trans>
                    );

                  case servicePaymentType.noPayment:
                    return extrasHasPayment
                      ? <></> 
                      : (
                        <Trans t={t}>
                          No payment is required.
                        </Trans>
                      );

                  default:
                    return <></>
                }
              })()
            }
          </Typography>
        ))
      ))}
    </>
  )

}
