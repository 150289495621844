import React from 'react';
import {IOrHRule} from './types';
import style from './style.module.scss';
import {themeTypes} from "app/models";
import classNames from "classnames";


const NS = 'OrHRule';

export default function OrHRule({showText, theme}: IOrHRule) {

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  return (
    <>
      <div className={style.root} data-testid="or-h-rule">
        <div className={classNames({
          [style.line1]: true,
          [style.line1IsDark]: isDark
        })}/>
        {showText && (
          <>
            <div className={classNames({
              [style.line2]: true,
              [style.line2IsDark]: isDark
            })}/>
          </>
        )}
      </div>
      {/*{showText && (*/}
      {/*  <>*/}
      {/*    <div className={classNames({*/}
      {/*      [style.orText]: true,*/}
      {/*      [style.orTextIsDark]: isDark*/}
      {/*    })} data-testid="or-h-rule-text">*/}
      {/*      OR*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  )
}
