
import React from 'react';
import style from './style.module.scss';
import { noCase } from 'change-case';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Paper, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import SimplePage from 'shared-components/simple-page/index';
import { renderIf } from 'app/services/utils/utils.service';
import { IntlService } from 'app/services/intl/intlService';
import {servicePaymentType} from "shared-types/index";
import { useTranslation } from 'react-i18next';

const NS = 'PaymentComplete';


export default function PaymentComplete({ theme, payment, currency, message, tableData }: IStateFromProps & IDispatchFromProps) {

  const isPreauth = payment.paymentType === servicePaymentType.preAuth;
  const { t } = useTranslation("payment");
  return (
    <SimplePage
      theme={theme}
      title={t(`${isPreauth ? 'Booking Guarantee' : 'Payment'} Confirmation`)}
      body={message}
      bodyIsMarkDown={true}
      >

      {renderIf(isPreauth, (
        <Typography variant="body1">
          {t('Your credit card Booking Guarantee was successful.')}
        </Typography>
      ), (
        <Typography variant="body1">
          {t(`Your ${noCase(payment.paymentType)} was successful.`)} {
            IntlService.currencyValue(payment.amountPaid, currency)
          }
        </Typography>
      ))}

      <div className={style.tableWrap}>
        <Table aria-label="summary" size="small" >
          <TableBody>
            {tableData.items.map((item, j) => !item.value ? null : (
              <TableRow key={`payment-conf-item-${j}`}>
                <TableCell title={item.name}>{t(item.name)} ({currency}) {item.prefix}</TableCell>
                <TableCell >{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

    </SimplePage>
  )
}

