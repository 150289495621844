import React, { useEffect, useState, useMemo } from 'react';
import {IVenue, themeTypes} from 'app/models';
import moment, { Moment } from 'moment';
import { IRootState } from 'app/reducers';
import { IActionGen } from 'app/types/common.types';
import { useDispatch, useSelector } from 'react-redux';
import { SECTION_ANY_ID } from '../SectionSelector/types';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { AvailableTimeList } from '../TimePicker/AvailableTimeList';
import { StandbyActionsNS } from 'app/actions/standby/standbyActions';
import { ISchedule } from 'app/services/client/client.types';
import DateUtilsService from 'shared-services/date-utils-service';
import { IAvailableTimeListProps, ISelectableTime } from '../TimePicker/types';
import { Fab, makeStyles, Typography } from '@material-ui/core';
import { IChangedActiveServiceUpdate, IChangedBookingTime } from 'app/actions/booking/interfaces';
import { LoadScheduleActions } from "app/actions/loadSchedule/loadScheduleActions";
import { BookingService } from 'app/services/booking/booking.service';
import JoinStandbyButton from "app/components/JoinStandbyButton";
import OrHRule from "app/components/OrHRule";
import {INextAvailableBookingDatePanel} from "./types";
import {STANDBY_EXHAUSTED_MSG} from "app/services/message/message.service";
import { renderIf } from 'app/services/utils/utils.service';
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IScheduleTime, IScheduleService} from "shared-types/index";
import { TimeFilterService } from 'app/services/timeFilter/timeFilter.service';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const NS = 'NextAvailableBookingDatePanel';



interface navigationProps {
    isLookForward: boolean;
}

export const getMultiServiceNames = (availableServices: IScheduleService[], thisDate: string, t: TFunction): string => {
  if (availableServices?.length > 1) {
    let names = ''
    availableServices.map((s, i) => {
      if (i === availableServices.length-1) {
        names = names + ` ${t('and')} ` + s.name;
      } else if (i === 0) {
        names = s.name;
      } else {
        names = names + ', ' + s.name;
      }
    })
    return t(`However, {{names}} for {{thisDate}} have availability.`, {
      names,
      thisDate
    });
  } else if (availableServices?.length === 1) {
    return t(`However, {{name}} service for {{thisDate}} has availability.`, {
      name: availableServices[0].name,
      thisDate
    });
  } else {
    return ''
  }
}

/** Displays next available times when tables are unavailable */
export const NextAvailableBookingDatePanel = ({ theme, showBottomHRule, standbyExhausted }: INextAvailableBookingDatePanel) => {
  const { t } = useTranslation("nextAvailableBooking");
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const useStyles = makeStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 0px 20px'
    },
    nextAvailableDate: {
      marginRight: '10px'
    },
    selectButton: {
      padding: '6px 8px'
    },
    navigationButton: {
      margin: '0 5px'
    },
    standbyMsg: {
      padding: '10px 0 15px'
    },
    showTimesBtn: {
      fontSize: '1.4rem',
      cursor: 'pointer',
      textDecoration: 'underline',
      marginLeft: '10px'
    },
    noTimesMessage: {
      marginBottom: 3,
      fontWeight: 'bold',
      fontSize: '1.5rem',
      color: isDark ? theme.palette.error.light : theme.palette.error.main
    },
    noAvailMessage: {
      width: '290px',
      color: isDark ? theme.palette.error.light : theme.palette.error.main
    },
    standbyExhaustedMsg: {
      margin: '20px 0'
    }
  });

    const classes = useStyles();
    const dispatch = useDispatch();
    const [availableBookingTimes, setAvailableBookingTimes] = useState<IScheduleTime[]>([]);
    const [nabAvailableServices, setNabAvailableServices] = useState<IScheduleService[]>([]);
    const [navigation, setNavigation] = useState<navigationProps>({isLookForward: true});
    const [areBookingTimesUnavailable, setUnavailableBookingTimes] = useState<boolean>(false);
    const widget = useSelector((state: IRootState) => state.widget);
    const [getNextAvailableSchedules, setNextAvailableSchedule] = useState<ISchedule>(null);
    const [shouldShowAvailableTimes, toggleAvailableTimesVisibility] = useState<boolean>(false);

    const {
        booking, activeService, activeVenue, standbyData,schedule
    } = widget;

    //Get available services and build NAB message
    const availableServices:IScheduleService[] = [];
    schedule?.services?.forEach((service:IScheduleService) => {
      const filteredTimes =  TimeFilterService.getFilteredTimesNonMutated(service.times, true, null, booking.utcTime);
       // To check if any tables are available. If not then hide Additional Options Panel.
       if(filteredTimes && filteredTimes.length > 0){
          const percentUnavailable: number = filteredTimes ? (filteredTimes.filter(t => t.isDisabled && !t.expired).length / filteredTimes.length) * 100 : 0;
          const noTablesAvailable: boolean = filteredTimes && filteredTimes.some(t => t.isDisabled && !t.expired) && percentUnavailable >= 100;
          if(!noTablesAvailable){
            availableServices.push(service);
          }
       }
    });

    const thisDate = moment(booking.viewDate).format("DD MMM");
    const nabNoServiceMessage = activeService
      ? t("No times available for {{serviceName}} on {{thisDate}}", {
        serviceName: activeService.name,
        thisDate
      })
      : t("No times available on {{thisDate}}", { thisDate });
    const nabOtherServiceMessage = getMultiServiceNames(availableServices, thisDate, t);

    const loadNABSchedules = async () => {
      const date = getAvailableDate() || booking.moment;
      const combinedTimeList: ISelectableTime[] = [];

      // @todo: no way to cancel previous schedule call. Possible race condition. Recommend using Observable instead so can be easily cancelled with each call.
      const nabSearchResponse = await LoadScheduleActions.loadNextAvailableScheduleForBooking(activeVenue as IVenue, booking.covers, date, activeService, navigation.isLookForward);

      if ((!nabSearchResponse?.schedule || !nabSearchResponse.schedule.services?.length) || nabSearchResponse.isTimeOut) {
        setUnavailableBookingTimes(true);
        const scheduleTime = {} as IScheduleTime;
        scheduleTime.time = nabSearchResponse?.lastSearchDate;
        setAvailableBookingTimes([scheduleTime]);
        return;
      }

      const nabAvailableServices:IScheduleService[] = [];
      nabSearchResponse.schedule.services.forEach(service => {
        let filteredTimeList = TimeFilterService.getFilteredTimesNonMutated(service.times, true, null, booking.utcTime);
        if(filteredTimeList.filter(item => item.isDisabled == false).length >0){
          nabAvailableServices.push(service);
        }
        // Remove disabled and duplicate times
        filteredTimeList = filteredTimeList.filter(item => item.isDisabled == false && !combinedTimeList.some(f => f.name === item.name));
        // Add service to each time
        filteredTimeList.forEach(time => time.serviceId = service.id);
        combinedTimeList.push(...filteredTimeList);
      });

      // Display AM time before PM time
      combinedTimeList.sort((a: ISelectableTime,b: ISelectableTime) => {
        return moment(a.time).diff(b.time);
      });
      setNabAvailableServices(nabAvailableServices);
      setAvailableBookingTimes(combinedTimeList);
      setUnavailableBookingTimes(false);
      setNextAvailableSchedule(nabSearchResponse.schedule);
    }

    useEffect(() => {
      loadNABSchedules();
    }, [booking.moment, navigation]);

    const isStandbyMode = !!standbyData;

    const getAvailableDate = () => {
        let availableDate = null;
        if (availableBookingTimes?.length > 0) {
            availableDate = moment(availableBookingTimes[0].time);
        }
        return availableDate;
    }

    const setIsLookForward = (value: boolean) => {
        const selectedNavigation: navigationProps = {
            isLookForward: value
        }
        setNavigation(selectedNavigation);
    }

    /**
     * Update the store to change the selected date and time, service and section
     */
    const onTimeSelection = (selectedTime: ISelectableTime) => {

        const time = moment(selectedTime.time);

        // Set selected date
        dispatch({type: BookingActionsTypes.CHANGED_BOOKING_DATE, payload: time} as IActionGen<Moment>);

        // Set services
        dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS, payload: getNextAvailableSchedules} as IActionGen<ISchedule>);

        // Set active service
        dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE, payload: {serviceId: selectedTime.serviceId, clearSelectionMenuOptions: true}} as IActionGen<IChangedActiveServiceUpdate>);

        // Set selected time
        dispatch({type: BookingActionsTypes.CHANGED_BOOKING_TIME, payload: selectedTime.time} as IChangedBookingTime);

        // sets the active section for new service
        dispatch({type: BookingActionsTypes.SET_ACTIVE_SECTION_FROM_ACTIVE_SERVICE} as IActionGen<void>);
    }

    const onStandbyButtonClicked = () => {
        dispatch(StandbyActionsNS.changedStandbyMode(true));
    }

    const availableTimeListProps: IAvailableTimeListProps = {
        gridColumns: 3,
        isStandbyMode,
        timeNotAvailableMessage: "",
        times: availableBookingTimes, //.slice(0, 10),
        handleSelect: onTimeSelection,
        nabAvailableServices: nabAvailableServices,
        isFlexibleTime: (standbyData && standbyData.isFlexibleTime) || false
    }

  const toggleAvailableTimesButtonText = shouldShowAvailableTimes 
    ? t("Hide Times")
    : t("Show Times");
  const blockoutMessageEnabled = activeService && activeService.isBlockoutPartiallyEnabled;
  const shouldShowStandbyButton = BookingService.isStandbyEligible(widget) && !blockoutMessageEnabled;
  const bookingTimesUnavailableMsg = areBookingTimesUnavailable
    ? <Typography className={classes.noAvailMessage} data-testid="no-nab-availability-message">
      <Trans t={t}>
        Can't find availability to accommodate selected booking size
      </Trans>
    </Typography>
    : null;

    
  const maxDaysInFutureBooking = activeVenue?.widgetSettings?.maxDaysInFutureBooking ?? 9999;
  
  const venueTime = BookingService.getVenueTime(activeVenue as IVenue);
  const today = useMemo(() => {
    return moment(venueTime).startOf('day');
  }, [venueTime]);

  const currentAvailableDate = useMemo(() => {
    return getAvailableDate();
  }, [availableBookingTimes]);

  const maxFutureBookingDate = useMemo(() => {
    return moment(today).add(maxDaysInFutureBooking, 'days');
  }, [today, maxDaysInFutureBooking]);
  
  let isDisabledNextButton = false;
  let isDisabledPrevButton = false
  
  const displayDateMom = useMemo(() => {
    return currentAvailableDate ? moment(currentAvailableDate).startOf('day') : null;
  }, [currentAvailableDate]);

  if (currentAvailableDate) {
    isDisabledNextButton = displayDateMom.isSameOrAfter(maxFutureBookingDate);
    isDisabledPrevButton = displayDateMom.isSameOrBefore(today);
  }

  const keepSearchingMsg = !isDisabledNextButton 
    ? t("Keep selecting forward to continue searching.")
    : null;
  
  const currentAvailableDateRaw = moment(currentAvailableDate).toDate()
  return (
    <>
      {currentAvailableDate ? (
        <>
          <Typography className={classes.noTimesMessage}>
            {nabNoServiceMessage}
            {
              renderIf(nabOtherServiceMessage && schedule.isVenueOpen, () => (
                <Typography className={classes.noTimesMessage}>
                  {nabOtherServiceMessage}
                </Typography>
              ))
            }
          </Typography>


          <Typography>
            <Trans t={t}>
              Next available booking date and times below:
            </Trans>
          </Typography>
          <div className={classes.root}>
            <Typography className={classes.nextAvailableDate}>{moment(currentAvailableDateRaw).format("DD MMM YYYY")}</Typography>
            <Fab className={classes.navigationButton} color="primary" size="small" aria-label="previous"
                 disabled={isDisabledPrevButton}
                 onClick={() => setIsLookForward(false)}>
              <ArrowLeft/>
            </Fab>
            <Fab className={classes.navigationButton} color="primary" size="small" aria-label="next"
                 disabled={isDisabledNextButton} onClick={() => setIsLookForward(true)}>
              <ArrowRight/>
            </Fab>
            {areBookingTimesUnavailable &&
            <Typography className={classes.noAvailMessage} data-testid="keep-searching-message">
              <Trans t={t}>
                We searched up until {{date: moment(currentAvailableDateRaw).format("Do MMM")}} and couldn't find any availability.
              </Trans>{" "}
              {keepSearchingMsg}
            </Typography>
            }
            {
              !areBookingTimesUnavailable &&
              <Typography
                data-e2e="show-times-btn"
                className={classes.showTimesBtn}
                onClick={() => toggleAvailableTimesVisibility(!shouldShowAvailableTimes)}>
                {toggleAvailableTimesButtonText}
              </Typography>
            }
          </div>
          {shouldShowAvailableTimes && <AvailableTimeList {...availableTimeListProps} />}
        </>) : bookingTimesUnavailableMsg
      }

          {shouldShowStandbyButton && standbyExhausted && (
            <>
              <OrHRule showText={false} theme={theme}/>
              <Typography data-testid="standby-exhausted-message" className={classes.standbyExhaustedMsg} variant="body2">
                {STANDBY_EXHAUSTED_MSG}
              </Typography>
            </>
          )}

          { shouldShowStandbyButton && !standbyExhausted && (
            <>
              <OrHRule showText={true} theme={theme} />
              <JoinStandbyButton handleClicked={onStandbyButtonClicked} />
            </>
          ) }
          {showBottomHRule && (
            <OrHRule showText={true} theme={theme} />
          )}
        </>
    )
}
