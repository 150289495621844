import React, {useEffect, useState} from 'react';
import { IChildMenuOptionImplicitList } from './types';
import style from './style.module.scss';
import { IServicePaymentOption, IBookingMenuOption, IWidgetTheme } from 'shared-types/index';
import { Grid } from '@material-ui/core';
import MenuOption from '../MenuOption';
import { menuOptionType } from '../MenuOption/types';
import CoverInput from 'shared-components/cover-input/index';
import { coverInputVariant } from 'shared-components/cover-input/types';
import IframeResizerService from "shared-services/iframe-resizer-service";
import BookingOptionsHelpers from "shared-components/booking-options-section/helpers";
import classNames from "classnames";

const NS = 'ChildMenuOptionImplicitList';

export default function ChildMenuOptionImplicitList({
  theme, menuOptions, currency, wrapperStyle, selectedMenuOptions,
  gridSizes, gridGutter, isUpsell,
  handleSpinnerUpdate
}: IChildMenuOptionImplicitList) {

  const [combinedCovers, setCombinedCovers] = useState<IBookingMenuOption[]>([]);

  useEffect(() => {
    const updatedCombinedCovers = menuOptions ?
      menuOptions.map(opt => {
        const selectedOpt = !selectedMenuOptions ? null : selectedMenuOptions.find(o => o.menuOptionId === opt.id);
        return {menuOptionId: opt.id, quantity: selectedOpt ? selectedOpt.quantity : 0};
      })
      : [];
    setCombinedCovers(updatedCombinedCovers)
  }, [selectedMenuOptions])

  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const useGrid = !!gridGutter || isUpsell;

  return (
    <div className={style.root}>
      {(() => (
        BookingOptionsHelpers.muiGridOrCustomElementOuter(useGrid, style.grid, style.box, gridGutter, (
          <>
            {menuOptions && menuOptions.map(({id, label, description, price, link}: IServicePaymentOption) => (
              BookingOptionsHelpers.muiGridOrCustomElementInner(id, useGrid, {
                xs: 6, sm: gridSizes[1], md: gridSizes[2], lg: gridSizes[3]
              }, "", style.boxItems, null, (
                <div className={style.listItem}
                     data-testid={`menu-option`}
                     data-test-uid={id}
                >
                  <MenuOption
                    isStacked={isStacked}
                    id={id}
                    theme={theme}
                    currency={currency}
                    price={price}
                    label={label}
                    description={description}
                    link={link}
                    type={menuOptionType.spinners}
                    isUpsell={isUpsell}
                    extrasHasPayment={false}>

                    {(() => {
                      const menuOpt: IBookingMenuOption = combinedCovers.find(cc => cc.menuOptionId === id);
                      return (
                        <CoverInput
                          theme={theme as IWidgetTheme}
                          wrapperStyle={wrapperStyle}
                          hasDelay={false}
                          coversPrefill={menuOpt?.quantity || 0}
                          maxPeoplePerBooking={1000}
                          minPeoplePerBooking={0}
                          variant={coverInputVariant.small}
                          omitMaxReachedMsg={true}
                          allowMaxBreach={false}
                          handleChange={quantity => {
                            const newValue = combinedCovers.map((item: IBookingMenuOption) => item.menuOptionId === id  ?  {menuOptionId: id, quantity}  :  item);
                            setCombinedCovers(newValue);
                            handleSpinnerUpdate(newValue);
                          }} />
                      )
                    })()}

                  </MenuOption>
                </div>
                ))
            ))}
          </>
        ))
      ))()}
    </div>
  )
}
