import React, { useState } from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import PaymentDetailsStripe from 'shared-components/payments-section/paymentDetailsStripe';
import PaymentSummaryContainer from '../PaymentSummary/container';
import ColumnWrap2Container from '../ColumnWrap2/container';
import { renderIf } from 'app/services/utils/utils.service';
import { PaymentDetailsGeneric } from 'shared-components/payments-section/paymentDetailsGeneric';
import PaymentFooterNav from 'shared-components/payments-section/paymentFooterNav';
import { paymentFooterNavTypes } from 'shared-components/payments-section/paymentFooterNav/types';
import { IProgressStep } from '../ProgressStepper/types';
import ProgressStepper from '../ProgressStepper';
import GroupedTablesBox from '../GroupedTablesBox';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PaymentAgree from '../PaymentAgree';
import PaymentPromo from '../PaymentPromo';
import { PaymentService } from 'app/services/payment/payment.service';
import LoaderOverlay from '../LoaderOverlay';
import { Helmet } from 'react-helmet';
import { IApplyPromoCode } from 'app/actions/booking/interfaces';
import { IframeResizerService } from "app/services/iframeResizer/iframeResizer.service";
import { IPaymentDetailsGenericData, IPrepareEwayData, IPrepareEwayFunctionData, servicePaymentType } from "shared-types/index";
import { useTranslation } from 'react-i18next';

const NS = 'Payments';

export default function Payments({
  theme, wrapperStyle, isStripe, stripePublishableKey, venuePaymentSettings, currency,
  customerDetails, bookingDetails, payment, stripeInstance, footerNavShowCancel, hasPromoCodes, isReuseCreditCardMode, cardLast4, isExistingBooking, bookedByDetails, isBookedBy,
  // defining empty values here helps with mocking in style guide
  prefilledPaymentDetails = null,
  handlePrepareEwayPayment, handleEwayPaymentSubmit, handleStripeLoaded, handleStripePaymentSubmit,
  handleCancelBooking, handlePromotionCode, handleConfirmState, handleStripePaymentExistingCardSubmit,
  handleEwayPaymentExistingCardSubmit
}: IStateFromProps & IDispatchFromProps) {
  const { t } = useTranslation("payment");
  const formRef: React.RefObject<HTMLFormElement> = React.createRef();


  /**
   * No need to keep user's entered `paymentDetails` in the global store.
   * Local state in sufficient.
   */
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetailsGenericData>({
    name: '', cardNumber: '', expiryMonth: '', expiryYear: '', cvc: ''
  });
  const [nextAttempted, setNextAttempted] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [ewayPaymentStep1Data, setEwayPaymentStep1Data] = useState<IPrepareEwayData | IPrepareEwayFunctionData>();
  const [stripePaymentStep1Ready, setStripePaymentStep1Ready] = useState<boolean>(false);

  const [stripeCard, setStripeCard] = useState<stripe.elements.Element>(null);
  const [stripeToken, setStripeToken] = useState<stripe.Token>(null);

  const [promoCodeErrorMessage, setPromoCodeErrorMessage] = useState<string>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isUseExistingCard, setIsUseExistingCard] = useState<boolean>(false);

  const stepIndex: number = isStripe ? (
    // stripe
    stripePaymentStep1Ready ? 1 : 0
  ) : (
    // eway
    ewayPaymentStep1Data ? 1 : 0
  );

  const cvcImagePath = '/assets/images/cards-cvc.jpg';

  // payments route doesn't use the main router to switch steps, routeName not required
  const steps: IProgressStep[] = [{
    label: 'Payment',
    routeName: null
  }, {
    label: 'Confirmation',
    routeName: null
  }];

  const handleNavPressed = async (type: paymentFooterNavTypes) => {
    if (stepIndex === 0 && type === paymentFooterNavTypes.next) {
      if (isStripe) {
        handleConfirmState();
        setStripePaymentStep1Ready(true);
      } else {
        setIsLoading(true);
        handlePrepareEwayPayment()
          .then((paymentStep1Data: IPrepareEwayData | IPrepareEwayFunctionData) => {
            handleConfirmState();
            setIsLoading(false);
            setEwayPaymentStep1Data(paymentStep1Data);
          });
      }
    }

    if (stepIndex === 1 && type === paymentFooterNavTypes.prev) {
      if (isStripe) {
        setStripePaymentStep1Ready(false);
      } else {
        setEwayPaymentStep1Data(null);
      }
    }

    if (stepIndex === 1 && (
      type === paymentFooterNavTypes.pay ||
      type === paymentFooterNavTypes.preauth
    )) {
      setIsLoading(true);

      if (isStripe) {
        if (isUseExistingCard) {
          handleStripePaymentExistingCardSubmit()
            .then(() => setIsLoading(false));
        } else {
          handleStripePaymentSubmit(stripeCard, stripeToken, paymentDetails)
            .then(() => setIsLoading(false));
        }
      } else {
        if (isUseExistingCard) {
          handleEwayPaymentExistingCardSubmit()
            .then(() => setIsLoading(false));
        } else {
          const formEl: HTMLFormElement = formRef.current;
          formEl.action = ewayPaymentStep1Data.formActionUrl;
          handleEwayPaymentSubmit(formEl)
            .then(() => setIsLoading(false));
        }
      }
    }

    if (type === paymentFooterNavTypes.cancel) {
      handleCancelBooking();
    }

    IframeResizerService.notifyParentIFrameOfSectionChange("Payments");
  }

  /**
   * Handler for changes to use existing card options.
   */
  function handleIsUseExistingCardChange(isUseExistingCard: boolean) {
    setIsUseExistingCard(isUseExistingCard);
    setIsValid(isUseExistingCard);
  }

  /**
   * Form element reference is needed for the submission
   * Eway Documentation:
   * - https://eway.io/api-v3/?csharp#transparent-redirect
   * - https://eway.io/api-v3/?csharp#encrypt-function
   */
  const getEwayHiddenForm = ({ accessCode }: IPrepareEwayData | IPrepareEwayFunctionData, { name, cardNumber, expiryMonth, expiryYear, cvc }: IPaymentDetailsGenericData) => (
    <form ref={formRef} >
      <input type="hidden" name="EWAY_ACCESSCODE" value={accessCode} />
      <input type="hidden" name="EWAY_PAYMENTTYPE" value="Credit Card" />
      <input type="hidden" name="EWAY_CARDNAME" value={name} />
      <input type="hidden" name="EWAY_CARDNUMBER" value={cardNumber} />
      <input type="hidden" name="EWAY_CARDEXPIRYMONTH" value={expiryMonth} />
      <input type="hidden" name="EWAY_CARDEXPIRYYEAR" value={expiryYear} />
      <input type="hidden" name="EWAY_CARDCVN" value={cvc} />
    </form>
  )

  return (
    <>
      {/*
        Needed to use a local loader, rather than global because the Eway payment uses a Promise to change the local state,
        but a global redux-triggered loader would cause the page to unmount.
       */}
      {renderIf(isLoading, () => (
        <LoaderOverlay theme={theme} message="Loading" />
      ))}

      {renderIf(!isStripe, () => (
        <Helmet>
          <script src="https://api.ewaypayments.com/JSONP/v3/js" async></script>
          <script src="https://secure.ewaypayments.com/scripts/eCrypt.min.js" async></script>
        </Helmet>
      ))}

      <div className={style.progressStepper}>
        <ProgressStepper activeStep={stepIndex} steps={steps} wrapperStyle={wrapperStyle} theme={theme} />
      </div>
      <ColumnWrap2Container>
        {/* left */}
        <div x-ms-format-detection="none">
          {renderIf(venuePaymentSettings, () => (
            renderIf(stepIndex === 0, () => (
              <PaymentSummaryContainer />
            ), () => (
              <GroupedTablesBox groups={isBookedBy ? [bookingDetails, customerDetails, bookedByDetails] : [bookingDetails, customerDetails]} wrapperStyle={wrapperStyle} />
            ))
          ))}
        </div>


        {/* right */}
        <div className={style.column2}>

          {renderIf(venuePaymentSettings, () => (
            <>
              <div>
                {renderIf(isStripe, () => (
                  // can't unmount the stripe elements until payment is confirmed, so hide them for step 2
                  <div className={classNames({
                    [style.stripeWrapIsHidden]: stepIndex !== 0
                  })}>
                    <PaymentDetailsStripe
                      cvcImagePath={cvcImagePath}
                      wrapperStyle={wrapperStyle}
                      publishableKey={stripePublishableKey}
                      stripeInstance={stripeInstance}
                      theme={theme}
                      triedNext={nextAttempted}
                      handleUpdate={(card: stripe.elements.Element, token: stripe.Token, paymentDetails: IPaymentDetailsGenericData, isValid: boolean) => {
                        setIsValid(isValid);
                        setStripeCard(isValid ? card : null);
                        setStripeToken(isValid ? token : null);
                        setPaymentDetails(isValid ? paymentDetails : null);
                      }}
                      handleStripeLoaded={handleStripeLoaded}
                      allowExistingCreditCard={isReuseCreditCardMode}
                      existingCreditCardLast4={cardLast4}
                      handleUseExistingCardChange={(isUseExistingCard) => handleIsUseExistingCardChange(isUseExistingCard)}
                    >
                      {renderIf(hasPromoCodes, () => (
                        <PaymentPromo
                          wrapperStyle={wrapperStyle}
                          handleApply={(promotionCode: string) => {
                            setIsLoading(true);
                            handlePromotionCode(promotionCode)
                              .then((data: IApplyPromoCode) => {  // PRETTY SURE HANDEL
                                setIsLoading(false);
                                setPromoCodeErrorMessage(data.codeFailMsg);
                              });
                          }}
                          promotionCode={payment.promotionCode}
                          errorMessage={t(promoCodeErrorMessage)}
                        />
                      ))}
                      <PaymentAgree
                        wrapperStyle={wrapperStyle}
                        showError={nextAttempted && !isAgreed}
                        paymentType={payment.paymentType}
                        handleChange={(checked: boolean) => {
                          setIsAgreed(checked);
                        }} />
                    </PaymentDetailsStripe>
                  </div>
                ))}

                {renderIf(stepIndex === 0, () => (
                  <>
                    {renderIf(!isStripe, () => (
                      // EWAY: Enter payment details
                      <PaymentDetailsGeneric
                        cvcImagePath={cvcImagePath}
                        wrapperStyle={wrapperStyle}
                        theme={theme}
                        paymentDetails={prefilledPaymentDetails || paymentDetails}
                        venuePaymentSettings={venuePaymentSettings}
                        handleUpdate={(paymentDetails: IPaymentDetailsGenericData, isValid: boolean) => {
                          setIsValid(isValid);
                          setPaymentDetails(isValid ? paymentDetails : null);
                        }}
                        allowExistingCreditCard={isReuseCreditCardMode}
                        existingCreditCardLast4={cardLast4}
                        handleUseExistingCardChange={(isUseExistingCard) => handleIsUseExistingCardChange(isUseExistingCard)}
                      >
                        {renderIf(hasPromoCodes, () => (
                          <PaymentPromo
                            wrapperStyle={wrapperStyle}
                            handleApply={(promotionCode: string) => {
                              setIsLoading(true);
                              handlePromotionCode(promotionCode)
                                .then((data: IApplyPromoCode) => {
                                  setIsLoading(false);
                                  setPromoCodeErrorMessage(data.codeFailMsg);
                                });
                            }}
                            promotionCode={payment.promotionCode}
                            errorMessage={t(promoCodeErrorMessage)}
                          />
                        ))}
                        <PaymentAgree
                          wrapperStyle={wrapperStyle}
                          showError={nextAttempted && !isAgreed}
                          paymentType={payment.paymentType}
                          handleChange={(checked: boolean) => {
                            setIsAgreed(checked);
                          }} />
                      </PaymentDetailsGeneric>
                    ))}
                  </>
                ), () => (
                  // else confirm payment details (step 2)
                  <div className={style.detailsSummaryTable}>
                    <GroupedTablesBox wrapperStyle={wrapperStyle}
                      groups={[PaymentService.getPaymentDetailGroup(payment, paymentDetails, currency)]}>
                      <Typography variant="body1" className={style.agreeText}>
                        {payment.paymentType === servicePaymentType.preAuth
                          ? t('By clicking the authorise button, you are agreeing to the terms and conditions.')
                          : t('By clicking the make payment button, you are agreeing to the terms and conditions.')
                          }
                      </Typography>
                      {renderIf(!isStripe, !ewayPaymentStep1Data ? null : getEwayHiddenForm(ewayPaymentStep1Data, paymentDetails))}
                    </GroupedTablesBox>
                  </div>
                ))}
              </div>


              <PaymentFooterNav
                theme={theme}
                wrapperStyle={wrapperStyle}
                nextType={stepIndex === 0 ? paymentFooterNavTypes.next : (
                  payment.paymentType === servicePaymentType.preAuth
                    ? paymentFooterNavTypes.preauth
                    : paymentFooterNavTypes.pay
                )}
                nextEnabled={stepIndex === 0 ? (isValid && isAgreed) : true}
                prevEnabled={stepIndex === 1}
                showCancel={footerNavShowCancel}
                isCancelChanges = {isExistingBooking}
                handleNavPressed={handleNavPressed}
                handleDisabledNextPressed={() => {
                  setNextAttempted(true);
                }} />
            </>
          ))}
        </div>

      </ColumnWrap2Container>
    </>
  )
}

