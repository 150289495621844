import React from 'react';
import style from './style.module.scss';
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Dialog as MuiDialog,
  CircularProgress, IconButton
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {IStateFromProps, IDispatchFromProps, IOwnProps} from './types';
import {renderIf} from 'shared-services/react-utils-service';
import ChildMenuOptionImplicitList from '../ChildMenuOptionImplicitList';
import {IBookingMenuOption, IBookingMenuOptionExtrasUpdater} from 'shared-types/index';
import classNames from 'classnames';
import ChildMenuOptionExplicitList from '../ChildMenuOptionExplicitList';
import IframeResizerService from "shared-services/iframe-resizer-service/index";
import CloseIcon from '@material-ui/icons/Close';
import { Trans, useTranslation } from 'react-i18next';

const NS = 'ChildMenuOptionOverlay';

const Dialog = withStyles((theme) => {
  const { palette: { type, common, text } } = theme
  return {
    root: {
      "& .MuiPaper-root": {
        backgroundColor: type === 'light' ? common.white : common.black,
      },
    },
    paper: {}
}})(MuiDialog);

export default function ChildMenuOptionOverlay({
  wrapperStyle, theme, currency, selectionData, menuOptionsImplicit, menuOptionsExplicit,
  handleClose, isUpsell, gridGutter, handleDone
}: IStateFromProps & IDispatchFromProps & IOwnProps) {
  const { t } = useTranslation("extras");
  const isLoading: boolean = !menuOptionsImplicit && !menuOptionsExplicit;

  const gridXs = 12;
  const gridSm = isUpsell ? 12 : 6;
  const gridMd = isUpsell ? 12 : 4;
  const gridLg = isUpsell ? 12 : 3;

  // if only 1 quantity amount left, then we default 'isSameForAll' to true, since you can't have a carousel with only 1 item
  if (selectionData && selectionData.parentQuantity === 1) {
    selectionData.isSameForAll = true;
  }

  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const hasExplicitOpts = menuOptionsExplicit && menuOptionsExplicit.length;

  const nextText = isUpsell ? t('PROCEED') : t('DONE');

  return (
    // Note: don't use MuiDialog/withStyles as it causes flickering between loading and loaded states
    <Dialog
      data-testid="children-menu-option-overlay"
      classes={{
        paper: isStacked ? style.paperIsStacked : style.paper
      }}
      maxWidth="md"
      disableBackdropClick
      open={!!selectionData}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      {renderIf(selectionData, () => (
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          classes={{
            root: isStacked ? style.titleIsStacked : style.title
          }}>
          {renderIf(!isUpsell, () => (
            <Typography variant="h4">
              <Trans t={t}>
                Additional options for ‘{{parentLabel: selectionData.parentLabel}}’
              </Trans>
            </Typography>
          ), () => (
            <>
              <Typography variant="h2" data-testid={`parent-label`}>
                {selectionData.parentLabel}
              </Typography>
              <Typography variant="h6" data-testid={`upsell-desc`}>
                {selectionData.upsellDescription}
              </Typography>
              <IconButton data-testid={`skip-button`} aria-label="close" className={style.closeButton} onClick={() => handleClose('close')}>
                <CloseIcon/>
              </IconButton>
            </>
          ))}
        </DialogTitle>
      ))}

      <DialogContent
        classes={{
          root: style.content
        }}
      >
        <div className={classNames({
          [style.contentInner]: true,
          [style.contentInnerIsStacked]: isStacked,
          [style.isLoading]: isLoading
        })}>
          {renderIf(isLoading, () => (
            <CircularProgress/>
          ), () => (
            <div>
              {/* 'explicit' items have a carousel, with a slide for each 'parentQuantity', so each person can customise their quantities */}
              {renderIf(hasExplicitOpts, () => (
                <div className={classNames({
                  [style.explicitWrap]: true,
                  [style.explicitWrapIsStacked]: isStacked
                })}>
                  <ChildMenuOptionExplicitList
                    data-testid={`explicit-child-menuoption`}
                    wrapperStyle={wrapperStyle}
                    theme={theme}
                    parentQuantity={selectionData ? selectionData.parentQuantity : 0}
                    isSameForAll={selectionData ? selectionData.isSameForAll : true}
                    menuOptions={menuOptionsExplicit}
                    selectedMenuOptionsMDA={selectionData && selectionData.explicitChildMenuOptions || []}
                    currency={currency}
                    gridSizes={[gridXs, gridSm, gridMd, gridLg]}
                    gridGutter={gridGutter}
                    onChanged={(selectedMenuOptionsMDA: IBookingMenuOption[][], isSameForAll) => {
                      selectionData.explicitChildMenuOptions = selectedMenuOptionsMDA;
                      selectionData.isSameForAll = isSameForAll;
                    }}
                  />
                </div>
              ))}

              {/* 'implicit' items have quantity spinners and appear down bottom of modal */}
              {renderIf(menuOptionsImplicit && menuOptionsImplicit.length, () => (
                <div className={classNames({
                  [style.implicitWrap]: true,
                  [style.implicitWrapIsStacked]: isStacked
                })}>
                  {renderIf(hasExplicitOpts, () => (
                    <Typography variant="body1" className={style.implicitHeader}>
                      <Trans t={t}>
                        Further options
                      </Trans>
                    </Typography>
                  ))}
                  <ChildMenuOptionImplicitList
                    data-testid={`implicit-child-menuoption`}
                    menuOptions={menuOptionsImplicit}
                    wrapperStyle={wrapperStyle}
                    theme={theme}
                    currency={currency}
                    gridSizes={[gridXs, gridSm, gridMd, gridLg]}
                    gridGutter={gridGutter}
                    isUpsell={isUpsell}
                    selectedMenuOptions={selectionData && selectionData.implicitChildMenuOptions}
                    handleSpinnerUpdate={(selectedMenuOptions: IBookingMenuOption[]) => {
                      selectionData.implicitChildMenuOptions = selectedMenuOptions.filter(o => o.quantity > 0);
                    }}/>
                </div>
              ))}
            </div>
          ))}
        </div>

      </DialogContent>
      <DialogActions classes={{
        root: isStacked ? style.btnWrapIsStacked : style.btnWrap
      }} className={classNames({
        [style.buttonRight]: isUpsell
      })}>
        {renderIf(!isUpsell, () => (
          <Button onClick={() => handleClose('close')} color="default" variant="contained" className={style.btn}>
            <Trans t={t}>
              CANCEL
            </Trans>
          </Button>
        ))}

        <Button data-testid={`overlay-button`} disabled={isLoading} color="primary" variant="contained" className={style.btn}
                onClick={() => {
                  handleDone(selectionData);
                  handleClose('done');
                }}>
          {nextText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

