import {Dispatch} from "redux";
import {IAppSettings, IWidgetModel, modeType} from "app/models";
import {
  IPaymentType,
  ISchedule,
  paymentProviderTypeUppercase
} from "app/services/client/client.types";
import {SetupActionsNS} from "./setup/setupActions";
import {IAppLoadedPayload, IVenueLoadedPayload} from "./setup/interfaces";
import {IAction, IActionGen} from "app/types/common.types";
import {MocksService} from "app/services/mocks/mocks.service";
import {IBookingMenuOption, IBookingResponseData} from "app/services/booking/booking.types";
import {
  IChangedActiveServiceUpdate,
  ICoversUpdate,
  ISelectedMenuOption,
  IUpdateActiveChildMenuOptionPayload,
  IUpdateCachedChildMenuOptionDetails
} from "./booking/interfaces";
import {appLoadCompleteSuccess} from "./setup/helpers";
import {IParentMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {NavigationActionsNS} from "./navigation/navigationActions";
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {
  IServicePaymentOption,
  servicePaymentType,
  IScheduleService
} from "shared-types/index";

const NS = 'MockActions';

export namespace MockActionsNS {

  // action type
  export enum Type {
    UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS',
  }

  export interface IUpdateAppSettings extends IAction {
    payload?: IAppSettings;
  }



  const setActiveVenue = (VENUE_ID: number, dispatch: Dispatch, state: IWidgetModel, paymentProvider: paymentProviderTypeUppercase = paymentProviderTypeUppercase.Stripe) => {

    const ACCOUNT_ID = 'test1';

    dispatch({type: Type.UPDATE_APP_SETTINGS, payload: {
      ...state.appSettings,
      venueId: VENUE_ID
    }} as IUpdateAppSettings);

    dispatch({type: SetupActionsNS.Type.VENUE_LOAD_SUCCESS, payload: {
      queryStringParams: {
        accountid: ACCOUNT_ID,
        venueid: VENUE_ID.toString()
      },
      accountDetails: {
        "id": "109d9819-d0ee-479c-87b8-855f8bbfb266",
        "firstName": "Jim",
        "surname": "Doyle",
        "email": "jimdoyle82@gmail.com",
        "businessName": "Super Element",
        "businessId": "123",
        "businessUrl": null,
        "businessAddress": "20 Mascot St",
        "suburb": "Woy Woy",
        "postcode": "2256",
        "state": "NSW",
        "country": "AU",
        "active": true,
        "logoUrl": null,
        "contactNumber": "+61414562166",
        "alternativePhone": null,
        "currentBookingService": null,
        "ownedVenues": [
          MocksService.getOwnedVenue(VENUE_ID, paymentProvider),
        ],
        "accountPhone": "+61 414 888 999"
      },
      overrideStyleGuide: true, // this will omit style guide exceptions in reducers
      appSettings: {
        accountId: ACCOUNT_ID,
        venueId: VENUE_ID,

        mode: modeType.normal,
        // canDeleteBooking: boolean;
        isStyleGuide: true
      }
    } as IVenueLoadedPayload});
  }


  // thunk action creators

  // export const updateAppSettings = (appSettings: IAppSettings) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
  //   return new Promise(resolve => {
  //     dispatch({type: Type.UPDATE_APP_SETTINGS, payload: appSettings} as IUpdateAppSettings);
  //     resolve();
  //   })
  // };

  export const updatePayment = (payload: IPaymentType) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {

      const schedule: ISchedule = MocksService.getSchedule();

      dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS, payload: schedule} as IActionGen<ISchedule>);
      dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE, payload: {serviceId: schedule.services[0].id, clearSelectionMenuOptions: true}} as IActionGen<IChangedActiveServiceUpdate>);

      dispatch({type: NavigationActionsNS.Type.UPDATED_PAYMENT_TYPE, payload});
      resolve();
    });
  }

  export const setMockCustomerDetails = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {

      dispatch({type: BookingActionsTypes.CHANGED_CUSTOMER_DETAILS, payload: {
        customerDetails: MocksService.getCustomerDetails(),
        tags: [],
        isValid: true
      }});
      resolve();
    });
  }

  // just used for style guide
  export const setMockPaymentsData = (paymentProvider: paymentProviderTypeUppercase) => (dispatch: Dispatch, getState: () => any): Promise<void> => {

    return new Promise(resolve => {

      const VENUE_ID = 2;
      setActiveVenue(VENUE_ID, dispatch, getState(), paymentProvider);

      dispatch({type: SetupActionsNS.Type.APP_LOAD_SUCCESS, payload: {
        appSettings: {

        }
        // completeLoadStatus: true,
      } as IAppLoadedPayload});

      const covers = 2;
      dispatch({type: BookingActionsTypes.CHANGED_COVERS_COUNT, payload: {covers, clearSelectionMenuOptions: true }} as IActionGen<ICoversUpdate>);

      const MENU_OPT_ID = 'menuOption1';

      const schedule: ISchedule = MocksService.getSchedule();
      const lastService: IScheduleService = schedule.services[schedule.services.length - 1];
      if (lastService) {
        lastService.paymentDetails.options[0].id = MENU_OPT_ID;
      }
      dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS, payload: schedule} as IActionGen<ISchedule>);


      if (lastService) {
        dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE, payload: {serviceId: lastService.id, clearSelectionMenuOptions: true}} as IActionGen<IChangedActiveServiceUpdate>);
      }

      const menuOptions: IBookingMenuOption[] = [{
        menuOptionId: MENU_OPT_ID,
        quantity: 1
      }];
      dispatch({type: BookingOptionsActionsNS.Type.SELECTED_MENU_OPTION, payload: menuOptions} as ISelectedMenuOption);

      dispatch({type: BookingActionsTypes.SAVE_BOOKING_SUCCESS, payload: MocksService.getBookingResponseData(VENUE_ID) as IBookingResponseData});

      appLoadCompleteSuccess(dispatch);

      resolve();
    })
  }

  export const updateActiveChildMenuOption = (parentMenuOption: IParentMenuOption) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {

      // console.log(NS, 'getState().activeVenue', getState().activeVenue)
      // if (!getState().activeVenue) {
      //   const VENUE_ID = 2;
      //   setActiveVenue(VENUE_ID, dispatch, getState());
      //   console.log(NS, 'reload venue')
      // }

      const bottleOfRedWine: IServicePaymentOption = MocksService.getBookingOption('redWine');
      const gravy: IServicePaymentOption = MocksService.getBookingOption('gravy');

      setTimeout(() => {
        dispatch({type: BookingOptionsActionsNS.Type.UPDATE_ACTIVE_CHILD_MENU_OPTION, payload: {
          implicitChildMenuOptions: [
            bottleOfRedWine
          ],
          explicitChildMenuOptions: [
            gravy
          ]
        }} as IActionGen<IUpdateActiveChildMenuOptionPayload>);
        resolve();
      }, 1000); // simulate loading
    });
  }

  export const addCachedMenuOptionDetails = () => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {

      const bottleOfRedWine: IServicePaymentOption = MocksService.getBookingOption('redWine');
      const steak: IServicePaymentOption = MocksService.getBookingOption('steak');
      const gravy: IServicePaymentOption = MocksService.getBookingOption('gravy');



      dispatch({type: BookingOptionsActionsNS.Type.UPDATE_CACHED_MENU_OPTION_DETAILS, payload: {
        options: [
          { ...bottleOfRedWine },
          { ...steak },
          { ...gravy }
        ], fullRefresh: false
      }} as IUpdateCachedChildMenuOptionDetails);

      dispatch({type: BookingOptionsActionsNS.Type.SELECTED_MENU_OPTION, payload: [
        {
          quantity: 1,
          menuOptionId: steak.id,
          extras: {
            explicitChildMenuOptions: [
              [{ quantity: 1, menuOptionId: gravy.id }]
            ],
            implicitChildMenuOptions: []
          }
        }
      ]} as ISelectedMenuOption);

      resolve();
    });
  }

  export const getMockUpsellOptions = () : IServicePaymentOption[] => {
    return [
      {
        "id": "menuoption_MC5IGPKMEK8EA_1602569504744",
        "provider": "Nbi",
        "name": "Two course Food Package",
        "label": "Food",
        "description": null,
        "link": null,
        "tag": null,
        "paymentType": servicePaymentType.preAuth,
        "price": 49,
        "childMenuOptionIds": [],
        "explicitChildMenuOptionIds": []
      },
      {
        "id": "menuoption_50MGF3KMYAYPZ_1623127231709",
        "provider": "Nbi",
        "name": "Indian Food Package",
        "label": "Aaloo Methi",
        "description": null as any,
        "link": null as any,
        "tag": "tag_allergy",
        "paymentType": servicePaymentType.preAuth,
        "price": 50,
        "childMenuOptionIds": [] as any,
        "explicitChildMenuOptionIds": [] as any
      }
    ];
  }

  export const getFreeMockUpsellOptions = () : IServicePaymentOption[] => {
    return [
      {
        "id": "free_upsell_id",
        "provider": "Nbi",
        "name": "Pappad",
        "label": "Pappad",
        "description": null as any,
        "link": null as any,
        "tag": "tag_allergy",
        "paymentType": servicePaymentType.noPayment,
        "price": 0,
        "childMenuOptionIds": [] as any,
        "explicitChildMenuOptionIds": [] as any
      }
    ];
  }

}

