import React, { Component } from 'react';
import style from './style.module.scss';
import { StripeProvider, Elements, ReactStripeElements } from 'react-stripe-elements';
import { IPaymentDetailsStripe } from './types';
import PaymentCardStripe from '../paymentCardStripe/index';
import LocationService from 'shared-services/location-service/index';
import { IPaymentDetailsGenericData } from 'shared-types/index';
import { renderIf } from 'shared-services/react-utils-service/index';
import { Paper, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { UseExistingCreditCardEnum } from '../paymentDetailsGeneric/useExistingCreditCardEnum'
import { withTranslation } from 'react-i18next';

const NS = 'PaymentDetailsStripe';

interface IState {
  fontFamilyUrlFriendly: string;
  protocol: string;
  loadStripeLoading: boolean;
  loadStripePromise: (publishableKey: string) => Promise<any>;
  useExistingCreditCard: UseExistingCreditCardEnum;
}


class PaymentDetailsStripe extends Component<IPaymentDetailsStripe & { children?: any }, IState> {

  readonly state: IState = {
    fontFamilyUrlFriendly: null,
    protocol: null,
    loadStripeLoading: false,
    loadStripePromise: null,
    useExistingCreditCard: this.props.allowExistingCreditCard ? UseExistingCreditCardEnum.UseExistingCard : UseExistingCreditCardEnum.UseNewCard,
  }

  componentDidMount() {

    const callLoadStripe = (loadStripe: (publishableKey: string) => Promise<any>) => {
      loadStripe(this.props.publishableKey)
        .then((stripe) => {
          this.props.handleStripeLoaded(stripe as unknown as stripe.Stripe);
        });
    }

    if (!this.state.loadStripePromise && !this.state.loadStripeLoading) {
      this.setState({ ...this.state, loadStripeLoading: true });

      import('@stripe/stripe-js')
        .then(({ loadStripe }) => {
          this.setState({ ...this.state, loadStripePromise: loadStripe, loadStripeLoading: false });
          callLoadStripe(loadStripe);
        });
    }

    if (this.state.fontFamilyUrlFriendly === null) {
      const fontFamilies = this.props.theme.typography && this.props.theme.typography.fontFamily ? this.props.theme.typography.fontFamily : null;
      const fontFamily = fontFamilies ? fontFamilies.split(',')[0] : this.props.theme.defaultFont;

      this.setState({
        fontFamilyUrlFriendly: fontFamily ? fontFamily.split(' ').join('+').split('"').join('') : '',
        protocol: 'http' + (LocationService.isSSH() ? 's' : '')
      });
    }

    if (this.props.handleUseExistingCardChange) {
      //notify initial state of existing card selection
      this.props.handleUseExistingCardChange(this.state.useExistingCreditCard === UseExistingCreditCardEnum.UseExistingCard);
    }
  }

  /**
   * Manages state for handling new vs existing credit card selection
   */
  handleUseExistingCardChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = (event.target as HTMLInputElement).value as UseExistingCreditCardEnum;
    this.setState({ ...this.state, useExistingCreditCard: newValue })
    if (this.props.handleUseExistingCardChange) {
      this.props.handleUseExistingCardChange(newValue === UseExistingCreditCardEnum.UseExistingCard);
    }
  }

  render() {
    const {
      stripeInstance, theme, triedNext, wrapperStyle, cvcImagePath,
      handleUpdate,
      t
    } = this.props;

    return (
      renderIf(stripeInstance && this.state.fontFamilyUrlFriendly && this.state.protocol, () => (
        <Paper elevation={1} className={style.paper}>
          {renderIf(this.props.allowExistingCreditCard, () => (
            <RadioGroup aria-label='existing-card' name="existing-card-group" className={style.selectCreditCard}
              data-testid="existing-card-group"
              value={this.state.useExistingCreditCard}
              onChange={event => this.handleUseExistingCardChange(event)}
            >
              <FormControlLabel
                value={UseExistingCreditCardEnum.UseExistingCard}
                control={<Radio />}
                label={t(`Update with existing card ending in {{last4}}`, {
                  last4: this.props.existingCreditCardLast4 
                })} 
              />
              <FormControlLabel
                value={UseExistingCreditCardEnum.UseNewCard}
                control={<Radio />}
                label={t("Pay with a new credit card")}
              />
            </RadioGroup>
          ))}
          {renderIf(this.state.useExistingCreditCard === UseExistingCreditCardEnum.UseNewCard, () => (
            <StripeProvider stripe={stripeInstance}>
              <Elements
                fonts={[
                  { cssSrc: `${this.state.protocol}://fonts.googleapis.com/css?family=${this.state.fontFamilyUrlFriendly}` }
                ]} >
                <PaymentCardStripe
                  stripe={stripeInstance as ReactStripeElements.StripeProps}
                  theme={theme}
                  triedNext={triedNext}
                  wrapperStyle={wrapperStyle}
                  cvcImagePath={cvcImagePath}
                  handleUpdate={(token: stripe.Token, paymentDetails: IPaymentDetailsGenericData, isValid: boolean, card: stripe.elements.Element) => {
                    handleUpdate(card, token, paymentDetails, isValid);
                  }}>
                </PaymentCardStripe>
              </Elements>
            </StripeProvider>
          ))}
          {this.props.children}
        </Paper>
      ), () => (
        <Paper elevation={1} className={style.loaderPanel}>
          <CircularProgress size={20} />
        </Paper>
      ))
    )
  }
}

export default withTranslation("payment")(PaymentDetailsStripe);