import React, {useEffect, useState} from 'react';
import style from './style.module.scss';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import {IDispatchFromProps, IStateFromProps} from './types';
import CloseIcon from "@material-ui/icons/Close";
import {loadStatus} from "app/types/common.types";
import {ROUTE_NAMES} from "app/services/route/route.types";

const NS = 'ScheduleFailDialog';
const ANTI_SPAM_DELAY = 5000;


export default function ScheduleFailDialog({ scheduleLoadStatus, currentRouteName, handleRetry}: IStateFromProps & IDispatchFromProps) {

  const [doShow, setDoShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enableRetryBtn, setEnableRetryBtn] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    if (scheduleLoadStatus === loadStatus.failedTemp) {
      setDoShow(true);
      setTimeout(() => setEnableRetryBtn(true), ANTI_SPAM_DELAY);
    } else if (scheduleLoadStatus === loadStatus.loading) {
      setIsLoading(true);
      setEnableRetryBtn(false);
    } else if (scheduleLoadStatus === loadStatus.success || scheduleLoadStatus === loadStatus.failed) {
      // if successful or normal fail (not a 503), hide the dialog
      setIsLoading(false);
      setDoShow(false);
    }

  }, [scheduleLoadStatus]);
  return (
    <Dialog
      classes={{
        paper: style.paper
      }}
      maxWidth="md"
      open={doShow}
      onClose={() => setDoShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        disableTypography
        id="alert-dialog-title"
        classes={{
          root: style.title
        }}>
        <IconButton  aria-label="close" className={style.closeButton} onClick={() => setDoShow(false)}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>

      <DialogContent
        classes={{
          root: style.content
        }} >

        {!isLoading && (
          <DialogContentText id="alert-dialog-description" component="div" className={style.bodyText}>
            {currentRouteName === ROUTE_NAMES.SUMMARY && (
              <>
                <Typography variant="body1">
                  This venue is experiencing a high number of bookings at the moment.
                </Typography>
                <Typography variant="body1">
                  Tried to save your booking, but failed.
                </Typography>
                <Typography variant="body1">
                  Please try again in a few seconds.
                </Typography>
              </>
            )}
            {currentRouteName === ROUTE_NAMES.SITTING && (
              <>
                <Typography variant="body1">
                  We are trying to find you a table.
                  <br />
                  Currently there are a large number of bookings on this venue.
                </Typography>
                <Typography variant="body1">
                  Please try again in a few seconds.
                </Typography>
              </>
            )}
            {currentRouteName === ROUTE_NAMES.SETUP && (
              <>
                <Typography variant="body1">
                  This venue is experiencing a high number of bookings at the moment.
                </Typography>
                <Typography variant="body1">
                  Please try again in a few seconds.
                </Typography>
              </>
            )}
          </DialogContentText>
        )}

        {isLoading && (
          <div className={style.loaderWrap}>
            <CircularProgress />
            <Typography variant="body1">
              {currentRouteName === ROUTE_NAMES.SUMMARY ? 'Saving Booking' : (
                currentRouteName === ROUTE_NAMES.SITTING ? 'Searching' :
                  'Opening Booking'
              )}
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions classes={{
        root: style.btnWrap
      }} >
        <Button
          onClick={() => {
            setEnableRetryBtn(false);
            handleRetry();
          }}
          color="primary"
          autoFocus
          disabled={!enableRetryBtn}
          variant="contained"
          className={style.btn}>
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  )
}

