// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".underlineOfText--Z0IED{text-decoration:underline;font-size:1.3rem}.divider--mCi2n{background-color:rgba(0,0,0,.42);margin:0 1.5rem}.body--TvZv4 p{margin:0 0 6px 0;line-height:1.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"underlineOfText": "underlineOfText--Z0IED",
	"divider": "divider--mCi2n",
	"body": "body--TvZv4"
};
export default ___CSS_LOADER_EXPORT___;
