
import React from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import SimplePage from 'shared-components/simple-page/index';
import { useTranslation } from 'react-i18next';

const NS = 'ErrorPage';


export default function ErrorPage({ theme, wrapperStyle, bookingError, handleReset }: IStateFromProps & IDispatchFromProps) {
  const { t } = useTranslation("errors");

  return (
    <SimplePage
      theme={theme}
      title={bookingError ? t(bookingError.heading) : ''}
      body={bookingError ? t(bookingError.message) : ''}
      buttonText={bookingError ? t(bookingError.buttonText) : ''}
      bodyIsMarkDown={true}
      buttonCallback={() => {
        if (bookingError) {
          handleReset(bookingError.messageType, bookingError.name);
        }
      }}
      centerButton={!!bookingError}
    />
  )
}

