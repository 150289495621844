import { Color } from '@material-ui/core';
import { SimplePaletteColorOptions } from '@material-ui/core/styles';
import {
    IWidgetTheme, IWidgetBasicColor,
    colorParamTypes, IColorsFromQueryString, IWidgetPalette
} from "shared-types/index";
import {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";

const NS = 'ThemeColorsService';

export class ThemeColorsService {

    static getCommonThemeStyles(theme: ThemeOptions) {
        const primary: Color & IWidgetBasicColor = theme.palette.primary as Color & IWidgetBasicColor;
        const secondary: Color & IWidgetBasicColor = theme.palette.secondary as Color & IWidgetBasicColor;
        const textColor1: string = primary[800] || primary.main;
        const textColor2: string = primary[800] || secondary.main;

        const colorError: string = (theme.palette.error as SimplePaletteColorOptions).main;

        // this doesn't get added until after initial load, so must have a null check
        const colorWarning: string = theme.palette.warning ? (theme.palette.warning as SimplePaletteColorOptions).main : '#000';
        const colorSuccess: string = theme.palette.success ? (theme.palette.success as SimplePaletteColorOptions).dark : '#000';

        return {primary, secondary, textColor1, textColor2, colorError, colorWarning, colorSuccess};
    }


    static getColorsFromString(
        themeDefaults: ThemeOptions, colorsParam: string, palette: IWidgetPalette = {}
    ): IColorsFromQueryString {
        let defaultColors = false;
        const colors: string[] = colorsParam ? colorsParam.split(',') : [];
        const type: string = colors[0];
        const primary: string = colors[1];
        const secondary: string = colors[2] || primary; // uses primary color if only 1 supplied

        if (primary && type === colorParamTypes.hex) {
            // if using hex values
            palette.primary = {
                main: `#${primary}`
            };
            defaultColors = false;
        } else {
            palette.primary = themeDefaults.palette.primary
        }

        if (secondary && type === colorParamTypes.hex) {
            // if using hex values
            palette.secondary = {
                main: `#${secondary}`
            };
        } else {
            palette.secondary = themeDefaults.palette.secondary;
        }

        return { palette, defaultColors };
    }
}