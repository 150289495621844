import React, {ComponentType, useState, useEffect, useMemo} from 'react';
import {IConfirmModalDialog} from './types';
import style from './style.module.scss';
import MuiDialog, {DialogProps} from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/styles";
import {wrapperStyleType} from "app/models";
import appValues from "app/constants/appValues";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import {UtilsService} from "app/services/utils/utils.service";
import ReactMarkdown from "react-markdown";
import ReCaptchaV2 from 'react-google-recaptcha'
import {renderIf} from 'app/services/utils/utils.service';
import { useTranslation } from 'react-i18next';


const NS = 'ConfirmModalDialog';

export default function ConfirmModalDialog({wrapperStyle, doShow, showRobotConfirmation, modalMsg, handleConfirm, handleCancel, confirmText, cancelText, modalHeader}: IConfirmModalDialog) {
  const { i18n } = useTranslation();
  const [isVerify, setIsVerified] = useState<boolean>(false);

  const handleToken = () => {
    setIsVerified(true);
  }

  useEffect(() => {
    setIsVerified(!showRobotConfirmation);
  }, []);

  const Dialog: ComponentType<DialogProps> = withStyles({
    paper: {
      minWidth: (() => {
        switch (wrapperStyle) {
          case wrapperStyleType.standard:
            return `${appValues.STACKED_BP - 20}px`;

          case wrapperStyleType.wide:
            return `600px`;
        }

        return null;
      })()
    }
  })(MuiDialog);

  const MemoDialog = useMemo(() => Dialog, [wrapperStyle]);
  return (
    <MemoDialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: style.paper,
      }}
      maxWidth="md"
      open={doShow}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        disableTypography
        id="alert-dialog-title"
        classes={{
          root: style.title
        }}>
        <Typography variant="h2">
          {modalHeader || 'Important information'}
        </Typography>
      </DialogTitle>

      <DialogContent
        classes={{
          root: style.content
        }} data-testid="dialog-content">
        <DialogContentText id="alert-dialog-description" component="div" className={style.markdownText}>
          <ReactMarkdown
            source={modalMsg}
            renderers={{
              link: UtilsService.reactMarkDownBlankTargets
            }}
            escapeHtml={false}
          />
        </DialogContentText>

        {renderIf(showRobotConfirmation, () => (
          <ReCaptchaV2
            data-testid="recaptcha-widget"
            sitekey= {appValues.RECAPTCHA_SITE_KEY}
            lang={i18n.language}
            hl={i18n.language}
            onChange={handleToken}
            />
          )
        )}

      </DialogContent>
      <DialogActions classes={{
        root: style.btnWrap
      }}>
        <Button onClick={handleCancel} color="default" autoFocus variant="contained">
          {cancelText || 'Cancel'}
        </Button>

        <Button onClick={handleConfirm} color="primary" disabled={!isVerify} autoFocus variant="contained">
          {confirmText || 'Confirm'}
        </Button>
      </DialogActions>
    </MemoDialog>
  )
}
