import React, {ChangeEvent, RefObject, useEffect, useRef, useState} from 'react';
import style from './style.module.scss';
import {IDispatchFromProps, IOwnProps, ISelectableTime, IStateFromProps, IAvailableTimeListProps} from './types';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import {renderIf} from 'app/services/utils/utils.service';
import AlertPanel from '../AlertPanel';
import MuiAlert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MuiFormControl from "@material-ui/core/FormControl";
import {AvailableTimeList} from './AvailableTimeList';
import JoinStandbyButton from "app/components/JoinStandbyButton";
import OrHRule from "app/components/OrHRule";
import {STANDBY_EXHAUSTED_MSG} from "app/services/message/message.service";
import {IScheduleTime} from "shared-types/index";
import { Trans, useTranslation } from 'react-i18next';

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'TimePicker';


const Alert = withStyles({
  icon: {
    alignItems: 'center'
  }
})(MuiAlert);

const FormControl = withStyles({
  root: {
    paddingLeft: 42,
    '& .MuiTypography-root': {
      fontSize: '1.3rem'
    },
    '& .MuiCheckbox-root': {
      position: 'absolute',
      marginTop: -1,
      left: -7
    }
  }
})(MuiFormControl);

const getComponentWidth = (myRef: RefObject<HTMLElement>): number => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
    };

    window.addEventListener('resize', handleResize);

    myRef.current && setWidth(myRef.current.offsetWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [myRef]);

  return width;
}

export default function TimePicker({
  times, emptyTimesMessage, alertMessage, wrapperStyle, showStandbyMessage, isStandbyMode, standbyCleared,
  timeNotAvailableMessage, isFlexibleTime, standbyExhausted, enableGAW, isEditMode, theme, hasActiveSection,
  isSelectedServiceAvailable,
  handleTimeSelect, handleBack, handleTimeChange, handleStandbyTimeSelect,
  handleOpenStandby, handleCancelStandby, handleIsFlexibleTimeChange
}: IStateFromProps & IDispatchFromProps & IOwnProps) {
  const { t } = useTranslation("nextAvailableBooking");

  const componentRef = useRef<HTMLDivElement>();
  const alertWrapRef = useRef<HTMLElement>();
  const width = getComponentWidth(componentRef);
  const isTightFit = width < 290;
  const isTightFit2 = width < 340;

  const handleSelect = (t: ISelectableTime) => {
    if (handleTimeChange) {
      handleTimeChange();
    }
    if (isStandbyMode) {
      setSelectedStandbyTime(t);
      handleStandbyTimeSelect(t);
      
    } else {
      handleTimeSelect(t);
    }
  }

  const sectionNotYetSelected: boolean = !isSelectedServiceAvailable || hasActiveSection;
  const percentUnavailable: number = times ? (times.filter(t => t.isDisabled && !t.expired).length / times.length) * 100 : 0;
  const showingMessages: boolean = !!timeNotAvailableMessage && times && times.some(t => t.isDisabled && !t.expired) && percentUnavailable >= 100 && sectionNotYetSelected;
  const gridColumns: 4 | 3 = isTightFit ? 4 : 3;

  useEffect(() => {
    alertWrapRef && alertWrapRef.current && alertWrapRef.current.scrollIntoView({behavior: "smooth"});
  }, [alertMessage]);

  const [isStandbyModeReady, setIsStandbyModeReady] = useState<boolean>(isStandbyMode);
  const [selectedStandbyTime, setSelectedStandbyTime] = useState<IScheduleTime>(null);

  const isLandscape = !IframeResizerService.isStacked(wrapperStyle);


  const availableTimeListProps: IAvailableTimeListProps = {
    times,
    gridColumns,
    isStandbyMode,
    isFlexibleTime,
    timeNotAvailableMessage,
    handleSelect: handleSelect
  }

  const showGAW = enableGAW && !isEditMode;

  return (
    <div className={style.root} ref={componentRef}>
      <div className={classNames({
        [style.outterArea]: true,
        [style.outterAreaIsStandbyMode]: isStandbyMode
      })}>
        <div className={style.scrollableArea}>
          {renderIf(handleBack, (
            <Button
              data-e2e="back-to-calendar-btn"
              aria-label="back to calendar"
              size="small"
              className={style.backBtn}
              onClick={handleBack}
            >
              <ArrowBackIcon fontSize="small" className={style.backArrow}/>
              <Trans t={t}>
                Back to calendar
              </Trans>
            </Button>
          ))}

          {renderIf(showingMessages, () => (
            <div className={style.errorMsg} data-e2e="empty-times-message"
            data-testid="empty-times-message">
              <Typography component="span" variant="body2">
                <ReactMarkdown source={emptyTimesMessage} renderers={{paragraph: 'p'}} escapeHtml={false}/>
              </Typography>
              {(showGAW || showStandbyMessage) && <div className={style.ruleWrap}><OrHRule showText={false} theme={theme} /></div>}
            </div>
          ))}

          {renderIf(alertMessage, () => (
            <Paper
              ref={alertWrapRef}
              className={classNames({
                [style.alert]: true,
                [style.alertBottomSpaced]: !showStandbyMessage && !standbyCleared
              })}
              elevation={0}
              data-e2e="alert-message"
            >
              <AlertPanel wrapperStyle={wrapperStyle} message={alertMessage}/>
            </Paper>
          ))}

          {showStandbyMessage && !standbyExhausted && (
            <div className={style.joinStandbyBtnWrap}>
              <JoinStandbyButton handleClicked={() => {
                handleOpenStandby()
                  .then(() => {
                    setIsStandbyModeReady(true);
                  });
              }} />
            </div>
          )}

          {/* Only show the standby exhausted message if there are no times, or else it gets confusing */}
          {renderIf(showStandbyMessage && standbyExhausted && times.filter((t => !t.isDisabled)).length === 0, () => (
            <Paper className={style.standbyMsg} data-testid="standby-exhausted" elevation={0}>
              <Typography variant="body2">{t(STANDBY_EXHAUSTED_MSG)}</Typography>
            </Paper>
          ))}

          {renderIf(standbyCleared, () => (
            <Paper className={style.standbyMsg} elevation={0}>
              <Alert severity="info" variant="filled">
                <Typography variant="body2">
                  <Trans t={t}>
                    Standby list settings cleared. Time now available.
                  </Trans>
                </Typography>
              </Alert>
            </Paper>
          ))}

          {renderIf(isStandbyMode, () => (
            <div className={style.standbyWrap}>
              <Typography variant="body2" component="div">
                <Trans t={t}>
                  Choose your preferred time
                </Trans>
                {renderIf(isTightFit2, () => <br/>, () => " ")}

                {/* when width is small this line gets forced into 2nd line with some space above it because of checkbox */}
                <span style={{
                  display: "inline-block",
                  marginTop: isTightFit2 ? 8 : 0
                }}>
                  <Trans t={t}>
                    or
                  </Trans>
                  &nbsp;
                  <FormControl>
                    <FormControlLabel
                      checked={isFlexibleTime}
                      onChange={(event: ChangeEvent<{}>, checked: boolean) => {
                        handleStandbyTimeSelect(checked ? null : selectedStandbyTime);
                        handleIsFlexibleTimeChange(checked);
                      }}
                      control={
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="default"/>}
                          checkedIcon={<CheckBoxIcon fontSize="default" color="secondary"
                          />}
                        />
                      }
                      label={t("I don't mind")}
                    />
                  </FormControl>
                </span>
              </Typography>
            </div>
          ))}

          {renderIf(isStandbyMode && !isStandbyModeReady, () => (
            <div className={classNames({
              [style.loaderWrap]: true,
              [style.loaderWrapIsCentered]: !isLandscape
            })}>
              <CircularProgress size={20}/>
            </div>
          ), () => (
            <Grid container>
              <AvailableTimeList  {...availableTimeListProps} />
            </Grid>
          ))}
        </div>

        {renderIf(isStandbyMode, (
          <Button
            data-e2e="cancel-standby-btn"
            aria-label="cancel standby" size="small" className={style.standbyCloseBtn}
            onClick={handleCancelStandby}>
            <CloseIcon fontSize="small"/>
              <Trans t={t}>
                Cancel standby list
              </Trans>
          </Button>
        ))}

      </div>
    </div>
  )
}

