import {connect} from 'react-redux';
import MenuOptions from 'shared-components/booking-options-section/MenuOptions/index';
import {IDispatchFromProps, IStateFromProps} from 'shared-components/booking-options-section/MenuOptions/types';
import {menuOptionType} from 'shared-components/booking-options-section/MenuOption/types';
import {IRootState} from 'app/reducers';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import BookingService from 'shared-services/booking-service/index';
import {
  IBookingMenuOption,
  IServicePaymentDetails,
  IWidgetTheme,
  loadStatus,
  PricingTypes,
  servicePaymentType
} from 'shared-types/index';
import FooterService from 'app/services/footer/footer.service';
import {ValidateSittingType} from 'app/services/footer/footer.types';
import {ROUTE_NAMES} from 'app/services/route/route.types';
import {IParentMenuOption} from 'shared-components/booking-options-section/ChildMenuOptionOverlay/types';
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";

const NS = 'MenuOptionsContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {
    theme, appSettings, wrapperStyle, triedNext, booking,
    activeService, activeVenue, filteredSections, standbyData
  } = widget;

  const covers: number = booking ? booking.covers : 0;
  const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;

  // @todo: getBookingOptions is mutating booking.selectedMenuOptions in the redux store here. Need to get this implmented in the reducers.
  const selectedMenuOptions: IBookingMenuOption[] = BookingService.getBookingOptions(
      paymentDetails.options, booking.selectedMenuOptions, covers, paymentDetails.singleMenuPerBooking
    );
  const allNoPayment: boolean = paymentDetails.options.every(o => o.paymentType === servicePaymentType.noPayment);

  let alertMessage = null;
  if (triedNext === ROUTE_NAMES.SITTING) {
    const validationResults = FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData);
    alertMessage = FooterService.getAlertMessage(validationResults, ValidateSittingType.MenuOptions);
  }

  return {
    theme: theme as IWidgetTheme,
    wrapperStyle,
    selectedMenuOptions,
    prefillMenuOptions: booking.selectedMenuOptions,
    menuOptions: paymentDetails.options,
    type: paymentDetails.singleMenuPerBooking ? menuOptionType.radioButtons : (
      paymentDetails.pricingType === PricingTypes.PerPax
        ? menuOptionType.spinners
        : menuOptionType.checkboxes
    ),
    maxCovers: covers || 1,
    alertMessage,
    serviceId: activeService ? activeService.id : null,
    bgColor: appSettings.bgColor,
    useMuiGrid: true,
    isUpsell: false // forces it to use narrow version
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleRadioSelect: (menuOptionId: string) => {
      dispatch(BookingOptionsActionsNS.selectedMenuOptions([{menuOptionId, quantity: 0}], false, true));
    },
    handleMultiBookingOptionUpdate: (opts: IBookingMenuOption[]) => {
      dispatch(BookingOptionsActionsNS.selectedMenuOptions(opts));
    },
    handleActiveChildMenuOption: (parentMenuOption: IParentMenuOption) => {
      dispatch(BookingOptionsActionsNS.updateActiveChildMenuOption(parentMenuOption));
    }
  }
};

const MenuOptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuOptions as any);

export default MenuOptionsContainer;
