import React, {useState} from 'react';
import {IGawVenueList} from './types';
import style from './style.module.scss';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Typography
} from "@material-ui/core";
import {renderIf} from "app/services/utils/utils.service";
import moment from "moment";
import {isEmpty} from 'lodash';
import {IScheduleTime} from "shared-types/index";
import { useTranslation, Trans } from "react-i18next";
import { useLocalizeTime } from "app/services/localization/useLocalizeTime";

const NS = 'GawVenueList';

export default function GawVenueList({selectedTimeForOtherVenues, handleTimeSelection, filteredTimes, venueName}: IGawVenueList) {
  const { t } = useTranslation("sitting");
  const { localizeTime } = useLocalizeTime();

  const beforeTime = filteredTimes.filter(time => moment(time.time).isBefore(moment(selectedTimeForOtherVenues.time)));
  const selectedTime = filteredTimes.filter(time => time.time === selectedTimeForOtherVenues.time);
  const afterTime = filteredTimes.filter(time => moment(time.time).isAfter(moment(selectedTimeForOtherVenues.time)));

  const [open, setOpen] = useState(false);
  const [selectedTimeForOtherVenue, setSelectedTime] = useState<IScheduleTime>(null);

  const openGAWDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const redirectToOtherVenueFromDialog = () => {
    handleTimeSelection(selectedTimeForOtherVenue)
    setOpen(false);
  };

  const showTimes = (times: IScheduleTime[]) => {
    return (
      <>
        {renderIf(!isEmpty(times), () => ( // add an extra blank if the selected time is 1st time to be shown - so that the selected time is in the middle
            <>
              {times.map((time, index) => (
                <Grid key={index} item xs={3} className={style.timeBox}>

                  <Chip
                    className={style.timeChip}
                    label={localizeTime(time).name}
                    clickable
                    color={'default'}
                    variant={'outlined'}
                    onClick={() => {
                      setSelectedTime(time)
                      openGAWDialog();
                    }}
                  />
                </Grid>
              ))
              }
            </>
          ),
          // hiding the empty slots as per NBI-4365
          // <Grid item xs={3} className={style.timeBox}>
          // </Grid>
        )}
      </>
    )
  }
  

  return (
    <Grid container className={style.root}>
      {showTimes(beforeTime)}
      {showTimes(selectedTime)}
      {showTimes(afterTime)}

      {/*Confirmation Dialog before redirecting to another venue*/}
      <Dialog
        open={open}
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant={'h3'} component={'div'}>
            <Trans t={t}>
              Availability at our other venues
            </Trans>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant={'h4'} component={'div'}>
              <Trans t={t}>
                You are navigating away from this page to make a booking at <span
              className={style.venueName}>{{venueName}}</span>
              </Trans>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="default">
            <Trans t={t}>
              Go back
            </Trans>
          </Button>
          <Button onClick={redirectToOtherVenueFromDialog} variant="contained" color="default" autoFocus>
            <Trans t={t}>
              Proceed
            </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
