import { handleActions } from "redux-actions";
import { loadStatus, loaderType } from "app/types/common.types";
import { SetupActionsNS } from 'app/actions/setup/setupActions';
import { ISetup, getInitialSetupState } from './state';


const NS = 'setupReducer';

export const setupReducer = handleActions<ISetup, any>(
  {
    [SetupActionsNS.Type.APP_LOAD_PROGRESS]: (state, action): ISetup => {
      return {
        ...state,
        appLoadStatus: loadStatus.loading,
        appLoadMessage: action.payload.appLoadMessage,
        appLoaderType: action.payload.appLoaderType || loaderType.hideContent
      }
    },

    [SetupActionsNS.Type.APP_LOAD_COMPLETE]: (state, action: SetupActionsNS.IAppLoadComplete): ISetup => {
      const { status, completeLoadStatus, msg } = action.payload;
      return {
        ...state,
        appLoadStatus: completeLoadStatus ? status : state.appLoadStatus,
        appErrorMessage: msg || null
      }
    },

    [SetupActionsNS.Type.LIB_PHONE_LOAD]: (state, action: SetupActionsNS.ILibPhoneStatus): ISetup => {
      return {
        ...state,
        libPhoneNumberLoaded: action.payload === loadStatus.success,
        libPhoneNumberLoading: action.payload === loadStatus.loading
      }
    },

  },
  getInitialSetupState()
);
