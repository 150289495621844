import React, { useState } from 'react';
import { IPaymentPromo } from './types';
import style from './style.module.scss';
import { TextField, Button } from '@material-ui/core';
import { wrapperStyleType } from 'app/models';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

const NS = 'PaymentPromo';

export default function PaymentPromo({ handleApply, promotionCode, errorMessage, wrapperStyle}: IPaymentPromo) {
  const { t } = useTranslation("payment");
  const [textVal, setTextVal] = useState<string>(promotionCode);

  const isTightFit = wrapperStyle === wrapperStyleType.standard;

  return (
    <div className={classNames({
      [style.root]: true,
      [style.rootIsTightFit]: isTightFit
    })}>

      <TextField
        className={classNames({
          [style.text]: true,
          [style.textIsTightFit]: isTightFit
        })}
        label={`${t("Promotion Code")}:`}
        onChange={(evt) => setTextVal(evt.target.value)}
        error={!!errorMessage}
        helperText={errorMessage || ''}
      />
      <div className={classNames({
        [style.btnWrap]: true,
        [style.btnWrapIsTightFit]: isTightFit
      })}>
        <Button
          variant="contained" color="secondary" size="small"
          disabled={!textVal}
          onClick={() => handleApply(textVal)}>
          <Trans t={t}>
            Apply Discount
          </Trans>
      </Button>
      </div>
    </div>
  )
}
