import {INavigation} from "app/reducers/state";
import {blockNavType, IStandbyData, IVenue, IVenueMinimal, IWidgetModel, modeType} from "app/models";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {BookingService} from "../booking/booking.service";
import {IBooking, IBookingMenuOption} from "../booking/booking.types";
import {IValidateFooterNav, IValidateFooterNavItem, ValidateSittingType} from './footer.types';
import {
  PricingTypes,
  IServicePaymentDetails,
  ISectionOrder,
  IScheduleService
} from "shared-types/index";
import {ISchedule} from "app/services/client/client.types";
import { t } from 'i18next';

const NS = 'FooterService';

export default class FooterService {

  /**
   * Works out if next button should be enabled or not using states from store
   */
  static isNextEnabled(nav: INavigation, widget: IWidgetModel): boolean {

    const {
      booking, filteredSections, activeVenue, activeService, isCustomerDetailsValid, standbyData, schedule
    } = widget;

    if (widget.editBookingDisabled || FooterService.shouldAcceptVerification(nav, widget)) {
      return false;
    }
    
    const curRouteName: string = nav.stepRoutes[nav.currentStepIndex];

    switch (curRouteName) {
      case ROUTE_NAMES.VENUES:
        return !!(widget.venues && widget.venues.selectedVenueId);

      case ROUTE_NAMES.SITTING:
        return FooterService.validateSittingPage(booking, activeService, activeVenue, filteredSections, standbyData, widget, schedule).isValid;

      case ROUTE_NAMES.CUSTOMER_DETAILS:
        return isCustomerDetailsValid;

      case ROUTE_NAMES.SUMMARY:
        return true;

      default:
        return false;
    }
  }

  static validateSittingPage(
    booking: IBooking,
    activeService: IScheduleService,
    activeVenue: IVenueMinimal,
    filteredSections: ISectionOrder[],
    standbyData?: IStandbyData,
    widget?: IWidgetModel,
    schedule?: ISchedule
  ): IValidateFooterNav {

    const items: IValidateFooterNavItem[] = [];
    const blockNav: blockNavType = widget?.blockNav;
    // const maxPeoplePerBookingOverride = activeService?.paymentDetails?.maxPeoplePerBookingOverride;
    const maxPeoplePerBookingOverride = BookingService.getMaxPeoplePerBooking(activeVenue as IVenue, activeService, schedule);
    const maxPeoplePerBooking = maxPeoplePerBookingOverride ? maxPeoplePerBookingOverride : widget?.activeVenue.widgetSettings.maxPeoplePerBooking;
    const minPaxPerBooking = activeService?.minPaxPerBooking || 1;
    const coversIsValid = standbyData ? standbyData.covers > 0 : booking.covers > 0;
    const coversOverLimit = booking.covers > maxPeoplePerBooking;
    const coversLessThanMinimum = booking.covers < minPaxPerBooking;
    const serviceIsValid = !!activeService; // radio so will always be selected
    const dateIsValid = standbyData ? !!standbyData.viewDate : !!booking.viewDate; // date will always be selected
    const timeIsValid = standbyData ? standbyData.isFlexibleTime || !!standbyData.viewTime : !!booking.viewTime;

    /**
     * @todo problem here is that sections and menu options don't add to `items` unless they are valid, which
     * means you can't have multiple items - but we need to. Severity: low
     */
    let isValid = activeService && coversIsValid && serviceIsValid && dateIsValid && timeIsValid && !blockNav && !coversOverLimit;

    if (blockNav) {
      if (blockNav === (blockNavType.becauseOfCoversPending || blockNavType.becauseOfPayment)) {
        items.push({
          type: ValidateSittingType.CoversPending,
          message: '-' // message not needed
        });
      } else if (blockNav === blockNavType.becauseNoAvailableTimes) {
        items.push({
          type: ValidateSittingType.Time,
          message: '-' // message not needed
        });
      }

    }

    if (!activeService) {
      items.push({
        type: ValidateSittingType.Service,
        message: t('Please tell us which service you are booking for.', {
          ns: 'footer'
        })
      });
    }

    if (!coversIsValid) {
      items.push({
        type: ValidateSittingType.Covers,
        message: t('Please tell us how many people are coming.', {
          ns: 'footer'
        })
      });
    }

    if (coversOverLimit) {
      items.push({
        type: ValidateSittingType.Covers,
        message: t('Maximum Limit of {{count}} people exceeded.', {
          ns: 'footer',
          count: maxPeoplePerBooking
        })
      });
    }

    if(coversLessThanMinimum){
      items.push({
        type: ValidateSittingType.Covers,
        message: t('Minimum booking size is {{count}}', {
          ns: 'footer',
          count: minPaxPerBooking
        })
      });
    }

    const hasSections: boolean = activeVenue && activeVenue.widgetSettings.canCustomersChooseSection && !!filteredSections.length;

    if (isValid && hasSections) {
      isValid = standbyData ? !!standbyData.sectionId : !!booking.sectionId;

      if (!isValid) {
        items.push({
          type: ValidateSittingType.Section,
          message: t('Where would you like to be seated?', { ns: 'footer' })
        });
      }
    }

    if (!timeIsValid) {
      items.push({
        type: ValidateSittingType.Time,
        message: t('What time would you like to book for?', { ns: 'footer' })
      });
    }

    if (!standbyData) {
      const hasMenuOptions: boolean = FooterService.showMenuOptions(activeVenue, activeService, booking.covers);

      if (isValid && hasMenuOptions) {

        isValid = FooterService.isMenuOptionsValid(activeService, booking.covers, booking.selectedMenuOptions);

        if (!isValid) {
          items.push({
            type: ValidateSittingType.MenuOptions,
            message: t('Invalid booking option.', { ns: 'footer' })
          });
        }
      }
    }

    return {isValid, items}
  }

  /**
   * Works out if next button should be enabled or not using states from store
   */
  static isPrevEnabled(nav: INavigation, widget: IWidgetModel): boolean {

    if (widget.appSettings.mode === modeType.preview) {
      return false;
    }

    const curRouteName: string = nav.stepRoutes[nav.currentStepIndex];

    switch (curRouteName) {
      case ROUTE_NAMES.VENUES:
        return false;

      case ROUTE_NAMES.SITTING:
        // only if venues exists in routes, enable the back button
        return !!nav.stepRoutes.find(r => r === ROUTE_NAMES.VENUES);

      default:
        return true;
    }
  }

  static getAlertMessage(validationResults: IValidateFooterNav, type: ValidateSittingType): string {
    let alertMessage: string = null;
    if (validationResults && !validationResults.isValid) {
      const coversItem = validationResults.items.find(o => o.type === type);
      if (coversItem) {
        alertMessage = coversItem.message;
      }
    }

    return alertMessage;
  }

  /**
   * Check whether verification pop-up must be accepted or not.
   */
  static shouldAcceptVerification(nav: INavigation, widget: IWidgetModel) {

    // Validate only on sitting page
    if (nav.currentRouteName !== ROUTE_NAMES.SITTING) return false;

    // Required verification switch is ON for the venue
    const additionalBookingRequirements = BookingService.getAdditionalBookingRequirements(widget);
    if (!additionalBookingRequirements?.requiresVerification) return false;

    // Verification pop-up is not accepted
    if (widget.haveAcceptedVerification) return false;

    return true;
  }

  private static isMenuOptionsValid(activeService: IScheduleService, covers: number, selectedMenuOptions: IBookingMenuOption[]): boolean {
    const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
    if (paymentDetails) {
      const selectedCovers: number = selectedMenuOptions ? selectedMenuOptions.reduce((a, o) => a + o.quantity, 0) : 0;

      return (paymentDetails.singleMenuPerBooking || paymentDetails.pricingType === PricingTypes.PerBooking)
        ? selectedCovers > 0 // if radio button or checkbox used, only needs a single quantity
        : selectedCovers === covers; // if spinners used, quantity needs to match covers
    }

    return false; // don't think this ever gets called
  }

  private static showMenuOptions(activeVenue: IVenueMinimal, activeService: IScheduleService, covers: number): boolean {
    if (!activeVenue) {
      return false;
    }
    const maxPeoplePerBookingDefault = activeVenue.widgetSettings.maxPeoplePerBooking;
    const paymentDetails: IServicePaymentDetails = activeService ? activeService.paymentDetails : null;
    return !!paymentDetails && BookingService.hasMenuOptions(activeService, covers, maxPeoplePerBookingDefault);
  }
}
