import {Dispatch} from "redux";
import moment, {Moment} from "moment";
import {bookingSuccessType} from 'app/services/error/error.types';
import {IRootState} from "app/reducers";
import {ClientService} from "app/services/client/client.service";
import {IVenue, IWidgetModel, modeType} from "app/models";
import {first} from "rxjs/operators";
import {
  IPaymentType,
  ISchedule,
} from "app/services/client/client.types";
import {IAction, IActionGen, loadStatus} from "app/types/common.types";
import {
  IBookingPayment,
  IBookingResponseData,
  ICustomer, ISavedBookingMenuOption
} from "app/services/booking/booking.types";
import {RouteService} from "app/services/route/route.service";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {
  bookingNotFoundDispatch,
  finishSession} from './helpers';
import {
  IApplyPromoCode,
  IChangedActiveServiceUpdate,
  IChangedBookingTime,
  ICoversUpdate,
  IStripeLoaded
} from "./interfaces";
import {appLoadCompleteSuccess, showProcessingOverlayLoader} from "../setup/helpers";
import {SetupActionsNS} from "../setup/setupActions";
import {
  sendBookingDeletedAnalytics,
  sendCancelledOnlineBookingAnalytics,
  sendConfirmPaymentDetailsAnalytics,
  sendCoversAnalytics,
  sendDateAnalytics,
  sendOnlineBookingAnalytics,
  sendTimeAnalytics
} from "./analyticsHelpers";
import {IResponse} from 'app/containers/App/types.d';
import {saveBooking as _saveBooking} from 'app/actions/saveBooking/saveBookingActions';
import {getScheduleForAllVenues, loadScheduleForBooking} from "app/actions/loadSchedule/loadScheduleActions";
import {applyPromoCode as _applyPromoCode, verifyHasPromoCode} from "app/actions/promoCode/promoCodeActions";
import {MenuOptionsService} from "app/services/menuOptions/menuOptions.service";
import {NavigationActionsNS} from "app/actions/navigation/navigationActions";
import {BookingService} from "app/services/booking/booking.service";
import {isEmpty} from 'lodash';
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {
  IScheduleTime,
  IScheduleService,
  IBookingTag,
  IErrorResponse,
  bookingErrorType,
  IOwnedVenue
} from "shared-types/index";

const NS = 'BookingActions';

export namespace BookingActionsNS {

  // thunk action creators

  export const changedCoversCount = (covers: number) => (dispatch: Dispatch, getState: () => any): Promise<any> => {
    const { activeVenue, appSettings, activeService, savedBooking }: IWidgetModel = getState().widget;

    dispatch({
      type: BookingActionsTypes.CHANGED_COVERS_COUNT,
      payload: {covers, clearSelectionMenuOptions: true}
    } as IActionGen<ICoversUpdate>);

    if (appSettings.mode === modeType.preview) {
      return Promise.resolve();
    }

    sendCoversAnalytics(activeVenue, covers);

    const booking = (getState() as IRootState).widget.booking; // needs to refetch booking details
    return loadScheduleForBooking(dispatch, activeVenue as IVenue, booking, false, activeService, !!savedBooking);
  };

  export const getScheduleCallForAllVenues = () => (dispatch: Dispatch, getState: () => any): Promise<any> => {
    const {activeVenue, appSettings, activeService, savedBooking, filteredTimes, schedule}: IWidgetModel = getState().widget;

    const enableGAW = activeVenue.groupAvailabilityActive && activeVenue.groupAvailabilitySubscriptionActive; // For GAW we need to check both subscription and active

    if (appSettings.mode === modeType.preview || !enableGAW || !schedule) {
      return Promise.resolve();
    }

    const isVenueOpen = schedule.isVenueOpen;

    // if venue is closed then we call GAW with the 1st service in the GAW
    if (!isVenueOpen) {
      const booking = (getState() as IRootState).widget.booking; // needs to refetch booking details
      const closedService = schedule.services ? schedule.services[0] : null;
      return getScheduleForAllVenues(dispatch, activeVenue as IVenue, booking, false, closedService, !!savedBooking);
    }

    const isGAWThresholdActive = BookingService.checkForGAWThreshold(filteredTimes, activeVenue.groupAvailabilityThreshold);
    if (isGAWThresholdActive) {
      const {widget} = getState() as IRootState;
      const ownedVenues: any = widget?.accountDetails?.ownedVenues;

      const booking = (getState() as IRootState).widget.booking; // needs to refetch booking details
      return getScheduleForAllVenues(
        dispatch,
        activeVenue as IVenue,
        booking,
        false,
        activeService,
        !!savedBooking,
        true,
        ownedVenues
      );
    }
    else {
      return Promise.resolve([]);
    }

  };

  export const getTimesForAllVenues = (allVenueSchedule: ISchedule[]) => (dispatch: Dispatch, getState: () => any) => {
    const {activeService, schedule}: IWidgetModel = getState().widget;
    let allTimes: IScheduleTime[] = [];
    const serviceVal = schedule.isVenueOpen && activeService ? activeService : null;
    const firstAvailableTimeForActiveService: IScheduleTime = serviceVal?.times[0]; // Chose the starting time of the service
    if (!isEmpty(allVenueSchedule)) {
      allTimes = BookingService.getTimesFromAllServices(allVenueSchedule, firstAvailableTimeForActiveService);
    }

    dispatch({type: BookingActionsTypes.ALL_VENUE_TIMES, payload: allTimes});

  };

  export const changeServiceBasedOnGAW = () => (dispatch: Dispatch, getState: () => any): Promise<IScheduleService> => {
    return new Promise(resolve => {

      const {widget} = getState() as IRootState;
      const {schedule, appSettings} = widget;

      const services: IScheduleService[] = BookingService.getActiveServiceForGAW(schedule, appSettings.time);
      const serviceId = services[0].id; // if multiple services present - choose the 1st one
      dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE, payload: {serviceId, clearSelectionMenuOptions: true}} as IActionGen<IChangedActiveServiceUpdate>);

      dispatch({type: BookingActionsTypes.CHANGED_BOOKING_TIME, payload: appSettings.time} as IChangedBookingTime);
      sendTimeAnalytics(widget.activeVenue, widget.booking.viewTime, widget.activeService)

      resolve(widget.activeService);
    })
  }

  export const setTimeForOtherVenues = (time: IScheduleTime) => (dispatch: Dispatch, getState: () => any) => {
    dispatch({type: BookingActionsTypes.SELECTED_OTHER_VENUE_TIME, payload: time});
  };

  export const returnToSittingAndClearTime = () => (dispatch: Dispatch, getState: () => any): Promise<any> => {
    const {widget} = getState() as IRootState;
    const { appSettings, activeVenue, booking, activeService, savedBooking } = widget;

    return new Promise(resolve => {
      RouteService.routeTo(ROUTE_NAMES.SITTING, dispatch, appSettings, activeVenue).then(() => {

        const now = moment();
        dispatch({type: BookingActionsTypes.CHANGED_BOOKING_DATE, payload: booking.moment.isAfter(now) ? booking.moment : now} as IActionGen<Moment>);

        const updatedBooking = (getState() as IRootState).widget.booking; // needs to refetch booking details
        loadScheduleForBooking(dispatch, widget.activeVenue as IVenue, updatedBooking, false, activeService, !!savedBooking)
          .then(() => resolve(null));
      });
    });
  }

  export const changedBookingDate = (date: Moment) => (dispatch: Dispatch, getState: () => any): Promise<any> => {

    const {widget} = getState() as IRootState;

    dispatch({type: BookingActionsTypes.CHANGED_BOOKING_DATE, payload: date} as IActionGen<Moment>);

    if (widget.appSettings.mode === modeType.preview) {
      return Promise.resolve();
    }

    sendDateAnalytics(widget.activeVenue, date.toString());

    const booking = (getState() as IRootState).widget.booking; // needs to refetch booking details
    return loadScheduleForBooking(dispatch, widget.activeVenue as IVenue, booking, false, widget.activeService, !!widget.savedBooking);
  }

  export const readyToLoadSchedule = (useGlobalLoader: boolean) => (dispatch: Dispatch, getState: () => any): Promise<any> => {
    const {widget} = getState() as IRootState;
    return loadScheduleForBooking(dispatch, widget.activeVenue as IVenue, widget.booking, useGlobalLoader, widget.activeService, !!widget.savedBooking);
  }

  export const changedBookingTime = (utcTime: string) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.CHANGED_BOOKING_TIME, payload: utcTime} as IChangedBookingTime);
      const {widget} = getState() as IRootState;
      sendTimeAnalytics(widget.activeVenue, widget.booking.viewTime, widget.activeService)
      resolve();
    })
  }

  export const setVerificationAcceptance = () => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.HAVE_ACCEPTED_VERIFICATION});
      resolve();
    })
  }

  export const changedActiveService = (serviceId: string) => (dispatch: Dispatch, getState: () => any): Promise<IScheduleService> => {
    return new Promise(resolve => {
      const existingState = getState() as IRootState;
      const existsingSectionId: string = existingState.widget.activeSection?.id || "";
      dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SERVICE, payload: {serviceId, clearSelectionMenuOptions: true}} as IActionGen<IChangedActiveServiceUpdate>);

      const {widget} = getState() as IRootState;

      if (existsingSectionId && !!widget.activeSection && existsingSectionId === widget.activeSection.id) {
        // const activeSection = widget.activeService ? widget.activeService.sections.find(s => s.id === existsingSectionId) : null;
        // if (activeSection) {
        //   dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SECTION, payload: existsingSectionId} as IActionGen<string>);
        // }

        const activeSection = widget.activeService ? widget.activeService.sections.find(s => s.id === existsingSectionId) : null;

        const isAvailableTimeForActiveSection = widget.filteredTimes?.some((t) => !t.expired && !t.isDisabled)
        if (isAvailableTimeForActiveSection && activeSection) {
          /**
           * @todo
           * Need to check why need this call.
           * Because when call CHANGED_ACTIVE_SERVICE, section is already choosed, and gets time by TimeFilterService
           */
          dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SECTION, payload: existsingSectionId} as IActionGen<string>);
        } else {
          dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SECTION, payload: null} as IActionGen<string>);
        }
      }

      resolve(widget.activeService);
    })
  }

  export const changedActiveSection = (sectionId: string) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.CHANGED_ACTIVE_SECTION, payload: sectionId} as IActionGen<string>);
      resolve();
    });
  }

  export const updatePaymentBasedOnUpsell = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;
      getPaymentType(dispatch, widget, true);
      resolve();
    })
  }


  export const changedCalenderView = (isTimeViewShown: boolean) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.CHANGED_CALENDER_VIEW, payload: isTimeViewShown});
      resolve();
    });
  }

  export const changedCustomerDetails = (customerDetails: ICustomer, tags: IBookingTag[], isValid: boolean, country: string, phoneWithoutPrefix: string) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.CHANGED_CUSTOMER_DETAILS, payload: {customerDetails, tags, isValid, country, phoneWithoutPrefix}});
      resolve();
    });
  }

  export const changedBookedBy = (customerDetails: ICustomer) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.UPDATE_BOOKEDBY, payload: customerDetails});
      resolve();
    });
  }

  export const setBookedBy = (isBookedBy: boolean) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.IS_BOOKEDBY, payload: isBookedBy});
      resolve();
    });
  }

  export const setIsDefaultSmsOptIn = (defaultSmsOptIn: boolean) => (dispatch: Dispatch): Promise<void> => {
    return new Promise(resolve => {
      dispatch({type: BookingActionsTypes.IS_SMSOPTIN, payload: defaultSmsOptIn});
      resolve();
    });
  }

  // just used for analytics
  export const handleConfirmState = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const {widget} = getState() as IRootState;
    return new Promise(resolve => {
      sendConfirmPaymentDetailsAnalytics(widget.activeVenue);
      resolve();
    });
  }

  export const redirectToOtherVenue = (schedule: ISchedule, time: IScheduleTime) => (dispatch: Dispatch, getState: () => any) => {
    const {widget} = getState() as IRootState;
    const account = widget.accountDetails;
    const covers = widget.booking.covers;
    const date = widget.booking.viewDate;
    const venue = account.ownedVenues.find(venue => venue.id === schedule.venueId);
    const url = BookingService.generateGAWUrl(widget.accountDetails.id, venue, date, covers, time.time);
    window.open(url, '_blank');
  }


  export const saveBooking = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return _saveBooking(dispatch, getState)
      .then(() => verifyHasPromoCode(dispatch, getState));
  }

  export const stripeLoaded = (stripe: stripe.Stripe) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;

      // avoids repeatitive loop around the block
      if (!widget.stripe) {
        dispatch({type: BookingActionsTypes.STRIPE_LOADED, payload: stripe} as IStripeLoaded);
      }
      resolve();
    });
  }

  export const cancelBooking = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {

    const state = getState() as IRootState;
    const {activeVenue, appSettings} = state.widget;

    return new Promise((resolve, reject) => {

      const success = () => {
        sendCancelledOnlineBookingAnalytics(activeVenue);
        dispatch({type: BookingActionsTypes.CANCEL_BOOKING_SUCCESS});
        resolve();
      }

      ClientService.cancelBooking(appSettings.tokenId, (activeVenue as IVenue).id)
        .pipe(first())
        .subscribe(({data}: IResponse<IBookingResponseData>) => {
          finishSession(dispatch);
          // manage booking page will detect the cancelled booking and display the cancelled page
          RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {
            success();
            resolve();
          });
        }, ({response}: { response: IErrorResponse }) => {
          console.warn(NS, 'Cancelling booking failed', response)

          finishSession(dispatch);
          // Check that status is already cancelled
          if (response.data && response.data.message && response.data.message === bookingErrorType.bookingCancelled) {
            // manage booking page will detect the cancelled booking and display the cancelled page
            RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {
              success();
              resolve();
            });
          } else {
            dispatch({type: BookingActionsTypes.CANCEL_BOOKING_FAIL, payload: bookingErrorType.cancellationError});
            reject();
          }
        });
    });
  }


  export const deleteBooking = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    const state = getState() as IRootState;
    const widget = state.widget;
    let {bookingId} = widget.appSettings;

    if (!bookingId && widget.savedBooking) {
      bookingId = widget.savedBooking.bookingId;
    }

    if (!bookingId) {
      return Promise.reject('"bookingId" was undefined');
    }

    return new Promise(resolve => {
      showProcessingOverlayLoader(dispatch, 'Cancelling booking');

      const success = () => {
        sendBookingDeletedAnalytics(widget.activeVenue);
        dispatch({type: BookingActionsTypes.DELETE_BOOKING_SUCCESS});
        appLoadCompleteSuccess(dispatch);
        resolve();
      }

      ClientService.deleteBooking(bookingId, (widget.activeVenue as IVenue).id)
        .pipe(first())
        .subscribe((response: any) => {
          finishSession(dispatch);
          // manage booking page will detect the cancelled booking and display the cancelled page

          if (widget.activeVenue.widgetSettings.enableAdvancedEditingSupport && widget.savedBooking) {
            //if we are editing a booking we may have abandoned a booking, rather than cancelled it
            //so we want to restore the original state before the edit started
            location.reload();
          }
          else {
            RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, widget.appSettings, widget.activeVenue).then(() => {
              success();
            });
          }
          resolve();
        }, ({response}: { response: IErrorResponse }) => {

          finishSession(dispatch);
          console.warn(NS, 'deleteBooking error', response);

          if (response.status === 404) {
            // booking already cancelled
            // manage booking page will detect the cancelled booking and display the cancelled page
            if (widget.activeVenue.widgetSettings.enableAdvancedEditingSupport && widget.savedBooking) {
              //if we are editing a booking we may have abandoned a booking, rather than cancelled it
              //so we want to restore the original state before the edit started
              location.reload();
            }
            else {
              RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, widget.appSettings, widget.activeVenue).then(() => {
                success();
              });
            }
          } else {
            RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, widget.appSettings, widget.activeVenue).then(() => {
              // dispatch({type: BookingActionsTypes.DELETE_BOOKING_FAIL, payload: response});

              // @todo change loadStatus.failed to loadStatus.success and handle the delete booking fail message
              dispatch({
                type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
                  completeLoadStatus: true,
                  status: loadStatus.failed
                }
              } as SetupActionsNS.IAppLoadComplete);
              resolve();
            });
          }

        });
    });
  }


  export const getLatestBookingPaymentDetails = (bookingId: string) => (dispatch: Dispatch, getState: () => any): Promise<IBookingPayment> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;
      const {appSettings} = widget;
      return ClientService.getBookingById(bookingId, appSettings.venueId, false)
        .pipe(
          first(),
        ).subscribe((response) => {
          const data: IBookingResponseData = (response as IResponse<IBookingResponseData>).data;
          resolve(data.paymentPending);
        })
    })
  }


  export const confirmBooking = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise((resolve, reject) => {
      const state = getState() as IRootState;
      const {appSettings, activeVenue, booking, activeService, activeSection} = state.widget;

      ClientService.confirmBooking(appSettings.tokenId, (activeVenue as IVenue).id)
        .pipe(first())
        .subscribe((response: IResponse<IBookingResponseData>) => {

          RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {

            sendOnlineBookingAnalytics(activeVenue, booking, activeService, activeSection);
            dispatch({type: BookingActionsTypes.CONFIRM_BOOKING_SUCCESS, payload: response.data});
            resolve();
          });

        }, ({response}: { response: IErrorResponse }) => {

          if (response.data && response.data.message && response.data.message === bookingSuccessType.bookingConfirmed) {
            RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {
              sendOnlineBookingAnalytics(activeVenue, booking, activeService, activeSection);
              dispatch({type: BookingActionsTypes.CONFIRM_BOOKING_SUCCESS, payload: null});
              resolve();
            });
          } else if (response.data && response.data.message && response.data.message === bookingErrorType.bookingCancelled) {
              RouteService.routeTo(ROUTE_NAMES.MANAGE_BOOKING, dispatch, appSettings, activeVenue).then(() => {
                bookingNotFoundDispatch(dispatch);
                resolve();
              });
          } else {

            console.warn(NS, 'confirmBooking error', response)
            dispatch({type: BookingActionsTypes.CONFIRM_BOOKING_FAIL, payload: response});
            reject();
          }

        });
    });
  }


  export const showPaymentMismatchError = (errorMsg: string) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
    return new Promise(resolve => {
      const {widget} = getState() as IRootState;
      const {appSettings, activeVenue} = widget;
      RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {
        dispatch({type: BookingActionsTypes.SHOW_PAYMENT_MISMATCH_ERROR, payload: errorMsg});
      });
    })
  }


  export const applyPromoCode = (promotionCode: string) => (dispatch: Dispatch, getState: () => any): Promise<IApplyPromoCode> => {
    return _applyPromoCode(promotionCode, dispatch, getState);
  }

  export function getPaymentType(dispatch: Dispatch, widget: IWidgetModel, isUpsellPresent = false) {

    const {booking, activeVenue, activeService} = widget

    let selectedMenuOptions: ISavedBookingMenuOption[] = MenuOptionsService.getFlatExtras(booking.selectedMenuOptions);

    selectedMenuOptions = BookingService.checkForMenuOptionQuantity(booking.selectedMenuOptions) ? selectedMenuOptions : [];

    // first checks if payment on booking options exists and blocks next nav if so
    if (selectedMenuOptions) {
      dispatch({type: NavigationActionsNS.Type.LOADING_PAYMENT_ON_NEXT, payload: true});

      ClientService.getPaymentType((activeVenue as IVenue).id, selectedMenuOptions, activeService.id, booking.utcTime, booking.covers)
        .pipe(first())
        .subscribe(result => {
          dispatch({type: NavigationActionsNS.Type.LOADING_PAYMENT_ON_NEXT, payload: false});
          if (isUpsellPresent) {
            dispatch({type: BookingActionsTypes.IS_UPSELL_PRESENT, payload: true});
          }
          dispatch({
            type: NavigationActionsNS.Type.UPDATED_PAYMENT_TYPE,
            payload: result.data
          } as IActionGen<IPaymentType>);

        }, err => {
          /**
           * An error here will force a generic payment message on summary screen.
           * We don't really need to show an error message to the end user if this happens, since the next screen will be the payment screen,
           * which has different back end logic, and will probably not contain any errors.
           */
          dispatch({type: NavigationActionsNS.Type.LOADING_PAYMENT_ON_NEXT, payload: false});
          dispatch({type: NavigationActionsNS.Type.UPDATED_PAYMENT_TYPE, payload: null} as IActionGen<IPaymentType>);
        });
    }
  }

}
