import { NavigationActionsNS, ITransitionPayload } from "app/actions/navigation/navigationActions";
import { handleActions } from "redux-actions";
import { INavigation, getInitialNavigationState } from "./state";
import { ROUTE_NAMES } from "app/services/route/route.types";
import { SetupActionsNS } from "app/actions/setup/setupActions";
import { IAppLoadedPayload } from "app/actions/setup/interfaces";
import RouteStates from "app/services/route/route.states";
import { IActionGen } from 'app/types/common.types';
import {IframeResizerService} from 'app/services/iframeResizer/iframeResizer.service';

const NS = 'navigationReducer';


export const navigationReducer = handleActions<INavigation, any>(
  {
    [SetupActionsNS.Type.APP_LOAD_SUCCESS]: (state, action) => {

      const payload = action.payload as IAppLoadedPayload;
      const { appSettings } = payload;

      // removes venues from routes when venueid param exists in query string
      const stepRoutes = appSettings && appSettings.venueId ? state.stepRoutes.filter(r => r !== ROUTE_NAMES.VENUES) : state.stepRoutes;
      const currentRouteName = RouteStates.getCurrentRoute().name as ROUTE_NAMES;

      return {
        ...state,
        stepRoutes,
        currentStepIndex: stepRoutes.indexOf(currentRouteName),
        currentRouteName
      }
    },


    [NavigationActionsNS.Type.CHANGED_ROUTE_BY_NAME]: (state, action) => {
      return {
        ...state,
        currentStepIndex: state.stepRoutes.indexOf(action.payload),
        currentRouteName: action.payload
      }
    },

    [NavigationActionsNS.Type.PASS_ROBOT_CHECK]: (state, action) => {
      return {
        ...state,
        passRobotCheck: true
      }
    },

    [NavigationActionsNS.Type.TRANSITION_STARTED]: (state, action: ITransitionPayload): INavigation => {

      const { from, to, id } = action.payload;

      const transitions = state.transitions.slice();
      transitions.push({from, to, id});
      IframeResizerService.notifyParentIFrameOfSectionChange(to);
      return {
        ...state,
        transitions
      }
    },

    [NavigationActionsNS.Type.TRANSITION_FINISHED]: (state, action: ITransitionPayload): INavigation => {

      const { id } = action.payload;
      const transitions = state.transitions.slice();
      const deleteIndex = transitions.findIndex(t => t.id === id);
      transitions.splice(deleteIndex, 1);
      return {
        ...state,
        transitions
      }
    },

    [NavigationActionsNS.Type.LOADING_PAYMENT_ON_NEXT]: (state, action: IActionGen<boolean>): INavigation => {

      return {
        ...state,
        loadingPaymentOnNext: action.payload
      }
    }
  },
  getInitialNavigationState()
);
