import i18next from 'i18next';
import React, { ReactElement } from 'react';

export class IntlService {

    static getLocale = () => navigator.language || (navigator as any).browserLanguage || (navigator.languages || ["en"])[0];

    private static isDollarCurrency(currency: string): boolean {
        return currency === 'AUD' || currency === 'USD' || currency === 'NZD' || currency === 'CAD';
    }

    static currencyValue(val: number, currency: string): ReactElement {
        if (!val) {
            return null;
        }

        // for these countries just use a dollar symbol. Everyone else gets internationalization
        if (IntlService.isDollarCurrency(currency)) {
            return <span>${val.toFixed(2)}</span>;
        }

        const isSGD = currency === 'SGD'; // bug in intl renders as `SGD` instead of `$S` so we need to add exception
        return isSGD ?
            <span>S${val.toFixed(2)}</span>
            : <span>{this.currencyValueAsString(val, currency)}</span>
    }

    static currencyValueAsString(val: number, currency: string): string {
        if (!val) {
            return '';
        }

        // for these countries just use a dollar symbol. Everyone else gets internationalization
        if (IntlService.isDollarCurrency(currency)) {
            return `$${val.toFixed(2)}`;
        }
        return new Intl.NumberFormat(
            i18next.language,
            { style: "currency", currency }
          ).format(val);
        }

}