import React, { useMemo, useState } from 'react';
import { IDispatchFromProps, IStateFromProps } from './types';
import style from './style.module.scss';
import {Paper, makeStyles} from '@material-ui/core';
import classNames from 'classnames';
import AlertPanel from 'app/components/AlertPanel';
import DateUtilsService from "shared-services/date-utils-service";
import moment from "moment";

const NS = 'StandbyConfirmPanel';

const useStyles = makeStyles({
  confirmPanel: {
    '&.MuiAlert-root': {
      backgroundColor: '#4669ac',
    }
  },
});

export default function StandbyConfirmPanel({theme, wrapperStyle, handleOk, handleNo, date}: IStateFromProps & IDispatchFromProps) {

  const classes = useStyles(theme);

  const _date: string = useMemo(() => DateUtilsService.getViewDateFromMoment(moment(date)), [date]);

  const [msg, setMsg] = useState<string>("You are already on the Standby List for " + _date + ". Do you want to cancel your existing request on the Standby List and create this as a new one?")

  const [buttonText, setButtonText] = useState<string>('No');
  const [cancelText, setCancelText] = useState<string>('Yes');

  const onHandleOk = () => {
    setMsg('Your booking was not created');
    setButtonText('');
    setCancelText('');
    setTimeout(() => {
      handleOk();
    }, 1500)
  }

  return (
    <Paper elevation={1} className={classNames({
      [style.paperBox]: true,
      [style.paperBox2]: true
    })}>

      <div>
        <AlertPanel
          wrapperStyle={wrapperStyle}
          message={msg}
          buttonText={buttonText}
          buttonHandler={onHandleOk}
          cancelText={cancelText}
          cancelHandler={handleNo}
          classes={classes.confirmPanel}
        />
      </div>
    </Paper>
  )
}
