import React from 'react';
import style from './style.module.scss';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ColumnWrap2Container from '../ColumnWrap2/container';
import FooterNavContainer from '../FooterNav/container';
import { ISimpleVenue, IStateFromProps, IDispatchFromProps } from './interfaces';
import { renderIf } from 'app/services/utils/utils.service';
import AlertPanel from '../AlertPanel';
import { ROUTE_NAMES } from 'app/services/route/route.types';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";
import { Trans, useTranslation } from 'react-i18next';

const NS = 'Venues';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      color: theme.palette.primary.main
    },
    listItemActive: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main + '!important'
    }
  }),
);

export default function Venues({
  hasAccountDetails, selectedVenueId, theme, venuesList, businessName, accountPhone, wrapperStyle, triedNext,
  handleVenueClick
}: IStateFromProps & IDispatchFromProps) {
  const { t } = useTranslation("venues");
  const classes = useStyles({theme});
  const isStacked = IframeResizerService.isStacked(wrapperStyle);

  return (
    <ColumnWrap2Container>
      {/* left */}
      <form className={style.root}>
        {hasAccountDetails ?
          <div>
            <Card elevation={2}>
              <CardContent className={style.cardContent}>
                <div className={style.headingWrap}>
                  <Typography variant="h2" className={[style.heading, classes.heading].join(' ')}>
                    {businessName}
                  </Typography>
                  {accountPhone ?
                    <p className={style.phone}>
                      <Trans t={t}>
                        Phone: {{accountPhone}}
                      </Trans>
                    </p>
                    : ''}
                </div>
                <List component="nav" aria-label="venues"
                  className={classNames({
                    [style.list]: true,
                    [style.listIsLandscape]: !isStacked,
                  })}>
                  {venuesList.map((venue: ISimpleVenue, index: number) => (
                    <ListItem
                      selected={selectedVenueId && venue.id === selectedVenueId}
                      className={classNames({
                        [style.listItem]: true,
                        [style.listItemIsLandscape]: !isStacked,
                      })}
                      key={index}
                      button
                      onClick={() => handleVenueClick(venuesList[index])}
                      classes={{
                        selected: classes.listItemActive
                      }}
                    >
                      <ListItemText primary={venue.name}></ListItemText>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </div>
          :
          <div>
            @TODO: handle no account details
          </div>
        }
      </form>

      {/* right */}
      <div className={classNames({
          [style.rightColumnInner]: true,
          [style.rightColumnInnerIsStacked]: isStacked
        })}>
        <Typography variant="subtitle1" align="center" className={style.msg}>
          <Trans t={t}>
            Please select your venue and click next to proceed.
          </Trans>
        </Typography>

        <div>
          {renderIf(triedNext === ROUTE_NAMES.VENUES, () => (
            <div className={style.alert}>
              <AlertPanel wrapperStyle={wrapperStyle} message={t("You must select a venue to proceed")} />
            </div>
          ))}
          <FooterNavContainer />
        </div>
      </div>

    </ColumnWrap2Container>
  )
}
