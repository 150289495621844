import { from, Observable, of } from "rxjs";
import axios, { AxiosAdapter, AxiosInstance, AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import {
    IResponse,
    IFunctionPaymentSummaryResponseData,
    IPayNowParams,
    IPrepareEwayData,
    IPrepareEwayFunctionData,
    ISavePreAuthData,
    ISavePreAuthResponseData,
    IFinilisePayment3DSecureParams,
    IStripePaidData, IEwaySummaryResponseData, IPromoCodeResponseData, IHasPromoCodeResponseData, IErrorResponse,
    ISetupPreAuth3DData,
    ISetupPreAuth3DResponse,
    IFinalizePreAuth3DData,
    IFinalizePreAuth3DResponse,
    IFinalisePayment3DSecurePFParams
} from "shared-types/index";
import { IFinalizePreAuthExistingCardData, IFinalizePreAuthExistingCardResponse } from "./payment.types";

const NS = 'PaymentApiRequests';

// @todo: check cache time required. severity: low
const cache = setupCache({
    maxAge: 24 * 60 * 60 * 1000, // cached for 1 day
    exclude: {
      paths: [/paymentintent/]
    }
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
    adapter: cache.adapter as AxiosAdapter
});

interface IAddEndpoints {
    payNowUrl: string;
    eventPayNowUrl: string;
    eventCompletedUrl: string;
    preAuthUrl: string;
    applyPromoCodeUrl: string;
    hasPromoCodeUrl: string;
    getPaymentIntent3DSecureUrl: (venueId: number, bookingId: string) => string;
    finilisePayment3DSecureUrl: string;
    ewayPaymentSummaryUrl: string;
    setupPreAuthStripe3DUrl: string,
    finalizePreAuthStripe3DUrl: string,
    finalisePayment3DSecureForPFUrl: string,
    getPaymentIntent3DSecureForPFUrl: (venueId: number) => string,
    finalizePreAuth3DExistingCardUrl: string,
    finalizePreAuthEwayExistingCardUrl: string,
}

class PaymentApiRequests {
    private endpoints: IAddEndpoints;

    addEndpoints(endpoints: IAddEndpoints): void {
        this.endpoints = endpoints;
    }

    /**
     * Should only get called once per session/app load.
     * Used for better SEQ logging.
     * Same thing also in ClientService.
     */
    setUpRequestInterceptor(source: string, globalSessionCorrelationId: string): void {
        const requestInterceptor = (config: AxiosRequestConfig) => {
            config.headers['X-NBI-CorrelationId'] = globalSessionCorrelationId;
            config.headers['X-NBI-Source'] = source; // 'widget2';
            return config;
        };
        api.interceptors.request.use(requestInterceptor);
    }


    /**
     * The calls to eway and stripe are used to create a token, but the actual payment gets done on the back end
     * after this call gets made. FYI, using test credit cards that reject the payment, tend to always return 400 (Bad Request) responses.
     */
    payNow(params: IPayNowParams): Observable<IResponse<IPrepareEwayData>> {

        if (!this.endpoints.payNowUrl) {
            console.warn(NS, `'payNowUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.post(this.endpoints.payNowUrl, { ...params })
        );
    }

    getFunctionPaymentSummary(
        venueId: number,
        eventId: string,
        accessCode: string = null,
        stripePaymentToken: string = null
    ): Observable<IResponse<IFunctionPaymentSummaryResponseData>> {

        if (!this.endpoints.eventCompletedUrl) {
            console.warn(NS, `'eventCompletedUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.get(this.endpoints.eventCompletedUrl, {
                params: {
                    venueId, eventId, accessCode, stripePaymentToken
                }
            })
        );
    }

    eventPaynow(params: IPayNowParams): Observable<IResponse<IPrepareEwayFunctionData>> {

        if (!this.endpoints.eventPayNowUrl) {
            console.warn(NS, `'eventPayNowUrl' has not been defined!`)
            return of(null);
        }

        return from(
            api.post(this.endpoints.eventPayNowUrl, { ...params })
        )
    }

    ewayPaymentSummary(bookingId: string, venueId: number, accessCode: string): Observable<IResponse<IEwaySummaryResponseData>> {
        const params = {
            venueId,
            bookingId,
            accessCode
        };
        return from(
            api.get(this.endpoints.ewayPaymentSummaryUrl, { params })
        );
    }

    savePreAuth(params: ISavePreAuthData): Observable<IResponse<ISavePreAuthResponseData>> {
        return from(
            api.post(this.endpoints.preAuthUrl, { ...params })
        );
    }


    paymentIntent3DSecure(bookingId: string, venueId: number): Observable<IResponse<string>> {
        return from(
            api.get(this.endpoints.getPaymentIntent3DSecureUrl(venueId, bookingId))
        );
    }


    finilisePayment3DSecure(bookingId: string, venueId: number, TransactionId: string, AmountPaidId: number): Observable<IResponse<IStripePaidData>> {
        const params: IFinilisePayment3DSecureParams = {
            venueId,
            bookingId,
            TransactionId,
            AmountPaidId
        };
        return from(
            api.post(this.endpoints.finilisePayment3DSecureUrl, { ...params })
        )
    }

    setupPreAuthStripe3D(params: ISetupPreAuth3DData): Observable<ISetupPreAuth3DResponse> {
        console.log("setupPreAuth3D");
        return from(
            api.post(this.endpoints.setupPreAuthStripe3DUrl, { ...params })
        );
    }

    finalizePreAuthStripe3D(params: IFinalizePreAuth3DData): Observable<IFinalizePreAuth3DResponse> {
        console.log("finalizePreAuth3D");
        return from(
            api.post(this.endpoints.finalizePreAuthStripe3DUrl, { ...params })
        );
    }


    hasPromoCode(bookingId: string, venueId: number): Observable<IResponse<IHasPromoCodeResponseData>> {
        const params = {
            venueId,
            bookingId
        }
        return from(
            api.post(this.endpoints.hasPromoCodeUrl, { ...params })
        );
    }

    applyPromoCode(promotionCode: string, bookingId: string, venueId: number): Observable<IResponse<IPromoCodeResponseData>> {
        const params = {
            venueId,
            bookingId,
            promotionCode
        }
        return from(
            api.post(this.endpoints.applyPromoCodeUrl, { ...params })
        );
    }

    finalisePayment3DSecureForPF(eventId: string, venueId: number, transactionId: string, amountPaidId: number): Observable<IResponse<any>> {
        const params: IFinalisePayment3DSecurePFParams = {
            venueId,
            eventId,
            transactionId,
            amountPaidId
        };
        return from(
            api.post(this.endpoints.finalisePayment3DSecureForPFUrl, { ...params }) //This doesn't actually return anything, why?
        );
    }

    getPaymentIntent3DSecureForPF(bookingId: string, venueId: number): Observable<IResponse<string>> {
        //This endpoint is weird and takes eventId as bookingId see https://nowbookit.atlassian.net/browse/NBI-5558
        const bookingParam = {
            bookingId: bookingId
        };
        return from(
            api.post(this.endpoints.getPaymentIntent3DSecureForPFUrl(venueId), { ...bookingParam })
        );
    }

    finalizePreAuth3DExistingCard(params: IFinalizePreAuthExistingCardData): Observable<IFinalizePreAuthExistingCardResponse> {
        return from(
            api.post(this.endpoints.finalizePreAuth3DExistingCardUrl, { ...params })
        );
    }

    finalizePreAuthEwayExistingCard(params: IFinalizePreAuthExistingCardData): Promise<IFinalizePreAuthExistingCardResponse> {
        return  api.post(this.endpoints.finalizePreAuthEwayExistingCardUrl, { ...params })
        
    }
}

// instance needed for stripeInstance reference
const instance = new PaymentApiRequests();
export { instance as PaymentApiRequests }
