import React from 'react';
import { IGroupedTablesBox } from './types';
import style from './style.module.scss';
import { Paper, Typography, Divider, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { renderIf } from 'app/services/utils/utils.service';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useLocalizeTime } from 'app/services/localization/useLocalizeTime';


const NS = 'GroupedTablesBox';

export default function GroupedTablesBox({ groups, children }: IGroupedTablesBox & {children?: any}) {
  const { t } = useTranslation("payment");
  const { localizeTime } = useLocalizeTime();
  return (
    <Paper elevation={1} className={style.paperBox}>

      {groups.map((group, i) => (
        <div key={`payment-conf-group-${i}`} className={style.group}>

          {renderIf(group.heading, () => (
            <>
            <Typography variant="h3" className={classNames({
                [style.heading]: !group.subtitle,
              })}>
                <Trans t={t}>
                  {group.heading}
                </Trans>
              </Typography>
              {group.subtitle && <Typography className={classNames({
                [style.heading]: true,
                [style.bookedBySubHeading]: true,
              })}>
                <Trans t={t}>
                  {group.subtitle}
                </Trans>
              </Typography>}

              <Divider className={style.divider} />
            </>
          ))}


          <Table aria-label="summary" size="small">
            <TableBody>
              {group.items.map((item, j) => !item.value ? null : (
                <TableRow key={`payment-conf-item-${j}`}>
                  <TableCell>{t(item.name)} {item.prefix}</TableCell>
                  <TableCell>
                    {
                      item.name === "Time" && typeof item.value === "string"
                        ? localizeTime(item.value)
                        : item.value
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ))}

      {children}

    </Paper>
  )
}
