import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import {
  BookingType,
  IBookingTag,
  IVenue,
  IBookingResponseData,
  IPrivateFunction,
  IWidgetBooking,
  servicePaymentType,
  IWidgetCustomer,
  preAuthStatus,
  ISavedBookingSelectedOptions,
  IBookingMenuOption,
  IBookingMenuOptionExtrasUpdater,
  IServicePaymentOption,
} from 'shared-types/index';
import DateUtilsService from 'external-services/date-utils-service';
import {MenuOptionsService} from 'external-services/menu-options-service';
import { IntlService } from 'app/services/intl/intlService';

const NS = 'BookingService';

export default class BookingService {
  static getBookingOptions(
    options: IServicePaymentOption[],
    selectedOptions: IBookingMenuOption[],
    covers: number,
    singleMenuPerBooking: boolean,
    isUpsell = false
  ): IBookingMenuOption[] {
    if (!singleMenuPerBooking) {
      return options.map((option) => {
        let item: IBookingMenuOption;
        // if selectedOption exists, use it
        if (!isUpsell) {
          item = selectedOptions && selectedOptions.find((s) => s.menuOptionId === option.id);
        } else {
          item =
            selectedOptions &&
            selectedOptions.find((s) => s.menuOptionId === option.id && s.isUpsellItem);
        }
        if (item) {
          return item;
        }

        // otherwise create a new one
        return {
          isUpsellItem: isUpsell,
          menuOptionId: option.id,
          quantity: 0,
        };
      });
    }

    if (!selectedOptions || !selectedOptions.length) {
      return [];
    }

    // if the types of options are toggled so get the first and change quantity to covers
    let firstOptWithQuantity: IBookingMenuOption = selectedOptions.find(
      (o) => {
        // isUpsellItem must be falsy (not specifically false) so that undefined is picked up as well
        return o.quantity > 0 && !o.isUpsellItem;
      }
    );
    if (firstOptWithQuantity) {
      firstOptWithQuantity = { ...firstOptWithQuantity, quantity: covers };
      return [firstOptWithQuantity];
    }

    return [];
  }

  // gets used in Group Widget, but Widget V2 also has a copy of it in src/app/services/booking/booking.service.ts
  static getBookingTimeWithOffset(bookingTime: Date, offsetHours = 0, offsetMins = 0): Date {
    const bookingTimeWithOffset: Date = cloneDeep(bookingTime);

    if (offsetHours != 0) {
      bookingTimeWithOffset.setHours(bookingTimeWithOffset.getHours() - offsetHours);
    }
    if (offsetMins != 0) {
      bookingTimeWithOffset.setMinutes(bookingTimeWithOffset.getMinutes() - offsetMins);
    }

    return bookingTimeWithOffset;
  }

  static getVenueTime(activeVenue: IVenue): Date {
    return DateUtilsService.getJsDate(DateUtilsService.getCorrectTime(activeVenue.timeZoneId));
  }

  static getBookingObj(data: IPrivateFunction | IBookingResponseData): IWidgetBooking {
    const bookingMoment = BookingService.getBookingMoment(data.time);
    const selectedOptions = (data as IBookingResponseData).selectedOptions;

    const booking: IWidgetBooking = {
      _id: (data as IBookingResponseData)._id || null,
      moment: bookingMoment,
      viewDate: IntlService.formatDate(bookingMoment),
      covers: data.people,
      viewTime: bookingMoment.format('h:mm a'),
      rhinoTime: data.time,
      status: data.status && data.status.statusType ? data.status.statusType : null,
      serviceId: data.serviceId || null,
      serviceName: data.serviceName || null,
      sectionId: data.preferredSectionId || (data as IPrivateFunction).sectionId || null,
      locked: data.locked || null,
      onlineEditingDisabled: data.onlineEditingDisabled || null,

      utcTime: BookingService.getUTCTime(bookingMoment),

      customer: BookingService.getCustomerLikeObjectFromResponse(data as IBookingResponseData),

      payment: data.paymentPending || null,
      notes: (data as IBookingResponseData).notes ? (data as IBookingResponseData).notes : '',
      selectedMenuOptions: selectedOptions
        ? BookingService.convertSelectedBookingOptions(
            selectedOptions.filter((opts) => !opts.isUpsellItem)
          )
        : null,
      selectedUpsellOptions: selectedOptions
        ? BookingService.convertSelectedBookingOptions(
            selectedOptions.filter((opts) => opts.isUpsellItem)
          )
        : null,
      bookingType: (data as IBookingResponseData).bookingType || BookingType.Booking,

      standByConfirmationExpiry: (data as IBookingResponseData).standByConfirmationExpiry || null,
      method: (data as IBookingResponseData).method || null,
      tables: (data as IBookingResponseData).tables || null,
      hasManagerNotes: (data as IBookingResponseData).hasManagerNotes || false,
      hasCustomerNotes: (data as IBookingResponseData).hasCustomerNotes || false,
      onlineCancelDisabled: data.onlineCancelDisabled || null,
    };

    if (data.tags && (data.tags as IBookingTag[]).length) {
      if (data.tags && (data.tags as IBookingTag[]).length) {
        const bookingTags: IBookingTag[] = data.tags as IBookingTag[];
        booking.savedTags = bookingTags.map((bookingTags) => {
          return bookingTags._id;
        });
      } else if (data.tags) {
        booking.savedTags = [(data.tags as IBookingTag)._id];
      }
    }

    if (data.paymentSummary) {
      booking.payment.cardLast4 = data.paymentSummary.cardLast4;
      if (data.paymentSummary.amountPaid > 0) {
        booking.payment.amountPaid = data.paymentSummary.amountPaid;
      }
    }

    /**
     * For preauth prevent user from going hitting the payments page twice
     * so use the PreAuthHeldDate because amountPaid will still be zero
     */
    if (
      data.paymentSummary &&
      data.paymentSummary.paymentType === servicePaymentType.preAuth &&
      data.paymentSummary.preAuthHeldDate &&
      /**
       * Only setting amountPaid if preAuthStatus is holding.
       * If preAuthStatus is 'released' treats the pre-auth as unauthorized/unpaid.
       */
      data.paymentSummary.preAuthStatus === preAuthStatus.holding
    ) {
      booking.payment.amountPaid = data.paymentSummary.amountDue;
    }

    return booking;
  }

  private static getCustomerLikeObjectFromResponse(data: IBookingResponseData): IWidgetCustomer {
    if (data.customer) {
      return data.customer;
    }

    const { firstName, lastName, email, company, phone, notes } = data;

    return {
      firstName,
      lastName,
      email,
      company,
      phone,
      notes,
      subscribed: false,
    };
  }

  /**
   * Booking moment displaying browser time so time will always
   * display correct even if the customer is in a different timezone
   */
  private static getBookingMoment(time: string): Moment {
    return moment(time, 'YYYY/MM/DD HH:mm', true);
  }

  /**
   * Remove offset from utcTime as time as we dont know what timezone the
   * browser may be set to
   */
  private static getUTCTime(bookingMoment: Moment): string {
    const utcTime: string = bookingMoment.format();
    return utcTime.substring(0, utcTime.length - 6);
  }

  static convertSelectedBookingOptions(opts: ISavedBookingSelectedOptions[]): IBookingMenuOption[] {
    const returnVal: IBookingMenuOption[] = opts.reduce(
      (acc, { id, quantity, extras, isUpsellItem }) => {
        const opt: IBookingMenuOption = { menuOptionId: id, quantity, isUpsellItem };
        if (extras && extras.length) {
          opt.extras = MenuOptionsService.getEmptyExtrasMenuOption();

          const explicitChildMenuOptions = extras.filter((o) => o.isExplicit);
          const implicitChildMenuOptions = extras.filter((o) => !o.isExplicit);

          // this is a multi-dimensional array, but only the first item is populated
          opt.extras.explicitChildMenuOptions = [
            explicitChildMenuOptions.map((o) => ({ menuOptionId: o.id, quantity: o.quantity })),
          ];

          opt.extras.implicitChildMenuOptions = implicitChildMenuOptions.map((o) => ({
            menuOptionId: o.id,
            quantity: o.quantity,
          }));
        }

        const existingOptWithSameId: IBookingMenuOption = acc.find(
          ({ menuOptionId }) => opt.menuOptionId === menuOptionId
        );
        if (existingOptWithSameId) {
          this.combineMenuOptions(existingOptWithSameId, opt);
        } else {
          acc.push(opt);
        }

        return acc;
      },
      []
    );

    this.fillEmptyExplicitSlots(returnVal);

    return returnVal;
  }

  private static fillEmptyExplicitSlots(opts: IBookingMenuOption[]): void {
    // fill `explicitChildMenuOptions` empties if there are any
    opts.forEach((opt) => {
      if (
        opt.extras &&
        !opt.extras.isSameForAll &&
        opt.extras.explicitChildMenuOptions.length !== opt.quantity
      ) {
        const start = opt.extras.explicitChildMenuOptions.length;
        for (let i = start; i < opt.quantity; i++) {
          opt.extras.explicitChildMenuOptions.push([]);
        }
      }
    });
  }

  /**
   * Merges props from opt2 into opt1
   */
  private static combineMenuOptions(
    opt1: IBookingMenuOption,
    opt2: IBookingMenuOption
  ): IBookingMenuOption {
    opt1.quantity += opt2.quantity;
    if (opt2.extras) {
      opt1.extras = opt1.extras || MenuOptionsService.getEmptyExtrasMenuOption();
      opt1.extras.explicitChildMenuOptions = [
        ...opt1.extras.explicitChildMenuOptions,
        ...opt2.extras.explicitChildMenuOptions,
      ];
      opt1.extras.implicitChildMenuOptions = [
        ...opt1.extras.implicitChildMenuOptions,
        ...opt2.extras.implicitChildMenuOptions,
      ];
    }

    if (opt1.extras) {
      opt1.extras.isSameForAll = this.menuOptionExtraIsSameForAll(
        opt1.extras.explicitChildMenuOptions[0],
        opt1.quantity
      );
    }
    return opt1;
  }

  // we can figure out if `isSameForAll` has been checked by seeing if there are more than 1 quantity for `explicitChildMenuOptions`
  private static menuOptionExtraIsSameForAll(
    explicitChildMenuOptions: IBookingMenuOption[],
    parentQuantity: number
  ): boolean {
    const firstExplicitOpt =
      explicitChildMenuOptions && explicitChildMenuOptions.length && explicitChildMenuOptions[0];
    return !firstExplicitOpt || parentQuantity === 1 || firstExplicitOpt.quantity > 1;
  }

  static isStandby(
    isStandByList: boolean,
    day: string,
    standByListDaysWeekDays: string[]
  ): boolean {
    if (!isStandByList) {
      return isStandByList;
    }
    return standByListDaysWeekDays.includes(day);
  }

  static getSelectionData(
    selectedUpsellOptions: IBookingMenuOption[],
    upsellHeading: string,
    upsellDescription: string
  ): IBookingMenuOptionExtrasUpdater {
    return {
      explicitChildMenuOptions: [],
      implicitChildMenuOptions: selectedUpsellOptions,
      isSameForAll: true,
      parentLabel: upsellHeading,
      upsellDescription,
      parentQuantity: 0,
    };
  }

  /**
   * Use this method to sort tag icons in ascending order i.e. ["Dietary", "Occasion", "Verified"].sort(compareTagIcons)
   * Verified tag appears at the top of the list.
   * 1. If the return value < than 0, then it sorts firstElement before secondElement.
   * e.g. If firstElement='Dietary' and secondElement='Occasion', then Dietary is placed before Occasion.
   * 2. If the return value > than 0, then it sorts secondElement before firstElement.
   * e.g. If firstElement='Occasion' and secondElement='Dietary', then Occasion is placed before Dietary.
   * 3. If the return value = 0, then firstElement and secondElement are considered equal.
   * e.g. If firstElement='Dietary' and secondElement='Dietary', then no change take place.
   */
  static compareTagIcons(firstElement: string, secondElement: string): number {
    if (firstElement === 'Verified') return -1;

    if (secondElement === 'Verified') return 1;

    if (firstElement < secondElement) return -1;

    if (firstElement > secondElement) return 1;

    return 0;
  }
}
