// @ts-ignore
import * as queryString from 'query-string-es5';

import {
  IOwnedVenue,
  ISchedule,
  IWidgetSettings,
  paymentProviderType,
  paymentProviderTypeUppercase
} from "../client/client.types";
import {
  bookingStatusCode,
  bookingStatusName,
  bookingStatusType,
  BookingType,
  IBooking, IBookingResponseData,
  ICustomer,
  ISavedExtras
} from "../booking/booking.types";
import MockAdapter from 'axios-mock-adapter';
import {ClientService} from "../client/client.service";
import {PaymentService} from "../payment/payment.service";
import {IPaymentSummaryResponseData, IProcessEwayPaymentResponse} from "../payment/payment.types";
import moment from "moment";
import {blockNavType, IVenue, IWidgetModel, themeTypes} from "app/models";
import {IRootState, RootState} from "app/reducers";
import {
  getInitialNavigationState,
  getInitialSetupState,
  getInitialWidgetState,
  INavigation,
  ISetup
} from "app/reducers/state";
import {ISelectableTime} from "app/components/TimePicker/types";
import {IPrepareEwayData, IWidgetTheme, bookingErrorType} from "shared-types/WidgetTypes";
import {
  IScheduleService,
  IServicePaymentOption,
  servicePaymentType,
  IServicePaymentDetails,
  IScheduleTime,
  ISectionOrder,
  IBookingTag
} from "shared-types/index";

const SERVICE_ID_1 = 'service_TEST1';
const SERVICE_NAME_1 = 'Dinner';
const BOOKING_ID_1 = 'booking_2019-11-30_TEST_1';
const FAKE_ACCESS_CODE = 'FAKE_ACCESS_CODE';
const FAKE_PAYMENT_URL = 'http://fake-payment-url';

let clientServiceMockAdapter: MockAdapter;
let paymentServiceMockAdapter: MockAdapter;

type StripeErrorType = 'api_connection_error'
    | 'api_error'
    | 'authentication_error'
    | 'card_error'
    | 'idempotency_error'
    | 'invalid_request_error'
    | 'rate_limit_error'
    | 'validation_error';

export class MocksService {

  static ID_BOTTLE_OF_RED_WINE = 'menuoption_BOTTLE_OF_RED_WINE';
  static ID_GRAVY = 'menuoption_GRAVY';
  static ID_STEAK = 'menuoption_STEAK';

  static setMockAdapter() {
    if (!clientServiceMockAdapter) {
      clientServiceMockAdapter = new MockAdapter(ClientService.getAxiosInstance(), { delayResponse: 1000 });
    }
    if (!paymentServiceMockAdapter) {
      paymentServiceMockAdapter = new MockAdapter(PaymentService.getAxiosInstance(), { delayResponse: 1000 });
    }
  }

  static getState(
    widget: RootState.WidgetState = getInitialWidgetState(),
    navigation: INavigation = getInitialNavigationState(),
    setup: ISetup = getInitialSetupState()
  ): IRootState {
    return { widget, navigation, setup };
  }

  static setMockCancelBooking(venueId: number, isBookingCancelled?: boolean): void {
    if (isBookingCancelled) {
      const params: any = queryString.parse(location.search) as unknown;
      if (params.token) {

        clientServiceMockAdapter.onPost(ClientService.getCancelBookingUrl(venueId, params.token))
          .reply(() => {
            return [400, {
              message: bookingErrorType.bookingCancelled
            }]
          });
      }
    }
  }

  static setMockPaymentType(venueId: number, amount = 1, paymentTypeName = servicePaymentType.fullPayment): void {
    clientServiceMockAdapter.onPost(ClientService.getPaymentTypeUrl(venueId))
      .reply(() => {
        return [200, {
          amount,
          paymentTypeName
        }]
      });
  }

  static setMockErrorPromoCode(errorType: number, message = 'Sorry, there was a problem'): void {
    paymentServiceMockAdapter.onPost(PaymentService.PROMO_CODE_URL)
      .reply(errorType, { message });
  }


  static setMockSuccessPromoCode(): void {
    paymentServiceMockAdapter.onPost(PaymentService.PROMO_CODE_URL)
      .reply(200, {
        amountDue: 6,
        discountAmount: 4
      });
  }

  static setMockGetSchedule(venueId: number, errorType: number = null): void {
    if (errorType) {
      clientServiceMockAdapter.onGet(ClientService.getScheduleUrl(venueId))
        .reply(errorType);
      return;
    }

    paymentServiceMockAdapter.onPost(PaymentService.PAY_NOW_URL)
      .reply(200, {
        blockoutMessage: null,
        isVenueOpen: true,
        services: [this.getService('1')],
        tags: []
      });
  }

  static setMockEwayPaymentData(
    payNowErrorType: number = null,
    paymentSummaryErrorType: number = null,
    processError: 'timeout' | 'serverError' = null
  ) {
    this.mockPayNow(payNowErrorType, true);
    this.mockPaymentSummary(paymentSummaryErrorType);
    this.mockEwayProcessSuccess(processError);
  }

  /**
   * Sets mocks for stripe payments.
   * 'stripe' is used for setting errors on the stripe object
   */
  static setMockStripePaymentData(
      stripe: stripe.Stripe = null,
      payNowErrorType: number = null,
      preauthErrorType: number = null
    ) {
    if (stripe) {
      // resolves to an error when called
      stripe.createPaymentMethod = () => Promise.resolve({
        error: {
          /**
           * The type of error returned.
           */
          type: 'card_error' as StripeErrorType,

          /**
           * For card errors, the ID of the failed charge.
           */
          charge: 'ABCD',

          /**
           * For some errors that could be handled programmatically,
           * a short string indicating the error code reported.
           */
          code: 'ABC',

          /**
           * A URL to more information about the error code reported.
           */
          doc_url: 'http://path-to-further-docs-about-code',

          /**
           * A human-readable message providing more details about the
           * error. For card errors, these messages can be shown to
           * your users.
           */
          message: 'Sorry, there was a problem processing your payment',

          /**
           * The PaymentMethod object for errors returned on a
           * request involving a PaymentMethod.
           */
          payment_method: this.getPaymentMethod()
        }
      });
    }

    if (preauthErrorType) {
      paymentServiceMockAdapter.onPost(PaymentService.PRE_AUTH_URL)
        .reply(preauthErrorType);
      return;
    }
    this.mockPayNow(payNowErrorType, false);
  }

  /**
   * year uses current year and date is December 31st so that it will be a future date (almost always)
   */
  static getBooking(year: number = (new Date()).getFullYear()): IBooking {

    const { _id, firstName, lastName, phone, email, notes, company } = this.getCustomerDetails();
    return {
      "moment": moment(`${year}-30-22T19:40:00.000Z`),
      "viewDate": `Thursday, December 31, ${year}`,
      "covers": 1,
      "viewTime": "6:40 am",
      "rhinoTime": `${year}/12/31 06:40`,
      "status": bookingStatusType.unconfirmed,
      "serviceId": "service_ZTF60ANAYHA1H_1570589968666",
      "serviceName": "Breakfast",
      "sectionId": "section_GIYOEZ9CC963K",
      "locked": null,
      "onlineEditingDisabled": null,
      "utcTime": `${year}-12-31T06:40:00`,
      "payment": null,
      "isFromDiary": true,
      customer: {
        _id, firstName, lastName, phone, email, notes, company,
        subscribed: false
      },
      bookingType: BookingType.Booking,
      hasManagerNotes: false,
      hasCustomerNotes: false
    }
  }


  static getBookingResponseData(venueID: number): IBookingResponseData {

    const { _id, firstName, lastName, phone, email, notes, company } = this.getCustomerDetails();
    return {
      bookedAt: "2019-11-29T02:03:02.8191738+00:00",
      bookingRef: "32582819",
      customer: {
        firstName,
        lastName,
        phone,
        phone2: null,
        phoneNational: null,
        email,
        birthdayDate: null,
        birthdayMonth: null,
        birthdayYear: null,
        company,
        streetAddress: null,
        suburb: null,
        postcode: null,
        notes,
        managerNotes: null,
        subscribed: true,
        tags: [],
        noShowCount: 0,
        lastModifiedDate: "2019-12-04T10:07:20.6372948+00:00",
        _id,
        type: "customer"
      },
      duration: 30,
      hasCustomerNotes: false,
      hasManagerNotes: false,
      lastModifiedDate: "2019-11-29T02:03:02.8191738+00:00",
      locked: false,
      method: "online",
      notes: '',
      onlineEditingDisabled: false,
      orgTime: "2019/11/30 09:30",
      paymentPending: {
        amountPaid: 0,
        price: 10,
        paymentType: servicePaymentType.fullPayment,
        amountDue: 10,
        discountAmount: 0,
        peopleRequired: 0
      },
      paymentSummary: null, // todo - check
      people: 1,
      preferredSectionId: "section_GIYOEZ9CC963K",
      serviceId: SERVICE_ID_1,
      serviceName: SERVICE_NAME_1,
      status: {
        code: bookingStatusCode.unconfirmed,
        editable: true,
        enabled: true,
        hide: false,
        name: bookingStatusName.unconfirmed,
        order: 0,
        statusType: bookingStatusType.unconfirmed,
        type: "status"
      },
      tables: [],
      tags: [],
      time: "2019/11/30 09:30",
      type: "booking",
      venueID: venueID,
      _id: BOOKING_ID_1,
      selectedOptions: [],
      bookingType: BookingType.Booking,
      standByConfirmationExpiry: null,
      onlineCancelDisabled : false
    }
  }

  static getCustomerDetails(): ICustomer {
    return {
      _id: 'customer_test-1',
      company: 'Now Book It',
      email: 'jim@nowbookit.com',
      firstName: 'John',
      lastName: 'Doe',
      notes: 'Test notes, test notes, test notes, test notes, test notes',
      phone: '+61414562222',
      phoneNational: null,
      subscribed: true // means terms are checked
    }
  }

  static getVenue(id: number, name: string, phone = "+61 414 000 111"): IVenue {
    return {
      id,
      name,
      phone,
      "accountId": "109d9819-d0ee-479c-87b8-855f8bbfb266",
      "active": true,
      "city": null,
      "country": "AU",
      "state": "NSW",
      "currency": "AUD",
      "email": "",
      "timeZoneId": "Australia/Sydney",
      "widgetSettings": this.getWidgetSettings(),
      "logoUrl": null,
      "paymentSettings": {
        "acceptVisa": true,
        "acceptMasterCard": true,
        "acceptAmericanExpress": true,
        "acceptDiners": false,
        "acceptJcb": false,
        "clientSideEncryptionKey": "aaa",
        "paymentProvider": paymentProviderType.eway
      },
      "canCancelBookingBySms": false,
      "canCustomersCancelBookings": true,
      "canCustomersEditBookings": true,
      "canEditBookingBySms": false,
      "clientSideEncryptKey": "aaa",
      "preAuthReleasingWindow": 24,
      "maxPeoplePerBooking": 10,
      "analytics": {
        "facebookApi": null,
        "googleApi": null,
        "googleGTMFields": []
      },
      url: ''
    }
  }

  static getOwnedVenue(id: number, paymentProvider: paymentProviderTypeUppercase): IOwnedVenue {

    return {
      id,
      "name": "Jim's Awesome Pizzas",
      "country": "AU",
      "address": "123 Abc Street",
      "suburb": "Genericville",
      "postcode": "2000",
      "state": "NSW",
      "phone": "+61 414 562 222",
      "url": null,
      "active": true,
      "logoUrl": null,
      "alternatePhone": null,
      "contactEmail": "jim@nowbookit.com",
      "widgetSettings": this.getWidgetSettings(),
      "timeZoneId": "Australia/Sydney",
      "currency": "AUD",
      "paymentSettings": {
        "paymentProvider": paymentProvider,
        "eway": {
          "acceptVisa": true,
          "acceptMasterCard": true,
          "acceptAmericanExpress": true,
          "acceptDiners": false,
          "acceptJcb": false,
          "clientSideEncryptionKey": null,
          "paymentProvider": null // will get set to paymentProviderType.eway in AccountService -> getPaymentSettings
        },
        "stripe": {
          "publishableKey": "pk_test_8hMNVRTOR3TdRU7FAoB2BGgs00YUujT44j",
          "paymentProvider": null, // will get set to paymentProviderType.stripe in AccountService -> getPaymentSettings
          "stripe3DEnabled": false
        },
        "preAuthReleasingWindow": 24
      },
      "canCustomersCancelBookings": true,
      "canCustomersEditBookings": true,
      "canCancelBookingBySms": false,
      "canEditBookingBySms": false,
      "analytics": {
        "facebook": {
          "apiKey": null,
        },
        "google": {
          "apiKey": null
        }
      }
    }
  }

  static getSchedule(serviceId = SERVICE_ID_1): ISchedule {
    return {
      "isVenueOpen": true,
      "blockoutMessage": null,
      "tags": [ this.getTag() ],
      "services": [
        {
          "id": serviceId,
          "name": SERVICE_NAME_1,
          "serviceType": 'DINNER',
          "online": true,
          "duration": 30,
          "sections": this.getScheduleSections(),
          "times": [ this.getScheduleTime() ],
          "paymentDetails": this.getPaymentDetails(),
          "description": null,
          "policyAgreement": null,
          "policyAgreementText": null
        }
      ],
      currentBookingsOnStandbyList: 0,
      maxBookingsOnStandbyList: 0
    }
  }

  static getAllSchedule(serviceId = SERVICE_ID_1, isVenueOpen = true): ISchedule[] {
    return [{
      "isVenueOpen": isVenueOpen,
      "blockoutMessage": null,
      "tags": [ this.getTag() ],
      "services": [
        {
          "id": serviceId,
          "name": SERVICE_NAME_1,
          "serviceType": 'DINNER',
          "online": true,
          "duration": 30,
          "sections": this.getScheduleSections(),
          "times": [ this.getScheduleTime(), this.getScheduleTime(18, 15), this.getScheduleTime(18, 45), this.getScheduleTime(19, 0), this.getScheduleTime(19,15) ],
          "paymentDetails": this.getPaymentDetails(),
          "description": null,
          "policyAgreement": null,
          "policyAgreementText": null
        },
        {
          "id": serviceId,
          "name": "Lunch",
          "serviceType": 'LUNCH',
          "online": true,
          "duration": 30,
          "sections": this.getScheduleSections(),
          "times": [ this.getScheduleTime(), this.getScheduleTime(18, 0), this.getScheduleTime(19, 0), this.getScheduleTime(19,30) ],
          "paymentDetails": this.getPaymentDetails(),
          "description": null,
          "policyAgreement": null,
          "policyAgreementText": null
        }
      ],
      currentBookingsOnStandbyList: 0,
      maxBookingsOnStandbyList: 0,
      venueId: 2
    }]
  }

  static getTheme(): IWidgetTheme {
    return {
      "palette": {
        "background": {
          "default": "#f5f5f5"
        },
        "primary": {
          "50": "#eceff1",
          "100": "#cfd8dc",
          "200": "#b0bec5",
          "300": "#90a4ae",
          "400": "#78909c",
          "500": "#607d8b",
          "600": "#546e7a",
          "700": "#455a64",
          "800": "#37474f",
          "900": "#263238",
          "A100": "#cfd8dc",
          "A200": "#b0bec5",
          "A400": "#78909c",
          "A700": "#455a64"
        },
        "secondary": {
          "50": "#fafafa",
          "100": "#f5f5f5",
          "200": "#eeeeee",
          "300": "#e0e0e0",
          "400": "#bdbdbd",
          "500": "#9e9e9e",
          "600": "#757575",
          "700": "#616161",
          "800": "#424242",
          "900": "#212121",
          "A100": "#d5d5d5",
          "A200": "#aaaaaa",
          "A400": "#303030",
          "A700": "#616161"
        },
        "warning": {
          "main": "#f0ad4e",
          "light": "#fcf8e3",
          "dark": "#8a6d3b"
        },
        "success": {
          "main": "#c3e6cb",
          "light": "#c3e6cb",
          "dark": "#155724"
        },
        "error": {
          "main": "#f44336",
          "light": "#ef9a9a",
          "dark": "#b71c1c"
        },
        "type": "light"
      },
      "type": themeTypes.light,
      "defaultColors": true,
      "typography": {
        "htmlFontSize": 10,
        "h1": {
          "fontSize": "3rem"
        },
        "h2": {
          "fontSize": "2.2rem"
        },
        "h3": {
          "fontSize": "1.8rem"
        },
        "h4": {
          "fontSize": "1.6rem"
        },
        "h5": {
          "fontSize": "1.4rem"
        },
        "h6": {
          "fontSize": "1.3rem"
        },
        "subtitle1": {
          "fontSize": "1.6rem"
        },
        "subtitle2": {
          "fontSize": "1.4rem"
        },
        "body1": {
          "fontSize": "1.4rem"
        },
        "body2": {
          "fontSize": "1.3rem"
        },
        "button": {
          "fontSize": "1.6rem"
        },
        "caption": {
          "fontSize": "1.4rem"
        },
        "overline": {
          "fontSize": "1.4rem"
        },
        "fontFamily": "\"Roboto\",\"Helvetica\",\"Arial\",sans-serif"
      },
      // "overrides": {
      //   "MuiPickersDay": {
      //     "daySelected": {
      //       "backgroundColor": "#37474f"
      //     }
      //   }
      // }
    };
  }

  static getTag(): IBookingTag {
    return {
      "name": "Birthday",
      "icon": "birthday",
      "online": true,
      "tagtype": {
        "name": "Occasion",
        "_id": "tagtype_occasion",
        "type": "tagtype"
      },
      "order": 1,
      "colour": "#000000",
      "permanent": false,
      "_id": "tag_birthday",
      "type": "tag"
    }
  }

  static getPaymentDetails(optionsUpsell: IServicePaymentOption[] = []): IServicePaymentDetails {
    return {
      "paymentType": servicePaymentType.noPayment,
      "peopleRequired": 4,
      "price": 10,
      "options": [ this.getServicePaymentOptionFullPayment() ],
      "optionsFrom": null,
      "optionsTo": null,
      "maxPeoplePerBookingOverride": 12,
      "hasPromotion": false,
      "singleMenuPerBooking": true,
      "optionsUpsell": optionsUpsell
    }
  }

  static getFullPaymentPaymentDetails(optionsUpsell: IServicePaymentOption[] = []): IServicePaymentDetails {
    return {
      "paymentType": servicePaymentType.fullPayment,
      "peopleRequired": 4,
      "price": 10,
      "options": [ this.getServicePaymentOptionFullPayment() ],
      "optionsFrom": null,
      "optionsTo": null,
      "maxPeoplePerBookingOverride": 12,
      "hasPromotion": false,
      "singleMenuPerBooking": true,
      "optionsUpsell": optionsUpsell
    }
  }

  private static getServicePaymentOptionNoPayment(): IServicePaymentOption {
    return {
      "id": "menuoption_TEST1",
      "provider": "Nbi",
      "name": "Free birthday beer",
      "label": "free-bday-beer",
      "description": "Get a free glass of beer for every booking on your birthday!",
      "link": "http://nowbookit.com",
      "tag": "tag_birthday",
      "paymentType": servicePaymentType.noPayment,
      "price": 0,
      childMenuOptionIds: [],
      explicitChildMenuOptionIds: []
    }
  }

  private static getServicePaymentOptionFullPayment(): IServicePaymentOption {
    return {
      "id": "menuoption_TEST2",
      "provider": "Nbi",
      "name": "Glass of wine",
      "label": "wine",
      "description": "Drink some wine with your meal",
      "link": "http://nowbookit.com",
      "tag": "tag_birthday",
      "paymentType": servicePaymentType.fullPayment,
      "price": 10,
      childMenuOptionIds: [],
      explicitChildMenuOptionIds: []
    }
  }

  static getScheduleSections(): ISectionOrder[] {
    return [
      {
        "id": "section_GIYOEZ9CC963K",
        "name": "Main Room",
        "order": 0
      },
      {
        "id": "section_AEMOGNHCZ4IV0",
        "name": "Cellar",
        "order": 1
      },
      {
        "id": "section_WA2EW2CZKJB5Y",
        "name": "Front Room",
        "order": 2
      },
      {
        "id": "section_5774RYT53CRAN",
        "name": "Back Room",
        "order": 3
      },
      {
        "id": "section_NJNFREFWU89BJ",
        "name": "Rooftop",
        "order": 4
      }
    ]
  }

  private static make2Digit(num: number): string {
    if (num < 10) {
      return `0${num}`;
    }
    return num.toString();
  }

  static getScheduleTime(hour24 = 18, mins = 30, day = 15, month = 6, year = 2030): IScheduleTime {
    return {
      "name": `${this.make2Digit(hour24 > 12 ? hour24 - 12 : hour24)}:${this.make2Digit(mins)}${hour24 >= 12 ? 'pm' : 'am'}`,
      "expired": false,
      "time": `${this.make2Digit(year)}-${this.make2Digit(month)}-${this.make2Digit(day)}T${this.make2Digit(hour24)}:${this.make2Digit(mins)}:00`,
      "sections": [
        {
          "id": "section_GIYOEZ9CC963K",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_AEMOGNHCZ4IV0",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_WA2EW2CZKJB5Y",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_5774RYT53CRAN",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_NJNFREFWU89BJ",
          "sectionState": true,
          isSectionBlocked: false
        }
      ]
    }
  }

  static getSelectableTime(hour24 = 18, mins = 30, day = 15, month = 6, year = 2030, isDisabled = false): ISelectableTime {
    return {
      "name": `${this.make2Digit(hour24 > 12 ? hour24 - 12 : hour24)}:${this.make2Digit(mins)}${hour24 >= 12 ? 'pm' : 'am'}`,
      "expired": false,
      "isDisabled": isDisabled,
      "time": `${this.make2Digit(year)}-${this.make2Digit(month)}-${this.make2Digit(day)}T${this.make2Digit(hour24)}:${this.make2Digit(mins)}:00`,
      "sections": [
        {
          "id": "section_GIYOEZ9CC963K",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_AEMOGNHCZ4IV0",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_WA2EW2CZKJB5Y",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_5774RYT53CRAN",
          "sectionState": true,
          isSectionBlocked: false
        },
        {
          "id": "section_NJNFREFWU89BJ",
          "sectionState": true,
          isSectionBlocked: false
        }
      ]
    }
  }

  static getUnavailableScheduleTime(hour24 = 18, mins = 30, day = 15, month = 6, year = 2030): IScheduleTime {
    return {
      "name": `${this.make2Digit(hour24 > 12 ? hour24 - 12 : hour24)}:${this.make2Digit(mins)}${hour24 >= 12 ? 'pm' : 'am'}`,
      "expired": false,
      "time": `${this.make2Digit(year)}-${this.make2Digit(month)}-${this.make2Digit(day)}T${this.make2Digit(hour24)}:${this.make2Digit(mins)}:00`,
      "sections": [
        {
          "id": "section_GIYOEZ9CC963K",
          "sectionState": false,
          isSectionBlocked: false
        },
        {
          "id": "section_AEMOGNHCZ4IV0",
          "sectionState": false,
          isSectionBlocked: false
        },
        {
          "id": "section_WA2EW2CZKJB5Y",
          "sectionState": false,
          isSectionBlocked: false
        },
        {
          "id": "section_5774RYT53CRAN",
          "sectionState": false,
          isSectionBlocked: false
        },
        {
          "id": "section_NJNFREFWU89BJ",
          "sectionState": false,
          isSectionBlocked: false
        }
      ]
    }
  }

  static getWidgetSettings(): IWidgetSettings {
    return {
      maximumPeopleMessage: "Please contact us for bookings larger than {{maxPeoplePerBooking}} on {{phone}}",
      dayClosedMessage: "Unfortunately we are closed on this day, but would love to see you another day.",
      noTablesAvailableMessage: "Tables are very limited at this time. Please call us on {{phone}}  for more availability.",
      timeNoLongerAvailableMessage: "Tables are very limited at this time. Please call us on {{phone}} for more availability.",
      showTimeNotAvailable: false,
      timeNotAvailableMessage: "Call Us",
      maxPeoplePerBooking: 10,
      canCustomersChooseSection: true,
      termsAndConditionsMessage: "1. As a courtesy you agree to contact the restaurant if you have any changes, updates or need to cancel your booking.\n2. If you use our Sites and Services, we may communicate with you via electronic messages, including email, text message/SMS, or mobile push notifications in accordance with our privacy policy.\n3. Your booking will be allocated to the best available table in the venue you have chosen.\n4. Any special requests made will be catered for by the venue as best as possible, please contact the venue directly if you require confirmation of your request.\n5. Your information will be used in accordance with the Now Book It Privacy Policy [https://www.nowbookit.com/privacypolicy](https://www.nowbookit.com/privacypolicy).",
      publicTermsAndConditionsMessage: "1. As a courtesy you agree to contact the restaurant if you have any changes, updates or need to cancel your booking.\n2. If you use our Sites and Services, we may communicate with you via electronic messages, including email, text message/SMS, or mobile push notifications in accordance with our privacy policy.\n3. Your booking will be allocated to the best available table in the venue you have chosen.\n4. Any special requests made will be catered for by the venue as best as possible, please contact the venue directly if you require confirmation of your request.\n5. Your information will be used in accordance with the Now Book It Privacy Policy [https://www.nowbookit.com/privacypolicy](https://www.nowbookit.com/privacypolicy).",
      preAuthorisationMessage: "To take this booking we require a credit card pre-authorisation. Nothing will be debited from your card when making the booking, however, if you cancel within 24 hours of your booking or don't show up, a cancellation fee per person will be taken as specified.",
      bookingCancellationWindow: "24",
      theme: "outlined-light",
      accentColour: "hex,bf360c,6d4c41",
      font: "Josefin Sans",
      button: "blue_key_sans_rect_now",
      allowOnlineBookings: true,
      onlineBookingsOffMessage: "Sorry, online bookings are unavailable at the moment, please call us on {{phone}}.",
      minMinutesBeforeServiceBooking: null,
      maxDaysInFutureBooking: 180,
      tooFarInAdvanceMessage: "Sorry, we don't take online bookings this far in advance. Please call us on {{phone}}.",
      disableEditingTimeWindow: null,
      finalWidgetScreenMessage: "Thanks for your booking, we can't wait to see you. We've sent you an email with the details of this booking.",
      minMinutesBeforeBooking: null,
      disableCancelTimeWindowInHours: null,
      enableNoTableMessage: true,
      enableRobotValidation: false,
      enableAdvancedEditingSupport: false,
      serviceClosedMessage: "Please contact us on {{phone}} to make a booking.",
      enquiryMessage: "Please contact us on {{phone}} to make a booking.",
      cancelledBookingMessage: "Please contact us on {{phone}} to make a booking.",
      additionalFields: {
        enableDateOfBirthField: false,
        isDateOfBirthFieldRequired: false,
        enablePostCodeField: false,
        isPostCodeFieldRequired: false,
        enableMembershipNumberField: false,
        isMembershipNumberFieldRequired: false,
        enableCompanyField: true,
        isCompanyFieldRequired: false
    }
    }
  }

  private static mockPayNow(errorType: number, isEway = true) {
    if (errorType) {
      paymentServiceMockAdapter.onPost(PaymentService.PAY_NOW_URL)
        .reply(errorType);
      return;
    }

    paymentServiceMockAdapter.onPost(PaymentService.PAY_NOW_URL)
      .reply(200, isEway ? {
        accessCode: FAKE_ACCESS_CODE,
        formActionUrl: FAKE_PAYMENT_URL
      } as IPrepareEwayData
      : {
        transactionId: '123',
        amountPaid: 10
      });
  }

  private static mockEwayProcessSuccess(processError: 'timeout' | 'serverError' = null) {
    // stub for eway payment
    (window as any).eWAY = {
      process: (formEl: HTMLFormElement, callbacks: {
        onComplete: (data: IProcessEwayPaymentResponse) => void,
        onError: (error: any) => void,
        onTimeout: (error: any) => void
      }) => {
        setTimeout(() => {
          if (processError === 'timeout') {
            callbacks.onTimeout(bookingErrorType.paymentTimeout);
          } else if (processError === 'serverError') {
            callbacks.onError(bookingErrorType.paymentServerError);
          } else {
            callbacks.onComplete({
              AccessCode: FAKE_ACCESS_CODE,
              RedirectUrl: 'http://whatever',
              IsComplete: true,
              Errors: null
            });
          }
        }, 1000); // fake delayes response
      }
    }
  }

  private static mockPaymentSummary(errorType: number) {
    if (errorType) {
      paymentServiceMockAdapter.onPost(PaymentService.PAY_NOW_URL)
        .reply(errorType);
      return;
    }

    paymentServiceMockAdapter.onGet(PaymentService.PAYMENT_SUMMARY_URL)
      .reply(200, {
        "success": true,
        "errorMessage": "",
        "formActionUrl": null,
        "accessCode": null,
        "transactionId": "21867063",
        "amountPaid": 10,
        "responseCodes": "A2000",
        "purchaseOrderId": 0
      } as IPaymentSummaryResponseData);
  }

  private static getPaymentMethod(): stripe.paymentMethod.PaymentMethod {
    const card: stripe.paymentMethod.PaymentMethodCard = {
      "brand":"visa",
      "checks": {
        "address_line1_check":null,
        "address_postal_code_check":null,
        "cvc_check":null
      },
      "country":"AU",
      "exp_month":10,
      "exp_year":2030,
      "funding":"credit",
      "generated_from":null,
      "last4":"4242",
      "three_d_secure_usage":{
        "supported":true
      },
      "wallet":null,
      fingerprint: undefined
    };

    return {
      "id":"pm_1G0EqACJ4FeOOjFXWBZeI312",
      "object":"payment_method",
      "billing_details": {
        "address":{
          "city":null,
          "country":null,
          "line1":null,
          "line2":null,
          "postal_code":null,
          "state":null
        },
        "email":null,
        "name":"Jim Doyle",
        "phone":null
      },
      card,
      "created":1578867807,
      "customer":null,
      "livemode":false,
      "metadata":{},
      "type":"card"
    }
  }

  static getService(id = '1', sections: ISectionOrder[] = [
      { id: 'section_Main_Room', name: "Main Room", order: 0}
    ], maxPeoplePerBookingOverride: number = null): IScheduleService {
    return {
      id,
      description: 'Dinner with testers',
      duration: 90,
      name: 'Dinner',
      online: true,
      serviceType: 'DELIVERY',
      isBlockoutPartiallyEnabled: false,
      blockoutMessage: 'Test message',
      paymentDetails: {
        hasPromotion: false,
        maxPeoplePerBookingOverride: maxPeoplePerBookingOverride,
        options: [],
        optionsFrom: null,
        optionsTo: null,
        paymentType: servicePaymentType.noPayment,
        peopleRequired: 1,
        price: 0,
        singleMenuPerBooking: false
      },
      policyAgreement: null,
      policyAgreementText: null,
      sections,
      times: [{
        expired: false,
        name: "6:00am",
        sections: [{
          id: "section_GIYOEZ9CC963K",
          sectionState: true,
          isSectionBlocked: false
        }],
        time: "2050-01-14T06:00:00"
      }]
    }
  }

  static getBookingOption(name?: 'redWine' | 'steak' | 'gravy'): IServicePaymentOption {

    const bottleOfRedWine: IServicePaymentOption = {
      description: "Not the best bottle of wine in the world, but drinkable",
      id: this.ID_BOTTLE_OF_RED_WINE,
      label: "Bottle of Red Wine",
      link: null,
      name: "Bottle of Red Wine",
      paymentType: servicePaymentType.fullPayment,
      price: 50,
      provider: "Nbi",
      tag: null,
      childMenuOptionIds: [],
      explicitChildMenuOptionIds: []
    };

    const gravy: IServicePaymentOption = {
      childMenuOptionIds: [],
      description: "Best gravy in town",
      explicitChildMenuOptionIds: [],
      id: this.ID_GRAVY,
      // isExplicit: true
      label: "Gravy",
      link: null,
      name: "Gravy",
      paymentType: servicePaymentType.fullPayment,
      price: 5,
      provider: "Nbi",
      // quantity: 1
      tag: null
    };

    const steak: IServicePaymentOption = {
      childMenuOptionIds: [
        this.ID_BOTTLE_OF_RED_WINE
      ],
      description: "Aberdeen Angus T-bone steak",
      explicitChildMenuOptionIds: [
        this.ID_GRAVY
      ],
      id: this.ID_STEAK,
      label: "Steak",
      link: null,
      name: "Steak",
      paymentType: servicePaymentType.fullPayment,
      price: 30,
      provider: "Nbi",
      tag: null
    };

    if (name === 'steak') {
      return steak;
    }

    if (name === 'gravy') {
      return gravy;
    }

    return bottleOfRedWine;
  }


  static getSavedExtra(name?: 'redWine' | 'steak' | 'gravy', isExplicit = false): ISavedExtras {

    const bottleOfRedWine: ISavedExtras = {
      id: this.ID_BOTTLE_OF_RED_WINE,
      isExplicit,
      label: "Bottle of Red Wine",
      name: "Bottle of Red Wine",
      paymentType: servicePaymentType.fullPayment,
      price: 50,
      quantity: 1
    };

    const gravy: ISavedExtras = {
      id: this.ID_GRAVY,
      isExplicit,
      label: "Gravy",
      name: "Gravy",
      paymentType: servicePaymentType.fullPayment,
      price: 5,
      quantity: 1
    };

    const steak: ISavedExtras = {
      id: this.ID_STEAK,
      isExplicit,
      label: "Steak",
      name: "Steak",
      paymentType: servicePaymentType.fullPayment,
      price: 30,
      quantity: 1
    };

    if (name === 'steak') {
      return steak;
    }

    if (name === 'gravy') {
      return gravy;
    }

    return bottleOfRedWine;
  }

  static getAvailableTimesforSections(section1: string, section2: string, availableSec1: boolean, availableSec2: boolean) {
    const year: number = (new Date()).getFullYear()
    return [
      {
        bookingOptionsCount: 0, expired: false, name: "6:40am", sections: [
          { id: section1, sectionState: availableSec1, isSectionBlocked: false },
          { id: section2, sectionState: availableSec2, isSectionBlocked: false }
        ], time: `${year}-12-31T06:40:00`
      },
      {
        bookingOptionsCount: 0, expired: false, name: "9:10am", sections: [
          { id: section1, sectionState: availableSec1, isSectionBlocked: false },
          { id: section2, sectionState: availableSec2, isSectionBlocked: false }
        ], time: `${year}-12-31T09:10:00`
      }
    ]
  }

  static getMockSelectedOptions(id: string, isUpsellItem = false, quantity = 0){
    return {
      isUpsellItem,
      menuOptionId: id,
      quantity
    }
  }
}
