import React from 'react';
import { IAlertPanel } from './types';
import style from './style.module.scss';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles, Button } from '@material-ui/core';
import { renderIf } from 'shared-services/react-utils-service';

const NS = 'AlertPanel';

const Alert = withStyles({
  icon: {
    alignItems: 'center'
  }
})(MuiAlert);

export default function AlertPanel({message, buttonText, buttonHandler, severity}: IAlertPanel) {
  return (
    <Alert severity={severity || 'error'} variant="filled" className={style.root} >
      <span className={style.link} dangerouslySetInnerHTML={{__html: message}} />
      {renderIf(buttonText && buttonHandler, () => (
        <Button
          className={style.btn}
          variant="contained"
          color="default"
          size="small"
          onClick={buttonHandler}>
          {buttonText}
        </Button>
      ))}
    </Alert>
  )
}
