import moment, { Moment } from "moment";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Hook to trigger an update of the {@link DatePicker} child component in the
 * {@link DayPicker} component.
 *
 * When changing locales, the day and month labels are updated correctly, e.g.
 * "Mon" -> "lun." and "January" -> "janvier". However, the _day of week_
 * property (0 = Sunday, 1 = Monday) is **not** automatically updated.
 *
 * This is probably because the `libInstance` _moment_ passed to the
 * {@link MuiPikersUtilsProvider} causes the provider to re-render prematurely.
 *
 * This hack can be included in the {@link DayPicker} component as simply
 *
 * ```ts
 * usePickerUpdate()
 * ```
 *
 * and it will trigger the children to be re-rendered one more time after the
 * `i18n` and `moment` locales have been updated.
 */
const usePickerUpdate = () => {
  const { i18n } = useTranslation();

  const [useMoment, setUseMoment] = useState<Moment>(null);
  useEffect(() => {
    if (!useMoment) {
      // First call: Set directly to prevent "Update to unmounted component"
      // error.
      setUseMoment(moment);
    } else {
      // Subsequent updates: Set the state variable to trigger the update on
      // the next render cycle.
      setTimeout(() => setUseMoment(moment), 0);
    }
  }, [i18n.language]);
};

export default usePickerUpdate;
