import {blockNavType, IStandbyData, IVenue, IWidgetModel} from "app/models";
import {BookingService} from "app/services/booking/booking.service";
import {ISchedule} from "app/services/client/client.types";
import {ISelectableTime} from "app/components/TimePicker/types";
import {TimeFilterService} from "app/services/timeFilter/timeFilter.service";
import {IActionGen, loadStatus} from "app/types/common.types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IScheduleService} from "shared-types/index";
import {SectionsAvailableService} from "app/services/sectionsAvailable/sectionsAvailable.service";

const NS = 'serviceScheduleSuccess';

export default {
  [BookingActionsTypes.SERVICE_SCHEDULE_SUCCESS]: (state: IWidgetModel, action: IActionGen<ISchedule>): IWidgetModel => {
    const schedule = action.payload;

    // REF: NBI-2408 - handling null tags
    if (!schedule.tags) {
      schedule.tags = [];
    }

    const services = BookingService.getAvailableServicesFromSchedule(schedule);
    const activeVenue: IVenue = state.activeVenue as IVenue;

    // match service by name
    const matchedPreviousActiveService: IScheduleService = schedule.isVenueOpen &&
      state.activeService &&
      services.find(s => s.name === state.activeService.name);

    const activeService: IScheduleService = matchedPreviousActiveService || null;

    const sectionData = SectionsAvailableService.getSectionsOnCoversOrServiceChange(
      activeService,
      activeVenue,
      state.booking,
      state.activeSection,
      state.manuallyChosenSectionId
    );

    const { filteredSections, activeSection } = sectionData;
    let {booking} = sectionData;
    
    /**
     * If a service (say 'breakfast') has been selected already, then the date is changed, we use
     * 'matchedPreviousActiveService' to keep 'breakfast' selected, but then we force the time view
     * again, because the times may be very different and they will have to choose the time again.
     */
    const isTimeViewShown: boolean = !!activeService || state.isTimeViewShown;

    const filteredTimes: ISelectableTime[] = activeService
      ? TimeFilterService.getFilteredTimesAndMutateOriginal(activeService.times, true, activeSection, booking.utcTime)
      : null;

      // if times are now available, then we don't need standby list, as they can just book normally
    const clearStandbyData: boolean = state.standbyData && filteredTimes && !filteredTimes.some(t => t.isDisabled);

    let standbyData: IStandbyData = null;
    if (clearStandbyData) {
      // so we repopulate the booking time and select it
      booking = BookingService.getBookingFromStandbyData(state.standbyData, booking);
    } else if (activeService && activeSection) {
      // if active service and section are available, we update the standbyData
      standbyData = state.standbyData ? {
        ...state.standbyData,
        serviceId: activeService.id,
        serviceName: activeService.name,
        sectionId: activeSection.id // there will always be an 'activeSection' so no need for a null check
      } : null;
    }

    const availableTimes = filteredTimes?.filter(t => !t.isDisabled && !t.isBlocked);
    const blockNav = !standbyData && !availableTimes?.length ? blockNavType.becauseNoAvailableTimes : null;

    const maxPaxOverride = BookingService.getMaxPeoplePerBooking(activeVenue, activeService, schedule);
    const selectedMenuOptions = BookingService.correctSelectedMenuOptions({
      activeService, booking
    })

    return {
      ...state,
      schedule,
      scheduleLoadStatus: loadStatus.success,
      activeService,
      isTimeViewShown,
      booking: {
        ...booking,
        selectedMenuOptions,
      },
      filteredSections,
      activeSection: activeSection || null,
      filteredTimes,
      standbyData,
      standbyCleared: clearStandbyData,
      standbyExhausted: !schedule.maxBookingsOnStandbyList || schedule.currentBookingsOnStandbyList >= schedule.maxBookingsOnStandbyList,
      blockNav,
      maxPaxOverride
    }
  }
}
