import { connect } from 'react-redux';
import ColumnWrap2 from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps, IOwnProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";

const NS = 'ColumnWrap2Container';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IStateFromProps => {

  return {
    isLandscape: !IframeResizerService.isStacked(widget.wrapperStyle),
    children: ownProps.children,
    grow: ownProps.grow
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    // handleSomeClick: () => {
    //   // dispatch(WidgetActionsNS.xxx());
    // }
  }
};

const ColumnWrap2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumnWrap2 as any);

export default ColumnWrap2Container;
