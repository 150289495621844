import React, { useState, useEffect } from 'react';
import { IMenuOptionsSpinner } from './types';
import style from './style.module.scss';
import { IServicePaymentOption, IBookingMenuOption, IBookingMenuOptionExtras, IWidgetTheme } from 'shared-types/index';
import { Typography, Chip } from '@material-ui/core';
import CoverInput from 'shared-components/cover-input/index';
import { coverInputVariant } from 'shared-components/cover-input/types';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MenuOptionContainer from 'internal-components/MenuOption/container';
import BookingOptionsHelpers from "shared-components/booking-options-section/helpers";
import { Trans, useTranslation } from 'react-i18next';


const NS = 'MenuOptionsSpinner';


function handleExtrasOnChange(quantity:number, extras: IBookingMenuOptionExtras) {

  if (extras) {
    const opts = extras.explicitChildMenuOptions;
    if (extras.isSameForAll) {
      if (opts && opts.length) {
        opts[0].forEach(o => o.quantity = quantity);
      }
    } else {
      if (quantity < opts.length) {
        // if quantity decreased, then we remove overspill on explicitChildMenuOptions
        opts.length = quantity;

      } else if (quantity > opts.length) {
        // if quantity increases, then needs empty slots added
        for (let i = opts.length; i < quantity; i++) {
          opts.push([]);
        }
      }
    }

  }
}

export default function MenuOptionsSpinner({
  theme, menuOptions, type, maxCovers,
  wrapperStyle, gridXs, gridMd, gridGutter, selectedMenuOptions,
  handleSpinnerUpdate, handleOpenExtras, handleMaxCovers, isUpsell
}: IMenuOptionsSpinner) {

  const [combinedCovers, setCombinedCovers] = useState<IBookingMenuOption[]>([]);
  const { t } = useTranslation("sitting");

  useEffect(() => {
    setCombinedCovers(menuOptions.map(({id}) => {
      const selectedOpt = !selectedMenuOptions ? null : selectedMenuOptions.find(o => o.menuOptionId === id);
      return {
        menuOptionId: id,
        quantity: selectedOpt ? selectedOpt.quantity : 0,
        extras: selectedOpt ? (selectedOpt.extras || null) : null
      };
    }));
  }, [menuOptions]);

  const getRemaining = (id?: string): number => {
    let remaining = maxCovers - combinedCovers.reduce((a, o) => a + (o.menuOptionId !== id ? o.quantity : 0), 0);

    // prevents minus numbers
    if (remaining > maxCovers) {
      remaining = maxCovers;
    }
    return remaining;
  };


  const remaining = getRemaining();
  const isSuccess = remaining === 0;
  let inputTO: any;

  handleMaxCovers && handleMaxCovers(isSuccess);

  const scrollGridItemIntoView = (itemEl: HTMLDivElement) => {
    if (itemEl.scrollIntoView) {
      if (inputTO) {
        clearTimeout(inputTO);
      }
      inputTO = setTimeout(() => {
        itemEl.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  const useGrid = !!gridGutter;


  return (
    <>
      {(() => ( // Grid Outer wrapper
        BookingOptionsHelpers.muiGridOrCustomElementOuter(useGrid, "", style.box, gridGutter, (
          <>
            {menuOptions.map((opts: IServicePaymentOption, i: number) => {
              const {
                id, price, label, link, description, childMenuOptionIds,
                explicitChildMenuOptionIds, paymentType, paymentTypeOverride
              } = opts;
              const gridItemRef: React.RefObject<HTMLDivElement> = React.createRef();
              const gridSizes = {xs: gridXs, sm: false, md: gridMd, lg: false};
              const selectedOpt: IBookingMenuOption = combinedCovers.find(cc => cc.menuOptionId === id);
              const allChildMenuOptionIds: string[] = (childMenuOptionIds || []).concat(explicitChildMenuOptionIds || []);
              const hasChildMenuOptions: boolean = allChildMenuOptionIds.length > 0;
              const _handleOpenExtras = selectedOpt?.quantity && hasChildMenuOptions ? () => handleOpenExtras(i) : null;

              // Grid Inner Items
              return (
                selectedOpt && BookingOptionsHelpers.muiGridOrCustomElementInner(id, useGrid, gridSizes, "", style.boxItems, gridItemRef, (
                  <MenuOptionContainer
                    paymentType={paymentTypeOverride ? paymentTypeOverride.paymentType : paymentType}
                    paymentTypeError={paymentTypeOverride ? paymentTypeOverride.hasError : false}
                    id={id}
                    price={price}
                    label={label}
                    description={description}
                    link={link}
                    type={type}
                    allChildMenuOptionIds={allChildMenuOptionIds}
                    handleOpenExtras={_handleOpenExtras} >
                    <CoverInput
                      theme={theme as IWidgetTheme}
                      wrapperStyle={wrapperStyle}
                      hasDelay={false}
                      coversPrefill={selectedOpt.quantity}
                      maxPeoplePerBooking={getRemaining(id)}
                      minPeoplePerBooking={0}
                      variant={coverInputVariant.small}
                      omitMaxReachedMsg={true}
                      allowMaxBreach={false}
                      handleChange={quantity => {
                        const newValue = combinedCovers.map((item: IBookingMenuOption) => {
                          if (item.menuOptionId !== id) {
                            return item;
                          }

                          // scrolls a menu option into view so the whole thing can be seen
                          scrollGridItemIntoView(gridItemRef.current);

                          const { extras, menuOptionId } = item;
                          handleExtrasOnChange(quantity, extras);
                          return {menuOptionId, quantity, extras, isUpsellItem: !!isUpsell};
                        });
                        setCombinedCovers(newValue);
                        handleSpinnerUpdate(newValue);
                      }} />
                  </MenuOptionContainer>
                ))
              )}
            )}
          </>
        ))
      ))()}

      <div className={[style.validationMsg, style.counterMessage].join(' ')}>
        <>
          <Typography variant="body1" className={style.msg}>
            <Trans t={t}>
            Number of booking options must match number of people in booking.
            </Trans>
          </Typography>
          <Chip
            size="medium"
            icon={isSuccess ? <CheckCircleIcon fontSize="small" /> : <ErrorIcon fontSize="small" />}
            label={maxCovers - remaining + '/' + maxCovers}
            color='primary'
            style={{
              color: isSuccess ? theme.palette.success.dark : theme.palette.warning.dark,
              backgroundColor: isSuccess ? theme.palette.success.light : theme.palette.warning.light,
              border: `1px solid  ${isSuccess ? theme.palette.success.dark : theme.palette.warning.dark}`
            }}
          />
        </>
      </div>
    </>
  )
}
