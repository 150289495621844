// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root--sqT4u{display:flex;flex-direction:column;width:100%}.rootIsSmall--SRN0u{width:auto}.numberInput--KKKI4{width:93px}.numberInput--KKKI4 label{white-space:nowrap}.numberInput--KKKI4 input{text-align:center;font-size:3rem;padding:10px 10px 5px 10px}.numberInput--KKKI4 input[type=number]::-webkit-outer-spin-button,.numberInput--KKKI4 input[type=number]::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}.numberInput--KKKI4 input[type=number]{-moz-appearance:textfield}.numberInputIsSmall--XDlEZ{width:40px !important;margin-top:0 !important;margin-bottom:0 !important}.numberInputIsSmall--XDlEZ legend>span{display:none}.numberInputIsSmall--XDlEZ input{font-size:1.5rem;padding:8px 5px 5px 5px;height:auto}.numberInputIsLight--3BIqb input{background-color:rgba(255,255,255,.5);border-radius:5px}.incrementBtnIsSmall--ce1kj{width:27px !important;height:27px !important;min-height:27px !important}.incrementIconIsSmall--SL3F4{font-size:1.9rem}.incrementBtns--KcOGC{display:flex;align-items:center;padding:0 0 0 15px}.incrementBtnsIsLandscape--smiuo{flex-grow:1}.incrementBtnsIsSmall--SWGET{padding-left:11px}.minusBtn--M-RcO{margin-right:4px !important}.plusBtn--V5qoG{margin-left:4px !important}.inputWrap--yzMdh{display:flex;width:100%;justify-content:center}.inputWrapIsLandscape--9KCC6{justify-content:flex-start}.message--WE-ne{margin:3px 0 0 0}.message--WE-ne p{margin:0}.disabledTouchAction--A2i3O{touch-action:manipulation}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "root--sqT4u",
	"rootIsSmall": "rootIsSmall--SRN0u",
	"numberInput": "numberInput--KKKI4",
	"numberInputIsSmall": "numberInputIsSmall--XDlEZ",
	"numberInputIsLight": "numberInputIsLight--3BIqb",
	"incrementBtnIsSmall": "incrementBtnIsSmall--ce1kj",
	"incrementIconIsSmall": "incrementIconIsSmall--SL3F4",
	"incrementBtns": "incrementBtns--KcOGC",
	"incrementBtnsIsLandscape": "incrementBtnsIsLandscape--smiuo",
	"incrementBtnsIsSmall": "incrementBtnsIsSmall--SWGET",
	"minusBtn": "minusBtn--M-RcO",
	"plusBtn": "plusBtn--V5qoG",
	"inputWrap": "inputWrap--yzMdh",
	"inputWrapIsLandscape": "inputWrapIsLandscape--9KCC6",
	"message": "message--WE-ne",
	"disabledTouchAction": "disabledTouchAction--A2i3O"
};
export default ___CSS_LOADER_EXPORT___;
