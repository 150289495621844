import {IChangedCustomerDetails} from "app/actions/booking/interfaces";
import {IWidgetModel} from "app/models";
import {IBooking, ICustomer} from "app/services/booking/booking.types";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import DateUtilsService from "shared-services/date-utils-service";

const NS = 'changedCustomerDetails';

const isSameCustomerDetail = (customer: ICustomer, compareCustomer: ICustomer): boolean => {
  if(customer.email !== compareCustomer.email) return false;
  if(customer.phone !== compareCustomer.phone) return false;
  if(customer.firstName !== compareCustomer.firstName) return false;
  if(customer.lastName !== compareCustomer.lastName) return false;
  return true;
}

export default {
  [BookingActionsTypes.CHANGED_CUSTOMER_DETAILS]: (state: IWidgetModel, action: IChangedCustomerDetails): IWidgetModel => {

    let booking: IBooking = state.booking;
    let standbyData = state.standbyData;
    let isDetectedPreviousSB = state.isDetectedPreviousSB;
    if (standbyData) {
      standbyData = {
        ...standbyData,
        tags: action.payload.tags,
        customer: {
          ...state.standbyData.customer,
          ...action.payload.customerDetails
        },
        // country: action.payload.country,
      }
      if (state.standbyData.restrictCustomer) {
        const isSameCustomer = isSameCustomerDetail(standbyData.customer, state.standbyData.restrictCustomer);

        const {
          utcTime, isFlexibleTime
        } = state.standbyData;

        const restrictDate: string = isFlexibleTime ? state.activeService.times[0].time : utcTime
        const isSameDate = DateUtilsService.isOnSameDate(new Date(restrictDate), new Date(standbyData.restrictDate));

        isDetectedPreviousSB = isSameCustomer && isSameDate;
      } else {
        isDetectedPreviousSB = false;
      }
    } else {
      booking = {
        ...booking,
        tags: action.payload.tags,
        customer: {
          ...state.booking.customer,
          ...action.payload.customerDetails
        },
        // country: action.payload.country,
      };
      isDetectedPreviousSB = false;
    }

    return {
      ...state,
      booking,
      country: action.payload.country,
      standbyData,
      isCustomerDetailsValid: action.payload.isValid,
      phoneWithoutPrefix: action.payload.phoneWithoutPrefix,
      isDetectedPreviousSB: isDetectedPreviousSB,
      isCustomerInformationUpdated: true
    }
  }
}
