import React, {ReactElement, useState} from 'react';
import {cloneDeep} from 'lodash';
import Carousel, {Dots} from '@brainhubeu/react-carousel';
import {IChildMenuOptionExplicitItem, IChildMenuOptionExplicitList} from './types';
import style from './style.module.scss';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import UtilsService from 'shared-services/utils-service/index';
import {renderIf} from 'shared-services/react-utils-service/index';
import {
  ISimpleBookingOption, IBookingMenuOption, IWidgetBasicColor,
  themeTypes, wrapperStyleType
} from 'shared-types/index';
import MenuOption from '../MenuOption';
import {menuOptionType} from '../MenuOption/types';
import classNames from 'classnames';
import IframeResizerService from "shared-services/iframe-resizer-service/index";
import BookingOptionsHelpers from "../helpers";
import { Trans, useTranslation } from 'react-i18next';

const NS = 'ChildMenuOptionExplicitList';

function ListItems({
    selectedMenuOptions, menuOptions, theme, currency, gridSizes, gridGutter, wrapperStyle,
    onChanged
  }: IChildMenuOptionExplicitItem): ReactElement {

  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isNarrow = wrapperStyle === wrapperStyleType.narrow;

  const useGrid = !!gridGutter;

  return (
    BookingOptionsHelpers.muiGridOrCustomElementOuter(useGrid, style.grid, style.box, gridGutter, (
      <>
        {menuOptions.map(({id, label, description, price, link}: ISimpleBookingOption, i) => {
          const isChecked = selectedMenuOptions.some(so => so.menuOptionId === id && so.quantity > 0);
          return (
            BookingOptionsHelpers.muiGridOrCustomElementInner(id, useGrid, {
              xs: 6, sm: gridSizes[1], md: gridSizes[2], lg: gridSizes[3]
            }, classNames({
              // [style.gridItem]: true,
              [style.gridItemIsStacked]: isStacked
            }), style.boxItems, null, (
              <div className={classNames({
                [style.listItem]: true,
                [style.listItemIsStacked]: isStacked,
                [style.listItemIsNarrow]: isNarrow
              })}
                   data-testid={`menu-option${isChecked ? '-checked' : ''}`}
                   data-test-uid={id} >
                <MenuOption
                  isStacked={isStacked}
                  theme={theme}
                  currency={currency}
                  id={id}
                  label={label}
                  price={price}
                  description={description}
                  link={link}
                  type={menuOptionType.checkboxes}
                  isChecked={isChecked}
                  extrasHasPayment={false}
                  handleCheckboxChanged={(evt: any, isChecked: boolean) => {
                    onChanged(id, isChecked);
                  }} />
              </div>
            ))
          );
        })}
      </>
    ))
  );
}


function insertEmptyArrays(selectedMenuOptionsMDA: IBookingMenuOption[][], parentQuantity: number, isSameForAll: boolean) {
  let _selectedMenuOptionsMDA = selectedMenuOptionsMDA.slice();
  // inserts empty arrays where needed
  if (isSameForAll) {
    if (!_selectedMenuOptionsMDA[0]) {
      _selectedMenuOptionsMDA = [[]];
    }
  } else {
    for (let i = 0; i < parentQuantity; i++) {
      if (!_selectedMenuOptionsMDA[i]) {
        _selectedMenuOptionsMDA.splice(i, 0, []);
      }
    }
  }

  return _selectedMenuOptionsMDA;
}

/**
 * Updates `selectedMenuOptions` by toggle adding/removing its items
 */
function toggleSelectionMenuOptions(selectedMenuOptions: IBookingMenuOption[], changedId: string, parentQuantity: number, isSameForAll: boolean) {
  let foundIndex = -1;
  const selectedOpt = selectedMenuOptions.find((so, i) => {
    const found = so.menuOptionId === changedId;
    if (found) {
      foundIndex = i;
    }
    return found;
  });

  if (selectedOpt) {
    // if `selectedOpt` found, that means it is already checked, so we need to now uncheck/remove it from the `selectedMenuOptions` array
    selectedMenuOptions.splice(foundIndex, 1);
  } else {
    // if `selectedOpt` isn't found, that means it isn't checked and needs to be checked/added to the `selectedMenuOptions` array
    selectedMenuOptions.push({
      menuOptionId: changedId,
      quantity: isSameForAll ? parentQuantity : 1
    })
  }
}

function populateBasedOnFirstOptions(firstOptions: IBookingMenuOption[], isChecked: boolean, parentQuantity: number): IBookingMenuOption[][] {

  const _selectedMenuOptionsMDA: IBookingMenuOption[][] = isChecked ? (() => {
    // if checked, set first items' quantities to same as parentQuantity
    firstOptions.forEach(o => o.quantity = parentQuantity);
    return [firstOptions];
  })() : (() => {
    // if unchecked, set all items' quantities to 1
    const arr = [];
    firstOptions.forEach(o => o.quantity = 1);
    for (let i = 0; i < parentQuantity; i++) {
      arr.push(cloneDeep(firstOptions));
    }
    return arr;
  })();

  return _selectedMenuOptionsMDA;
}

export default function ChildMenuOptionExplicitList(props: IChildMenuOptionExplicitList) {
  // deconstructing here instead of above due to duplicate prop/state name 'isSameForAll' & 'selectedMenuOptionsMDA'
  const {
    wrapperStyle, theme, currency, menuOptions, parentQuantity, gridSizes, gridGutter,
    onChanged
  } = props;

  const { t } = useTranslation("extras");
  const [slideNumber, setSlideNumber] = useState<number>(0);
  const [isSameForAll, setIsSameForAll] = useState<boolean>(props.isSameForAll);

  const [selectedMenuOptionsMDA, setSelectedMenuOptionsMDA] = useState<IBookingMenuOption[][]>(
    insertEmptyArrays(props.selectedMenuOptionsMDA, parentQuantity, isSameForAll)
  );


  const noCarousel: boolean = isSameForAll || parentQuantity === 1;
  const primaryColor: string = (theme.palette.primary as IWidgetBasicColor).main;
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  const getSlides = (): ReactElement[] => {

    const slides: ReactElement[] = [];
    for (let i = 0; i < parentQuantity; i++) {
      const selectedMenuOptions = selectedMenuOptionsMDA[i];

      slides.push(
        <div key={i} className={style.listItemsWrap}>
          <ListItems
            wrapperStyle={wrapperStyle}
            selectedMenuOptions={selectedMenuOptions}
            menuOptions={menuOptions}
            theme={theme}
            currency={currency}
            gridSizes={gridSizes}
            gridGutter={gridGutter}
            onChanged={(changedId: string, isChecked: boolean) => {
              toggleSelectionMenuOptions(selectedMenuOptions, changedId, parentQuantity, isSameForAll);
              const _selectedMenuOptionsMDA = selectedMenuOptionsMDA.slice();
              _selectedMenuOptionsMDA.splice(i, 1, selectedMenuOptions);

              // updates internal component state
              setSelectedMenuOptionsMDA(_selectedMenuOptionsMDA);

              // updates parent with new data
              onChanged(_selectedMenuOptionsMDA, isSameForAll);
            }}
              />
        </div>
      );
    }
    return slides;
  };

  return (
    <div className={classNames({
      [style.root]: true,
      [style.rootIsStacked]: isStacked
    })}>
      {renderIf(parentQuantity > 1, () => (
        <Typography variant="body1" className={style.optionsText}>
          {renderIf(isSameForAll, () => (
            <Trans t={t}>
              Options for all {{parentQuantity}} items
            </Trans>
          ), () => (
            <Trans t={t}>
              Options for item {{number: slideNumber + 1}} of {{parentQuantity}}
            </Trans>
          ))}
        </Typography>
      ))}

      {renderIf(noCarousel, () => (
        <div data-testid="single-list" className={classNames({
          [style.singleList]: true,
          [style.singleListIsStacked]: isStacked
        })}>
          <ListItems
            wrapperStyle={wrapperStyle}
            selectedMenuOptions={selectedMenuOptionsMDA[0]}
            menuOptions={menuOptions}
            theme={theme}
            currency={currency}
            gridSizes={gridSizes}
            gridGutter={gridGutter}
            onChanged={(changedId: string, isChecked: boolean) => {
              const firstOptions = selectedMenuOptionsMDA[0];
              toggleSelectionMenuOptions(firstOptions, changedId, parentQuantity, isSameForAll);

              // if there is no carousel (ie isSameForAll is true) then we only want to set 1 option
              const _selectedMenuOptionsMDA = [firstOptions];

              // updates internal component state
              setSelectedMenuOptionsMDA(_selectedMenuOptionsMDA);

              // updates parent with new data
              onChanged(_selectedMenuOptionsMDA, isSameForAll);
            }}
              />
          </div>
      ), () => (
        <div data-testid="carousel">
          <Carousel
            value={slideNumber}
            onChange={setSlideNumber}
          >
            {getSlides()}
          </Carousel>

          <div className={classNames({
            [style.dots]: true,
            [style.dotsIsDark]: isDark
          })} >
            <Dots value={slideNumber} onChange={setSlideNumber} number={parentQuantity} />
          </div>
        </div>
      ))}

      <div className={classNames({
        [style.footer]: true,
        [style.footerNoCarouselShown]: noCarousel,
        [style.footerIsStacked]: isStacked
      })}>
        <div>
          {/* still needs an empty div when no carousel so that flex space-between still works */}
          {renderIf(parentQuantity > 1, () => (
            <FormControlLabel
                control={
                  <Checkbox
                    data-testid="same-for-all-checkbox"
                    checked={isSameForAll}
                    onChange={(evt, isChecked) => {
                      const firstOptions: IBookingMenuOption[] = selectedMenuOptionsMDA[0];

                      const _selectedMenuOptionsMDA = populateBasedOnFirstOptions(firstOptions, isChecked, parentQuantity);

                      // updates internal component state
                      setSelectedMenuOptionsMDA(_selectedMenuOptionsMDA);
                      setIsSameForAll(isChecked);

                      // updates parent with new data
                      onChanged(_selectedMenuOptionsMDA, isChecked);
                    }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="default" />}
                    checkedIcon={<CheckBoxIcon fontSize="default" color="secondary" />}
                  />
                }
                label={t(`Apply to all x{{parentQuantity}} items`, { parentQuantity })}
              />
          ))}
        </div>
        <div>
          {/* still needs an empty div when no carousel so that flex space-between still works */}
          {renderIf(!noCarousel, () => (
            <div data-testid="carousel-nav-btns" className={classNames({
              [style.carouselNavBtns]: true,
              [style.carouselNavBtnsIsStacked]: true
            })}>
              <Button aria-label="Previous" size="small" color="secondary" variant="contained"
                disabled={slideNumber === 0}
                data-testid="btn-previous"
                onClick={() => {
                  const _slideNumber = slideNumber-1;
                  setSlideNumber(_slideNumber > 0 ? _slideNumber : 0);
                }}
              >
                <ArrowBackIcon fontSize="small" />
                <Trans t={t}>
                  Previous
                </Trans>
              </Button>

              <Button aria-label="Next" size="small" color="secondary" variant="contained"
                disabled={slideNumber === parentQuantity-1}
                data-testid="btn-next"
                onClick={() => {
                  const _slideNumber = slideNumber+1;
                  setSlideNumber(_slideNumber < parentQuantity-1 ? _slideNumber : parentQuantity-1);
                }}
              >
                <Trans t={t}>
                  Next
                </Trans>
                <ArrowForwardIcon fontSize="small" />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
