import React, {useEffect, useState} from 'react';
import style from './style.module.scss';
import { IDispatchFromProps, IStateFromProps } from './types';
import SimplePage from 'shared-components/simple-page/index';
import { ISimplePageButton } from 'shared-components/simple-page/types';
import {MessageService} from 'app/services/message/message.service';
import {bookingStatusType, BookingType} from 'app/services/booking/booking.types';
import {renderIf} from 'app/services/utils/utils.service';
import { Button, Table, TableBody, TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import {bookingAction} from 'app/services/route/route.types';
import {canCancelBooking, canEditBooking, getManageBookingConfig, canConfirmBooking} from './helpers';
import {BookingService} from 'app/services/booking/booking.service';
import MenuOptionSummary from 'app/components/MenuOptionSummary';
import {lookType} from 'app/components/MenuOptionSummary/types';
import MuiAlert from '@material-ui/lab/Alert';
import LoaderOverlay from "app/components/LoaderOverlay";
import ConfirmModalDialog from "app/components/ConfirmModalDialog";
import moment from "moment";
import { PaymentService } from 'app/services/payment/payment.service';
import {IntlService} from "app/services/intl/intlService";
import ScheduleFailDialogContainer from "app/components/ScheduleFailDialog/container";
import RobotValidationModalDialog from "app/components/RobotValidationModalDialog";
import {BookingMethods, servicePaymentType} from "shared-types/index";
import classNames from 'classnames';
import DisplayBookedByInfo from 'app/components/DisplayBookedByInfo/DisplayBookedByInfo';
import { Trans, useTranslation } from 'react-i18next';
import { useLocalizeTime } from 'app/services/localization/useLocalizeTime';
enum RobotStateEnum {
  CANCEL = "CANCEL",
  CONFIRM = "CONFIRM",
}
const NS = 'ManageBooking';

export const EDIT_BOOKING_BTN_TEXT = 'Edit Booking';
export const CONFIRM_BOOKING_BTN_TEXT = 'Confirm Booking';
export const CANCEL_BOOKING_BTN_TEXT = 'Cancel Booking';
export const ACCEPT_BOOKING_BTN_TEXT = 'Accept Booking';
export const CONFIRM_BOOKING_WITH_ROBOT_TEXT = "To CONFIRM your reservation, please complete the below and then CONFIRM";
export const CANCEL_BOOKING_WITH_ROBOT_TEXT = "To CANCEL your reservation, please complete the below and then CONFIRM";

const PRIMARY_CLR = 'primary';

const Alert = withStyles({
  icon: {
    alignItems: 'center'
  }
})(MuiAlert);
export default function ManageBooking({
    theme,
    booking, appSettings, activeVenue, tableData, manageBookingErrorMessage, menuOptions, standbyConfirmationTimeoutInMinutes,
    handleCancelBooking, handleEditBooking, handleConfirmBooking, handleRebook, wrapperStyle, handleGoToPayment, bookedBy, isBookedBy
  }: IStateFromProps & IDispatchFromProps) {
  const { t, i18n } = useTranslation("manageBooking");
  const { localizeTime } = useLocalizeTime();

  const  cancelChargeModalText= `A cancellation fee will be charged to the credit card used to guarantee this reservation should you proceed, according to the cancellation policy you agreed to when making your reservation.
  ${activeVenue.widgetSettings.enableRobotValidation ?  CANCEL_BOOKING_WITH_ROBOT_TEXT :"To continue cancelling your reservation please press CONFIRM below and this charge will be processed."}.`;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showRobotValidationModal, setShowRobotValidationModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [forceHideButtons, setForceHideButtons] = useState<boolean>(false);
  const [forceHideCancelButton, setForceHideCancelButton] = useState<boolean>(false);
  const [robotValidationModalMessage, setRobotValidationModalMessage] = useState<string>(CANCEL_BOOKING_WITH_ROBOT_TEXT);
  const [robotState, setRobotState] = useState<RobotStateEnum>();

  const cancelBooking = () => {
    if(activeVenue.widgetSettings.enableRobotValidation && activeVenue.widgetSettings.enableCancelRobotValidation)
    {
      setRobotValidationModalMessage(CANCEL_BOOKING_WITH_ROBOT_TEXT);
      setShowRobotValidationModal(true)
      setRobotState(RobotStateEnum.CANCEL);
    } else {
      doCancelBooking();
    }
  }
  const _handleConfirmBooking = () => { // can get overridden by SB with payment further down
    handleConfirmBooking()
      .then(() => {
        if (isPaidStandbyConfirm) {
          setForceHideButtons(true);
        }
      })
      // on success it will redirect, so don't need to update anything
      .catch(() => {
        setIsLoading(false);
        setErrorMessage('Sorry, there was a problem confirming your booking. Please try again.');
      });
  }
  const confirmBooking = () => {
    if(activeVenue.widgetSettings.enableRobotValidation && activeVenue.widgetSettings.enableConfirmBookingRobotValidation) // updated
    {
      setRobotValidationModalMessage(CONFIRM_BOOKING_WITH_ROBOT_TEXT);
      setShowRobotValidationModal(true)
      setRobotState(RobotStateEnum.CONFIRM);
    } else {
      _handleConfirmBooking();
    }
  }
  const robotConfirmCallback = () => {
    switch (robotState) {
      case RobotStateEnum.CANCEL:
        robotValidationModalConfirmCancelBooking();
        break;
      case RobotStateEnum.CONFIRM:
        setShowRobotValidationModal(false);
        _handleConfirmBooking();
        break;
    }
  }
  const doCancelBooking = () =>{
    setIsLoading(true);
    handleCancelBooking().then(() => {
      const { origin, search, pathname } = window.location
      if (pathname === "/confirm") {
        window.location.href = origin + search;
      } else {
        window.location.reload();
      }
    }).catch(() => {
        setIsLoading(false);
        setErrorMessage('Sorry, there was a problem cancelling your booking. Please try again.');
      });
  }

  const confirmModal = () => {
    setShowModal(false);
    doCancelBooking();
  };
  const cancelModal = () => {
    setShowModal(false);
  };

  const robotValidationModalConfirmCancelBooking = () =>{
    setShowRobotValidationModal(false);
    doCancelBooking();
  }

  const robotValidationModalCancel = () =>{
    setShowRobotValidationModal(false);
  }


  /**
   * Timezone times are mainly handled on back end, but here we must check the current time at the venue against
   * the booking time to check it's validity.
   */
  const bookingTime: Date = new Date(booking.rhinoTime);
  const venueTime: Date = BookingService.getVenueTime(activeVenue);

  const canConfirm: boolean = canConfirmBooking(booking, bookingTime, venueTime);
  const canEdit = canEditBooking(booking, appSettings.source, activeVenue, bookingTime, venueTime);
  const canCancel = canCancelBooking(booking, appSettings.source, activeVenue);
  /**
   * Currently user will see a 'Payment Error' screen if they have paid already. Would be nicer to show them the booking
   * info and hide the cancellation button.
   * @todo: check if Jem agrees with this and add logic to the 'canCancel' condition.
   */
  const cancellationTime = parseInt(activeVenue.widgetSettings.bookingCancellationWindow) > 0 ? activeVenue.widgetSettings.bookingCancellationWindow : null;

  const showPreAuthMsg = (bookingTime: Date) => {
    const bookingTimeMinusCancellationPeriod = moment(bookingTime).subtract(cancellationTime, 'hours');
    return moment(venueTime).isAfter(bookingTimeMinusCancellationPeriod);
  };

  const {viewDate, payment} = booking;

  const cnf = getManageBookingConfig(booking, appSettings, activeVenue, bookingTime, venueTime);
  let {title, message, hideDetails} = cnf;
  title = t(title);
  message = t(message);
  let {hideButtons} = cnf;
  let buttons: ISimplePageButton[];
  let bodyIsMarkDown = true;
  let linkExpired = false;
  const isPreAuth = payment && payment.paymentType === servicePaymentType.preAuth;
  const hasPayment = !!(payment && payment.paymentType !== servicePaymentType.noPayment && payment.price);
  const hasPaid = booking?.payment?.amountPaid > 0;
  const isPaidStandbyConfirm = hasPaid && appSettings.action === bookingAction.confirm && booking.bookingType === BookingType.StandbyList;

  activeVenue.phone = activeVenue.phone || '';

  const standbyListCancel = (details?: string) => {
    /**
     * This message is for when the venue sends the customer an email that they have been accepted for a booking
     * via the standby list, but then the customer clicks the cancel button in the email.
     */
    return {
      title: canCancel
        ? t(`Standby List Cancellation`)
        : t(`Standby List Details`),
      message: hasPaid && !isPreAuth
        ? t(` You have already paid for this booking. Please call us on {{phone}} if you wish to cancel.`, { phone: activeVenue.phone })
        : canCancel
          ? t(` Are you sure you would like to cancel your request for a table at {{details}}?`, { details })
          : t(` Please call us on {{phone}} if you wish to cancel.`, { phone: activeVenue.phone }),
      showConfirmBtn: false,
      showCancelBtn: canCancel && (!hasPaid || isPreAuth)
    }
  }

  if (booking.bookingType === BookingType.StandbyList
    && booking.status !== bookingStatusType.standbyPending
    && booking.status !== bookingStatusType.confirmed
    && booking.status !== bookingStatusType.seated
    && appSettings.action === bookingAction.confirm) {

    /**
     * Scenario where confirmation has been sent, but then retracted by the venue
     */
    hideDetails = true;
    linkExpired = true;
  } else if (manageBookingErrorMessage) {
    title = t(manageBookingErrorMessage.title);
    message = MessageService.getMessage(t(manageBookingErrorMessage.message, manageBookingErrorMessage.params) as string, activeVenue, '')
    hideDetails = true;
  } else {
    buttons = hideButtons ? null : [];

    const cancelBtn: ISimplePageButton = {
      buttonText: t(CANCEL_BOOKING_BTN_TEXT), color: 'default', buttonCallback: () => {

        if (booking.bookingType === BookingType.StandbyList
          || (booking.method === BookingMethods.walkin
            && booking.status === bookingStatusType.partiallySeated)) {
          cancelBooking();
        } else {
            let offset = activeVenue.widgetSettings.disableCancelTimeWindowInHours || 0;

          if (isPreAuth) {
            offset = 0;
          }

          const bookingCancelTime = BookingService.getBookingTimeWithOffset(bookingTime, offset);

	  // Booking cancel time is to make sure when the user clicks cancel button, its still valid to cancel the booking as user may have opened the booking
          // when it was in allowed cancel window but when they click cancel it may have passed the cancel time.
          if (isPreAuth && cancellationTime) {
            showPreAuthMsg(bookingTime) ? setShowModal(true) : cancelBooking(); // Show PreAuth msg if cancel time is after the cancellationTimePeriod from the BookingTime or just cancel booking
          }
          else if (venueTime < bookingCancelTime) {
            /**
             * Eg, table booked for 7:00pm and time at the venue is 6:59pm, we can still cancel the booking
             */
            cancelBooking();
          } else {
            /**
             * Eg, table booked for 7:00pm and the time at the venue is 7:00pm or more - can't cancel because already started
             */
            setPageTitle(t('Cancellation Error'));
            setErrorMessage(t('Sorry, we can’t cancel your booking as the cancellation period has expired.'));
            setForceHideCancelButton(true);
          }
        }
      }, size: 'small'
    };

    const editBtn: ISimplePageButton = {
      buttonText: t(EDIT_BOOKING_BTN_TEXT),
      color: PRIMARY_CLR,
      buttonCallback: ()=>{
        handleEditBooking();
      },
      size: 'small'
    };

    const confirmBtn: ISimplePageButton = {
      buttonText: t(CONFIRM_BOOKING_BTN_TEXT),
      color: PRIMARY_CLR,
      buttonCallback: confirmBooking,
      size: 'small'
    };

    /**
     * It's worth noting here that the confirm button will be visible for both "edit" and "confirm" actions,
     * but the edit button is only visible for the "edit" action (not the "confirm" action).
     */
    let showConfirmBtn: boolean = canConfirm &&
      (appSettings.action === bookingAction.confirm || appSettings.action === bookingAction.edit) &&
      booking.status !== bookingStatusType.confirmed &&
      booking.status !== bookingStatusType.seated &&
      booking.status !== bookingStatusType.cancelled;

    let showEditBtn: boolean = canEdit && appSettings.action === bookingAction.edit;
    let showCancelBtn: boolean = canCancel;

    if (booking.bookingType === BookingType.StandbyList) {

      confirmBtn.buttonText = t(ACCEPT_BOOKING_BTN_TEXT);

      if (hasPayment && !hasPaid) {
        confirmBtn.buttonText = t('Pay Now');
        if (isPreAuth) {
          confirmBtn.buttonText = t('Proceed');
        }
        confirmBtn.buttonCallback = handleGoToPayment
      }

      if (hasPaid) {
        buttons = [];
        hideButtons = false;
      }

      showCancelBtn = false;
      showConfirmBtn = hasPaid;
      showEditBtn = false;

      let details;

      if (booking.status === bookingStatusType.standbyPending ||
        booking.status === bookingStatusType.unconfirmed) {

        message = t(`Hello {{name}}.`, { name: booking.customer.firstName });
        bodyIsMarkDown = false;

        // details = `${booking.viewTime} for ${booking.covers} ${booking.covers === 1 ? 'person' : 'people'}`;
        details = t("{{time}} for {{count}} people", {
          count: booking.covers,
          time: localizeTime(booking.viewTime)
        });
      }

      if (booking.status === bookingStatusType.standbyPending) {

        switch(appSettings.action) {
          case bookingAction.confirm:
            /**
             * This message is for when the venue sends the customer an email that they have been accepted for a booking
             * via the standby list and they click to accept the booking.
             */
            title = t('Standby List Confirmation');

            const inHours = standbyConfirmationTimeoutInMinutes / 60;
            const hours = Math.floor(inHours);
            const minutes = standbyConfirmationTimeoutInMinutes - (hours * 60)
            const duration =
              `${hours ? t("{{count}} hours", { count: hours }) : ''} ${t("and")} ${t("{{count}} minutes", { count: minutes})}`

            message += t(`Your request for a table at {{details}} is available!`, { details });
            message += "\n" + t("You have {{duration}} to accept this booking{{orCancel}}.", {
                duration,
                orCancel: canCancel ? t(" or you can cancel if you're unable to make it") : ""
              });

            showConfirmBtn = true;
            showCancelBtn = canCancel && (!hasPaid || isPreAuth);
            break;

          case bookingAction.cancel:
            const result = standbyListCancel(details);
            title = result.title;
            message += result.message;
            showConfirmBtn = result.showConfirmBtn;
            showCancelBtn = result.showCancelBtn;
            break;

          default:
            title = t('Invalid URL');
            message = t('There seems to be a problem with the URL you have entered.');
            hideDetails = true;
            hideButtons = true;
        }
      } else if (booking.status === bookingStatusType.unconfirmed && appSettings.action === bookingAction.cancel) {
        const result = standbyListCancel(details);
        title = result.title;
        message += result.message;
        showConfirmBtn = result.showConfirmBtn;
        showCancelBtn = result.showCancelBtn;
      } else if (hasPaid && appSettings.action === bookingAction.edit) {
        /**
         * This message is for when customer tries to remove themselves from the standyby list after adding themselves
         * via the widget and receiving an email with details.
         */
        title = t('Standby List Details');
        message = PaymentService.getStandbyPaidNoTableMessage(activeVenue.phone, activeVenue.currency, booking.payment.amountPaid, isPreAuth);
        showConfirmBtn = false;
        showCancelBtn = canCancel && isPreAuth;
      } else {
        message = MessageService.getMessage(message, activeVenue, '');
      }
    } else {
      message = MessageService.getMessage(message, activeVenue, '');
    }

    if (!hideButtons && !forceHideButtons) {
      if (showCancelBtn && !forceHideCancelButton) {
        buttons.push(cancelBtn);
      }
      if (showEditBtn) {
        if (showConfirmBtn) {
          editBtn.color = 'secondary';
        }
        buttons.push(editBtn);
      }
      if (showConfirmBtn) {
        buttons.push(confirmBtn);
      }
    }
  }
  const showBookingAgainBtn = booking.status === bookingStatusType.cancelled;
  if (linkExpired) {
    title = t('Link Expired');
  }

  const [pageTitle, setPageTitle] = useState<string>(title);

  useEffect(() => {
    setPageTitle(title)
  }, [title]);

  return (
    <SimplePage
      theme={theme}
      title={pageTitle}
      body={linkExpired ? '' : message}
      bodyIsMarkDown={bodyIsMarkDown}
      buttons={buttons}
      customButtonWrapClass={buttons?.length === 1 && buttons[0].color === PRIMARY_CLR ? style.buttonWrapStylesSingleButton : style.buttonWrapStyles}
      childContentBelowBody={true}
    >
      {renderIf(!bookingAction.cancel && !hideDetails && hasPayment && !hasPaid && booking.bookingType === BookingType.StandbyList, () => (
        <Typography className={style.paymentMessage}>
          {PaymentService.getPaymentMessage(payment.paymentType, activeVenue.currency, payment.price, false)}
        </Typography>
      ))}

      {renderIf(!hideDetails && hasPayment && hasPaid && booking.bookingType === BookingType.StandbyList, () => (
        <Typography className={style.paymentMessage}>
          Paid: {IntlService.currencyValue(payment.price, activeVenue.currency, i18n.language)}
        </Typography>
      ))}

      {renderIf(viewDate && !hideDetails, () => (
        <div className={style.tableWrap} data-testid="table">
          <Table aria-label="summary" size="small">
            <TableBody>
              {tableData.items.map((item, j) => !item.value ? null : (
                <TableRow key={`payment-conf-item-${j}`} data-testid="table-row">
                  <TableCell>{t(item.name)} {item.prefix}</TableCell>
                  <TableCell>
                    {
                      item.name === "At" && typeof item.value === "string"
                      ? localizeTime(item.value)
                      : item.value
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {renderIf(menuOptions && menuOptions.length, () => (
            <>
              <Typography variant="h3" className={style.bookingOptHeading}>
                <Trans t={t}>
                Booking Options
                </Trans>
              </Typography>
              <Table aria-label="booking options" size="small">
                <TableBody>
                  <MenuOptionSummary theme={theme} currency={activeVenue.currency} menuOptions={menuOptions}
                                     look={lookType.manageBooking}/>
                </TableBody>
              </Table>
            </>
          ))}
          {renderIf(isBookedBy, () => (
            <DisplayBookedByInfo bookedBy={bookedBy} isStacked wrapperStyle={wrapperStyle} title={t('Booked By')}
                                 containerStyle={{marginTop: 30}}
            />
          ))}
        </div>
      ))}
      {showBookingAgainBtn && (
        <div className={classNames({
          [style.centerButton]: true,
        })}
        >
          <Button onClick={handleRebook} variant="contained" color="primary" data-testid="book-again-button" >{t("BOOK AGAIN?")}</Button>
        </div>
      )}
      {/*
        Needed to use a local loader, rather than global so that view doesn't get unmounted in the case of an error message
        needing to be shown.
       */}
      {renderIf(isLoading, () => (
        <LoaderOverlay theme={theme} message={t("Processing, please don't refresh your browser.")}/>
      ))}

      <ConfirmModalDialog
        wrapperStyle={wrapperStyle}
        modalMsg={cancelChargeModalText}
        showRobotConfirmation={activeVenue.widgetSettings.enableRobotValidation
          && activeVenue.widgetSettings.enableCancelRobotValidation}
        doShow={showModal}
        handleConfirm={confirmModal}
        handleCancel={cancelModal}
        confirmText={t('Confirm')}
        cancelText={t('Go Back')}
        modalHeader={t('Cancellation Charge')}
      />

    <RobotValidationModalDialog
            wrapperStyle={wrapperStyle}
            modalMsg={t(robotValidationModalMessage)}
            doShow={showRobotValidationModal}
            handleConfirm={robotConfirmCallback}
            handleCancel={robotValidationModalCancel}
            confirmText={t('Confirm')}
            cancelText={t('Go Back')}
            modalHeader={t('Cancellation Charge')}
          />

      {renderIf(errorMessage, () => (
        <Alert severity="error" variant="filled" className={style.errorMessage} data-testid="alert-message">
          <Trans t={t}>
            {errorMessage}
          </Trans>
        </Alert>
      ))}
      <ScheduleFailDialogContainer />

    </SimplePage>
  )
}

