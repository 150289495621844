import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import {IActionGen} from "app/types/common.types";
import {IWidgetModel} from "app/models";
import {IScheduleTime} from "shared-types/WidgetTypes";

const NS = 'selectedStandbyTime';

export default {
  [StandbyActionsNS.Type.SELECTED_STANDBY_TIME]: (state: IWidgetModel, {payload}: IActionGen<string>): IWidgetModel => {

    const utcTime = payload;
    let selectedTime: IScheduleTime;
    if (utcTime) {
      selectedTime = state.filteredTimes ? state.filteredTimes.find(t => t.time === utcTime) : null;
    }

    return {
      ...state,
      standbyData: {
        ...state.standbyData,
        utcTime: selectedTime ? selectedTime.time : null,
        viewTime: selectedTime ? selectedTime.name : null
      },
      blockNav: null
    }
  },


  [StandbyActionsNS.Type.SET_IS_FLEXIBLE_TIME]: (state: IWidgetModel, {payload}: IActionGen<boolean>): IWidgetModel => {
    return {
      ...state,
      standbyData: {
        ...state.standbyData,
        isFlexibleTime: payload
      }
    }
  }
}
