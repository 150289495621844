import { IPaymentSummaryMenuOption } from 'app/components/PaymentSummary/types.d';
import {IWidgetTheme, wrapperStyleType} from "shared-types/index";

export interface IMenuOptionSummary {
  theme: IWidgetTheme;
  currency: string;
  menuOptions?: IPaymentSummaryMenuOption[];
  look: lookType;
}

export enum lookType {
  manageBooking = 1,
  paymentSummary = 2
}
