import React, {useState} from 'react';
import {IDispatchFromProps, IOwnProps, IStateFromProps, menuOptionType} from './types';
import {themeTypes} from 'shared-types/index';
import {renderIf} from 'shared-services/react-utils-service/index';
import {MenuOptionNarrow} from "./narrow/MenuOptionNarrow";
import {MenuOptionWide} from "./wide/MenuOptionWide";
import { IntlService } from 'app/services/intl/intlService';

const NS = 'MenuOption';

// exported for tests
export const MAX_FAILED_CHILD_OPTIONS_ATTEMPTS = 5;

export default function MenuOption(props: IStateFromProps & IDispatchFromProps & IOwnProps & { children?: any }) {

  const {
    theme, isStacked, price, currency, bookingCancellationTimeWindowInHours, children, extraDetails,
    link, type, allChildMenuOptionIds, isUpsell,
    handleCheckboxChanged, handleIdleCachedMenuOptionDetails, updateCachedMenuOptionDetails
  } = props;

  let {extrasHasPayment, paymentTypeError} = props;

  const [childDetailsAttemptCount, setChildDetailsAttemptCount] = useState<number>(0);
  const [loadingChildDetails, setLoadingChildDetails] = useState<boolean>(false);

  let linkUrl = link;
  if (link && link.indexOf('http') !== 0) {
    linkUrl = 'http://' + link;
  }

  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const hasCb = !!(type === menuOptionType.checkboxes && handleCheckboxChanged);

  // @todo: determine why we need this and if it can be removed. severity: low
  if (extraDetails && handleIdleCachedMenuOptionDetails) {
    handleIdleCachedMenuOptionDetails(extraDetails);
  }

  /**
   * Checks all child options adding them to the store's 'cachedMenuOptionDetails' prop, then re-rendering once loaded.
   * Once they have been added to 'cachedMenuOptionDetails', then 'allChildMenuOptionIds' will become empty and this condition
   * will be skipped.
   */
  if (allChildMenuOptionIds && allChildMenuOptionIds.length && updateCachedMenuOptionDetails) {

    /**
     * Before checking the child options for their payment details, we assume that at least 1 DOES have payment, and therefore
     * hide the 'No payment is required' message, using `extrasHasPayment = true`. If the requests are successful (which they should be)
     * and all child options are in fact free, then the 'No payment is required' is allowed to be shown.
     */
    extrasHasPayment = true;

    if (!loadingChildDetails) {

      if (childDetailsAttemptCount < MAX_FAILED_CHILD_OPTIONS_ATTEMPTS) {
        // uses a loading state just so we don't spam 'updateCachedMenuOptionDetails' while requests are still in progress
        setChildDetailsAttemptCount(childDetailsAttemptCount + 1);
        setLoadingChildDetails(true);
        updateCachedMenuOptionDetails(allChildMenuOptionIds)
          .then(() => {
            setLoadingChildDetails(false);
          })
          .catch(err => {
            console.warn(NS, 'updateCachedMenuOptionDetails rejected', err);
            setLoadingChildDetails(false);
          })
      } else {
        paymentTypeError = true;
      }
    }
  }

  const currencyValue = IntlService.currencyValue(price, currency);

  // Must display singular hour (i.e. without 's'), when cancellation hour is equal to 1
  const hoursText = parseInt(bookingCancellationTimeWindowInHours) === 1 ? "hour" : "hours";

  return (
    <>
      {renderIf(!isUpsell, (
          <MenuOptionNarrow {...props} isDark={isDark} hasCb={hasCb} isStacked={isStacked} linkUrl={linkUrl}
                            extrasHasPayment={extrasHasPayment} paymentTypeError={paymentTypeError} currencyValue={currencyValue} hoursText={hoursText} >
            {children}
          </MenuOptionNarrow>
        ), () => (
          <MenuOptionWide {...props} isDark={isDark} hasCb={hasCb} isStacked={isStacked} linkUrl={linkUrl}
                          extrasHasPayment={extrasHasPayment} paymentTypeError={paymentTypeError} currencyValue={currencyValue} hoursText={hoursText}>
            {children}
          </MenuOptionWide>
        )
      )}
    </>
  )
}
