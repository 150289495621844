import React, { useEffect, useState } from 'react';
import style from './style.module.scss';
import { IDispatchFromProps, IStateFromProps } from './types';
import FooterNavContainer from '../FooterNav/container';
import { Chip, Paper, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import ColumnWrap2Container from '../ColumnWrap2/container';
import { renderIf, UtilsService } from 'app/services/utils/utils.service';
import classNames from 'classnames';
import PaymentMessageContainer from '../PaymentMessage/container';
import { IframeResizerService } from "app/services/iframeResizer/iframeResizer.service";
import { themeTypes, wrapperStyleType } from "app/models";
import { IBookingMenuOptionExtrasUpdater } from "app/services/booking/booking.types";
import ChildMenuOptionOverlay from "shared-components/booking-options-section/ChildMenuOptionOverlay";
import { IWidgetTheme } from "shared-types/WidgetTypes";
import ScheduleFailDialogContainer from "app/components/ScheduleFailDialog/container";
import appValues from 'app/constants/appValues';
import ReCaptchaV2 from 'react-google-recaptcha';
import DisplayBookedByInfo from 'app/components/DisplayBookedByInfo/DisplayBookedByInfo';
import { Trans, useTranslation } from 'react-i18next';
import { useLocalizeTime } from 'app/services/localization/useLocalizeTime';

const NS = 'Summary';


export default function Summary({
                                  wrapperStyle, details, tags, showPaymentDetails, upsellMenuOptions, theme, showRecaptcha, isEditMode,
                                  selectionData, handleDataForUpsell, hideUpsell, handleRecaptcha, bookedBy, isBookedBy
}: IStateFromProps & IDispatchFromProps) {
  const { t, i18n } = useTranslation("summary");
  const { localizeTime } = useLocalizeTime();

  const hasTags = !!(tags && tags.length);
  const isStacked = IframeResizerService.isStacked(wrapperStyle);
  const isTakeAwayMode = false; // Maybe useful for future should takeaway mode be enabled on widget v2
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;
  const [newSelectionData, setSelectionData] = useState<IBookingMenuOptionExtrasUpdater>(selectionData);
  useEffect(() => {
    // as no paid upsellOption in edit booking, we need calculate payment to change button text
    if (isEditMode && !upsellMenuOptions.length) {
      handleDataForUpsell(newSelectionData.implicitChildMenuOptions);
    }
  }, []);

  return (
    <ColumnWrap2Container>
      {/* left */}
      <div className={style.column2}>
      <Paper elevation={1} className={[style.paperBox, style.paperBox1].join(' ')}>
        <Typography variant="h2" className={style.detailsTitle}>
          {isBookedBy 
            ? t('Confirm your customer details') 
            : t('Confirm your details')}
        </Typography>
        <Table aria-label="summary" size="small" x-ms-format-detection="none"
               className={classNames({
                 [style.summaryTable]: true,
                 [style.summaryTableIsStacked]: isStacked,
                 [style.summaryTableIsNarrow]: wrapperStyle === wrapperStyleType.narrow,
                 [style.summaryTableIsStandard]: wrapperStyle === wrapperStyleType.standard
               })}>
          <TableBody>
            {details.map(item => (
              <TableRow key={item.order} data-testid="summary-detail-row">
                <TableCell>{t(item.name)}</TableCell>
                <TableCell>
                  {item.name === "At"
                    ? localizeTime(item.value)
                    : item.value
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {isBookedBy &&  <Paper elevation={1} className={[style.paperBox, style.paperBox1, style.summaryBookedByTopMargin].join(' ')}>
        <DisplayBookedByInfo isDark={isDark} bookedBy={bookedBy} isStacked wrapperStyle={wrapperStyle} isSummaryTable
        subTitle={`*${t('All communications will be sent to this contact')}*`}
        />
      </Paper>
        }
      </div>
      {/* right */}
      <div className={style.column2}>

        <Paper elevation={1} className={classNames({
          [style.paperBox]: true,
          [style.paperBox2]: true,
          [style.paperBox2IsLandscapeHasTags]: !isStacked && hasTags
        })}>
          {renderIf(hasTags, () => (
            <div>
              <div className={style.specialWrap}>
                <Typography variant="subtitle1">
                  <Trans t={t}>
                    Special Requirements
                  </Trans>
                </Typography>

                <div className={style.tagWrap}>
                  {/* Display tooltip when tag is aprox too long*/}
                  {tags.map(t => (
                    <div key={t._id} data-testid="summary-tag">
                      <Tooltip title={t.name.length >= 40 ? t.name : ""} enterTouchDelay={100}>
                        <Chip label={t.name} color="primary"/>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {renderIf(showPaymentDetails, () => (
            <>
              <PaymentMessageContainer useActiveService={true}/>
              <Typography variant="body1" data-testid="summary-payment-confirm-message">
                {isTakeAwayMode ? (
                  <Trans t={t}>
                    Please confirm your order details are correct.
                  </Trans>
                ) : (
                  <Trans t={t}>
                    Please confirm your booking details are correct so we can hold your table.
                  </Trans>
                )}
              </Typography>
            </>
          ), () => (
            <>
              <Typography variant="body1" data-testid="payment-message">
                <Trans t={t}>
                  By clicking the book now button, you are agreeing to the terms and conditions.
                </Trans>
              </Typography>
              {showRecaptcha && <ReCaptchaV2
                theme={theme.type === themeTypes.light ? 'light' : 'dark'}
                data-testid="recaptcha-widget"
                sitekey= {appValues.RECAPTCHA_SITE_KEY}
                lang={i18n.language}
                hl={i18n.language}
                title={`Captcha-${i18n.language}`}
                onChange={handleRecaptcha}
              />}
            </>
          ))}
        </Paper>

        {renderIf(!hideUpsell && upsellMenuOptions.length > 0, () => (
          <ChildMenuOptionOverlay
            selectionData={newSelectionData}
            wrapperStyle={wrapperStyle}
            theme={theme as IWidgetTheme}
            currency={'AUD'}
            menuOptionsImplicit={upsellMenuOptions}
            menuOptionsExplicit={[]}
            isUpsell={true}
            handleClose={(type) => {
              setSelectionData(null);
              if (type === 'close') { // To call the payment type again if they skip the upsell
                handleDataForUpsell([]);
              }
            }}
            handleDone={(data) => {
              handleDataForUpsell(data.implicitChildMenuOptions);
            }}/>
        ))}
        <FooterNavContainer/>
        <ScheduleFailDialogContainer />
      </div>
    </ColumnWrap2Container>
  )
}

