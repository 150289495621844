import {FormControlLabel, Radio} from "@material-ui/core";
import style from "./style.module.scss";
import React from "react";

const NS = 'MenuOptionsRadio -> CustomRadio';


export default function CustomRadio({id, label, checkedCB}: {
  id:string, label: string, checkedCB?: () => void
}) {
  return (
    <FormControlLabel
      className={style.radLbl}
      value={id} label={label}
      control={
        <Radio color="secondary" className={style.radBtn} onChange={(evt, checked) => {
          // scrolls a menu option into view so the whole thing can be seen
          if (checkedCB && checked) {
            checkedCB();
          }
        }} />
      } />
  );
}
