import { Dispatch } from "redux";
import { IRootState } from "app/reducers";
import { IVenue, IWidgetModel, modeType } from "app/models";
import { first, catchError } from "rxjs/operators";
import { RouteService } from "app/services/route/route.service";
import { ROUTE_NAMES } from "app/services/route/route.types";
import { PaymentService } from "shared-services/payment-service/";
import { of } from "rxjs";
import {
    sendPaymentAnalytics,
} from "../booking/analyticsHelpers";
import { applyPromoCode as _applyPromoCode, verifyHasPromoCode } from "app/actions/promoCode/promoCodeActions";
import { BookingActionsTypes } from "app/actions/booking/bookingActionsTypes";
import {
    servicePaymentType,
    IProcessPayment,
    IProcessStripePayment,
    IPaymentDetailsGenericData,
    IEwayInfo,
    IPrepareEwayData,
    IPrepareEwayFunctionData,
    IErrorResponse,
    ISavePreAuthResponseData,
    bookingErrorType,
    IStripePaymentError,
    IStripePaymentSuccessData,
    IEwaySummaryResponseData,
    IFunctionPaymentSummaryResponseData
} from "shared-types/index";
import { finishSession } from "../booking/helpers";
import { PaymentApiRequests } from "shared-services/payment-service/paymentApiRequests";
import appValues from "app/constants/appValues";

const NS = 'PaymentActions';

PaymentApiRequests.addEndpoints({
    payNowUrl: `${appValues.APIBASE}/bookings/payments/paynow`,
    preAuthUrl: `${appValues.APIBASE}/bookings/payments/preauth`,
    getPaymentIntent3DSecureUrl: (venueId: number, bookingId: string) => `${appValues.APIBASE}/bookings/venues/${venueId}/paymentintent/bookingid/${bookingId}`,
    finilisePayment3DSecureUrl: `${appValues.APIBASE}/bookings/payments/finilisePayment3Descure`,
    ewayPaymentSummaryUrl: `${appValues.APIBASE}/bookings/payments/completed`,
    applyPromoCodeUrl: `${appValues.APIBASE}/bookings/payments/apply-promotion-code`,
    hasPromoCodeUrl: `${appValues.APIBASE}/bookings/payments/has-promotion-code`,
    setupPreAuthStripe3DUrl: `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/setup`,
    finalizePreAuthStripe3DUrl: `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/finalize`,
    eventPayNowUrl: `${appValues.APIBASE}/bookings/payments/event-paynow`,
    eventCompletedUrl: `${appValues.APIBASE}/bookings/payments/event-completed`,
    finalisePayment3DSecureForPFUrl: `${appValues.APIBASE}/bookings/payments/finalisePayment3Dsecpf`,
    getPaymentIntent3DSecureForPFUrl: (venueId) => `${appValues.APIBASE}/bookings/payments/venues/${venueId}/paymentintent/event`,
    finalizePreAuth3DExistingCardUrl: `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/finalize-existing-card`,
    finalizePreAuthEwayExistingCardUrl: `${appValues.APIBASE}/bookings/payments/eway-preauth/finalize-existing-card`
});

export namespace PaymentActionsNS {


    export const submitStripePayment = (
        card: stripe.elements.Element,
        token: stripe.Token,
        paymentDetails: IPaymentDetailsGenericData
    ) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings } = widget;
        if (appSettings.privateFunction) {
            return submitStripePFPayment(dispatch, getState, card);
        } else {
            return submitStripeNoPFPayment(dispatch, getState, card, token, paymentDetails);
        }

    }

    /**
     * This function submits a payment for Bookings and Events but not Private Functions
     * Private functions are not the same as regular events, and use
     * different API responses and endpoints. Private functions do not support preauthenticated payments
     */
    function submitStripeNoPFPayment(
        dispatch: Dispatch,
        getState: () => any,
        card: stripe.elements.Element,
        token: stripe.Token,
        paymentDetails: IPaymentDetailsGenericData
    ): Promise<void> {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings, activeVenue, booking, stripe } = widget;
        const bookingId = appSettings.bookingId || booking._id;
        const venueId = (activeVenue as IVenue).id;
        const customerId = booking.customer._id;


        const venueSubset = {
            id: venueId,
            clientSideEncryptKey: String(null), // only needed for eway
            preAuthReleasingWindow: parseInt(widget.activeVenue.widgetSettings.preAuthReleasingWindow) || 24
        }

        return new Promise(resolve => {
            /**
             * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
             * which will break the Stripe Elements (they need to be mounted until the payment is complete).
             */

            if (widget.booking.payment.paymentType === servicePaymentType.preAuth) {
                PaymentService.processStripePreAuth(bookingId, venueSubset, stripe, card)
                    .pipe(first())
                    .subscribe(({ successPayload, errorStripePayload, errorPreauthPayload }: IProcessStripePayment) => {

                        // must resolve before dispatching success or fail so that loader can be removed cleanly
                        resolve();
                        if (successPayload) {
                            finishSession(dispatch);
                            routeToPreauthSuccess(dispatch, widget, successPayload);
                        } else if (errorStripePayload) {
                          // @ts-ignore
                          if (errorStripePayload.stripeError?.response?.status === 404) {
                            finishSession(dispatch);
                            // @ts-ignore
                            routeToStripePaymentError(dispatch, widget, { stripeError: null, backEndError: errorStripePayload.stripeError?.response });
                            return;
                          }
                          routeToStripePaymentError(dispatch, widget, errorStripePayload);
                        }
                        else if (errorPreauthPayload) {
                            routeToBookingError(dispatch, widget, errorPreauthPayload);
                        }
                        else {
                            routeToBookingError(dispatch, widget, bookingErrorType.UKNOWN_USERPAYMENTERROR);
                        }
                    });
            } else {
                PaymentService.processStripe3DSecurePayment(bookingId, widget.stripe, venueId, card)
                    .pipe(first())
                    .pipe(catchError((errMsg, caught) => {
                        return of({
                            errorPayload: {
                                stripeError: null,
                                backEndError: errMsg
                            },
                        });
                    }))
                    .subscribe(({ successPayload, errorPayload }: IProcessPayment) => {
                        // must resolve before dispatching success or fail so that loader can be removed cleanly
                        resolve();
                        if (successPayload && isIStripePaymentSuccessData(successPayload)) {
                            finishSession(dispatch);
                            routeToStripePaymentSuccess(dispatch, widget, successPayload);
                        } else if (errorPayload && isIStripePaymentError(errorPayload)) {
                            routeToStripePaymentError(dispatch, widget, errorPayload);
                        } else if (errorPayload && !isIStripePaymentError(errorPayload)) {
                          // @ts-ignore
                          if (errorPayload.backEndError?.response?.status === 404) {
                            finishSession(dispatch);
                            // @ts-ignore
                            routeToStripePaymentError(dispatch, widget, { stripeError: null, backEndError: errorPayload.backEndError?.response });
                            return
                          }
                          // @ts-ignore
                          routeToStripePaymentError(dispatch, widget, errorPayload);
                        } else {
                            routeToBookingError(dispatch, widget, bookingErrorType.UKNOWN_USERPAYMENTERROR);
                        }
                    });
            }
        });
    }

    /**
     * Submits a stripe payment for private functions.
     * Private functions are not the same as regular events, and use
     * different API responses and endpoints. Private functions do not support preauthenticated payments
     * @param card
     * @returns
     */
    function submitStripePFPayment(
        dispatch: Dispatch,
        getState: () => any,
        card: stripe.elements.Element
    ): Promise<void> {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings, activeVenue } = widget;
        const eventId = appSettings.eventId;
        const venueId = (activeVenue as IVenue).id;

        return new Promise((resolve, reject) => {
            /**
             * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
             * which will break the Stripe Elements (they need to be mounted until the payment is complete).
             */

            if (widget.booking.payment.paymentType === servicePaymentType.preAuth) {
                reject("Preauth stripe private function payments are not supported.")
            }

            PaymentService.processStripe3DSecurePFPayment(eventId, widget.stripe, venueId, card)
                .pipe(first())
                .pipe(catchError((errMsg, caught) => {
                    return of({
                        errorPayload: {
                            stripeError: null,
                            backEndError: errMsg
                        },
                    });
                }))
                .subscribe(({ successPayload, errorPayload }: IProcessPayment) => {
                    // must resolve before dispatching success or fail so that loader can be removed cleanly
                    resolve();
                    if (successPayload && isIStripePaymentSuccessData(successPayload)) {
                        finishSession(dispatch);
                        routeToStripePaymentSuccess(dispatch, widget, successPayload);
                    } else if (errorPayload && isIStripePaymentError(errorPayload)) {
                        routeToStripePaymentError(dispatch, widget, errorPayload);
                    } else if (errorPayload && !isIStripePaymentError(errorPayload)) {
                        routeToBookingError(dispatch, widget, errorPayload);
                    } else {
                        routeToBookingError(dispatch, widget, bookingErrorType.UKNOWN_USERPAYMENTERROR);
                    }
                });
        });
    }


    export const prepareEwayPayment = () => (dispatch: Dispatch, getState: () => any): Promise<IPrepareEwayData | IPrepareEwayFunctionData> => {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings } = widget;
        if (appSettings.privateFunction) {
            return prepareEwayPFPayment(dispatch, getState);
        } else {
            return prepareEwayNoPFPayment(dispatch, getState);
        }
    }



    function prepareEwayNoPFPayment(dispatch: Dispatch, getState: () => any): Promise<IPrepareEwayData | IPrepareEwayFunctionData> {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings, activeVenue, booking } = widget;
        const bookingId = appSettings.bookingId || booking._id;
        const venueId = (activeVenue as IVenue).id;
        return new Promise(resolve => {

            /**
             * First contacts the back end to get a token to use for eway API.
             * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
             * which causes the promise's resolution to fail.
             */
            PaymentService.payNowEway(venueId, bookingId)
                .pipe(first())
                .subscribe((response) => {
                    const { data } = response;

                    /**
                     * Not sending data to redux store to avoid possibility of sensitive data being stored in front end.
                     * Instead returning a promise with the data
                     */
                    resolve(data as IPrepareEwayData | IPrepareEwayFunctionData);

                }, (data: { response: IErrorResponse }) => {
                    // must resolve before dispatching fail so that loader can be removed cleanly
                    resolve(null);
                    console.warn(NS, 'prepareEwayPayment error', data.response)
                    routeToPrepareEwayPaymentError(dispatch, widget, data.response);
                });
        });
    }


    function prepareEwayPFPayment(dispatch: Dispatch, getState: () => any): Promise<IPrepareEwayFunctionData> {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings, activeVenue, booking } = widget;
        const venueId = (activeVenue as IVenue).id;
        const eventId = appSettings.eventId;
        return new Promise(resolve => {

            /**
             * First contacts the back end to get a token to use for eway API.
             * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
             * which causes the promise's resolution to fail.
             */
            PaymentService.payPFNowEway(venueId, eventId)
                .pipe(first())
                .subscribe((response) => {
                    const { data } = response;

                    /**
                     * Not sending data to redux store to avoid possibility of sensitive data being stored in front end.
                     * Instead returning a promise with the data
                     */
                    resolve(data as IPrepareEwayFunctionData);

                }, (data: { response: IErrorResponse }) => {
                    // must resolve before dispatching fail so that loader can be removed cleanly
                    resolve(null);
                    console.warn(NS, 'prepareEwayPFPayment error', data.response)
                    routeToPrepareEwayPaymentError(dispatch, widget, data.response);
                });
        });
    }


    export const submitEwayPayment = (formEl: HTMLFormElement) => (dispatch: Dispatch, getState: () => any): Promise<void> => {
        const state = getState() as IRootState;
        const widget = state.widget;
        const { appSettings, activeVenue, booking } = widget;
        const bookingId = appSettings.bookingId || booking._id;
        const venueId = (activeVenue as IVenue).id;
        const customerId = booking.customer._id;
        const preAuthReleasingWindow = parseInt(widget.activeVenue.widgetSettings.preAuthReleasingWindow) || 24;
        const ewayInfo = activeVenue.paymentSettings as IEwayInfo;
        const eventId = appSettings.eventId;

        return new Promise(resolve => {
            /**
             * Do not trigger any redux state changes, like global loaders, because it will cause the payment page to unmount,
             * which will break the Stripe Elements (they need to be mounted until the payment is complete).
             */

            if (widget.booking.payment.paymentType === servicePaymentType.preAuth) {
                PaymentService.handleEwayPreauthPayment(venueId, bookingId, customerId, formEl, ewayInfo.clientSideEncryptionKey, preAuthReleasingWindow)
                    .pipe(first())
                    .subscribe(({ success, errorType, errorMessage }) => {
                        resolve();
                        if (success) {
                            finishSession(dispatch);
                            routeToPreauthSuccess(dispatch, widget, success);
                        } else if (errorType) {
                            routeToBookingError(dispatch, widget, errorType)
                        } else {
                            routeToBookingError(dispatch, widget, bookingErrorType.UKNOWN_USERPAYMENTERROR);
                        }
                    });
            } else {
                PaymentService.handleEwayStandardPayment(venueId, eventId, bookingId, formEl)
                    .pipe(first())
                    .subscribe(({ success, errorType, errorWithCode }) => {

                        // must resolve before dispatching success or fail so that loader can be removed cleanly
                        resolve();
                        finishSession(dispatch);
                        if (errorType) {
                            routeToBookingError(dispatch, widget, errorType);
                        } else if (success) {
                            routeToEwayPaymentSuccess(dispatch, widget, success);
                        }
                    });
            }
        });
    }

    /**
    * Handler for paying for a booking with a previously used card.
    * Only functions for eway preauth payments.
    */
    export const submitEwayPaymentExistingCard = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
        const { widget } = getState() as IRootState;
        const { appSettings } = widget;
        return new Promise(resolve => {
            PaymentService.finalizePreAuthEwayExistingCard({
                venueId: appSettings.venueId,
                bookingId: appSettings.bookingId,
                token: appSettings.tokenId
            })
                .then(() => {
                    resolve();
                    routeToPreauthSuccess(dispatch, widget);
                })
                .catch((error: { response: IErrorResponse }) => {
                    resolve();
                    //This API operation doesn't have specific error codes at this time
                    routeToBookingError(dispatch, widget, bookingErrorType.UKNOWN_USERPAYMENTERROR);
                }).finally(() =>{
                    finishSession(dispatch); //Complete the session and stop the payment countdown timer.
                });
        })
    }

    /**
     * Handler for paying for a booking with a previously used card.
     */
    export const submitStripePaymentExistingCard = () => (dispatch: Dispatch, getState: () => any): Promise<void> => {
        const { widget } = getState() as IRootState;
        const { appSettings } = widget;
        return new Promise(resolve => {
            PaymentService.finalizePreAuth3DExistingCard({
                venueId: appSettings.venueId,
                bookingId: appSettings.bookingId,
                token: appSettings.tokenId
            })
                .toPromise()
                .then(() => {
                    resolve();
                    finishSession(dispatch); //Complete the session and stop the payment countdown timer.
                    routeToPreauthSuccess(dispatch, widget);
                })
                .catch((error: { response: IErrorResponse }) => {
                    resolve();
                    //This API operation doesn't have specific error codes at this time
                    //There's no stripe error as this is a back-end only operation as we've contacted stripe servers previously.
                    const bookingError: IStripePaymentError = { stripeError: null, backEndError: error.response };
                    routeToStripePaymentError(dispatch, widget, bookingError);
                }).finally(() =>{
                    finishSession(dispatch); //Complete the session and stop the payment countdown timer.
                });
        })
    }

    //Type guard
    function isIStripePaymentError(response: IStripePaymentError | bookingErrorType): response is IStripePaymentError {
        const r = response as IStripePaymentError;
        if (r.stripeError) {
            return true;
        }
        return false;
    }

    //Type guard
    function isIStripePaymentSuccessData(response: ISavePreAuthResponseData | IStripePaymentSuccessData): response is IStripePaymentSuccessData {
        if ((response as IStripePaymentSuccessData).response) {
            return true;
        }
        return false;
    }

    function routeToPrepareEwayPaymentError(dispatch: Dispatch, widget: IWidgetModel, payload: IErrorResponse) {
        RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, widget.appSettings, widget.activeVenue).then(() => {
            dispatch({ type: BookingActionsTypes.PREPARE_EWAY_PAYMENT_FAIL, payload });
        });
    }

    function routeToStripePaymentSuccess(dispatch: Dispatch, widget: IWidgetModel, payload: IStripePaymentSuccessData) {
        RouteService.routeTo(ROUTE_NAMES.PAYMENT_COMPLETE, dispatch, widget.appSettings, widget.activeVenue)
            .then(() => {
                sendPaymentAnalytics(widget);
                dispatch({ type: BookingActionsTypes.STRIPE_PAYMENT_SUCCESS, payload });
            });
    }

    function routeToPreauthSuccess(dispatch: Dispatch, widget: IWidgetModel, payload: ISavePreAuthResponseData = {}) {
        RouteService.routeTo(ROUTE_NAMES.PAYMENT_COMPLETE, dispatch, widget.appSettings, widget.activeVenue)
            .then(() => {
                sendPaymentAnalytics(widget);
                dispatch({ type: BookingActionsTypes.PREAUTH_SUCCESS, payload })
            });
    }

    function routeToEwayPaymentSuccess(dispatch: Dispatch, widget: IWidgetModel, payload: IFunctionPaymentSummaryResponseData | IEwaySummaryResponseData) {
        RouteService.routeTo(ROUTE_NAMES.PAYMENT_COMPLETE, dispatch, widget.appSettings, widget.activeVenue)
            .then(() => {
                sendPaymentAnalytics(widget);
                dispatch({ type: BookingActionsTypes.EWAY_PAYMENT_SUCCESS, payload });
            });
    }

    function routeToBookingError(dispatch: Dispatch, widget: IWidgetModel, payload: bookingErrorType) {
        RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, widget.appSettings, widget.activeVenue)
            .then(() => {
                dispatch({ type: BookingActionsTypes.BOOKING_ERROR, payload });
            });
    }

    function routeToStripePaymentError(dispatch: Dispatch, widget: IWidgetModel, payload: IStripePaymentError) {
        RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, widget.appSettings, widget.activeVenue)
            .then(() => {
                dispatch({ type: BookingActionsTypes.STRIPE_PAYMENT_FAIL, payload });
            });
    }
}
