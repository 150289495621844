import React, { ReactElement } from 'react';
import style from './style.module.scss';
import { IStateFromProps, IDispatchFromProps } from './types';
import { Typography, Divider } from '@material-ui/core';
import { renderIf, UtilsService } from 'app/services/utils/utils.service';
import { noCase } from 'change-case';
import { IntlService } from 'app/services/intl/intlService';
import {servicePaymentType, themeTypes} from "shared-types/index";
import CustomizeDialog from 'app/components/CustomizeDialog/index';
import { Trans, useTranslation } from 'react-i18next';

const NS = 'PaymentMessage';

export default function PaymentMessage({
  paymentType, price, covers, currency, hasMenuOptions, bgColor, isPricePerPerson, dividePriceByCovers, preAuthorisationMessage, theme, originalPaymentDetails
}: IStateFromProps & IDispatchFromProps) {
  const { t } = useTranslation("payment");
  const isDark = theme.type === themeTypes.dark || theme.type === themeTypes.outlinedDark;

  const Price = ({calcPrice}: {calcPrice: number}): ReactElement => {
    return (
      <span className={isDark ? 'dark-text' : "secondary-text"}>
        {IntlService.currencyValue(calcPrice, currency)}
      </span>
    )
  }

  const fundsMessageBasedOnPriceChange = (currentPrice: number) => {
    if (originalPaymentDetails?.price === currentPrice) {
      return t('Funds will be verified again (with your previous verification cancelled), but not charged to your card at this time.');
    }
    return t('Funds will be verified, but not charged to your card at this time.');
  }

  const PRE_AUTH_TEXT = () => (
    <Typography variant="body1" data-testid="pre-auth-text">
      <Trans t={t}
        components={{
          price: <Price calcPrice={ hasMenuOptions || dividePriceByCovers || !isPricePerPerson? price : price * covers}/>
        }}
        defaults="A credit card Booking Guarantee of <price/> is required to finalise your booking."
      />
      {fundsMessageBasedOnPriceChange(hasMenuOptions || dividePriceByCovers || !isPricePerPerson ? price : price * covers)}
      {/*Change "View Booking Guarantee Policy" from open a new page to a popup--------*/}
      <CustomizeDialog 
        name={t("View Booking Guarantee Policy")} 
        message={preAuthorisationMessage}
        isTC={false}
      />
    </Typography>
  );

  const aPaymentType = t(`A ${noCase(paymentType)}`); // "A full payment" / "Un paiement intégral" etc.

  return (
    <div>
      {renderIf(!paymentType || paymentType === servicePaymentType.noPayment, () => (<></>), () => (
        renderIf(hasMenuOptions, () => (
          renderIf(paymentType === servicePaymentType.preAuth,
            // show preauth text
            PRE_AUTH_TEXT, () => (
            // else not preauth
            <Typography variant="body1">
              <Trans t={t}>
                {{ aPaymentType }} for each booking option is required.
              </Trans>
            </Typography>
          ))
        ), () => (
          renderIf(paymentType === servicePaymentType.functionPayment, () => (
            <Typography variant="body1" data-testid="function-text">
              <Trans t={t}
                defaults="A payment of <price/> is required to finalise your booking."
                components={{ price: <Price calcPrice={price} /> }}
              />
            </Typography>
          ), () => (
            renderIf(paymentType === servicePaymentType.preAuth, PRE_AUTH_TEXT, () => (
              (() => {
                const calcPrice: number = (isPricePerPerson && dividePriceByCovers) ? (price/covers) : price;
                return (
                  <Typography variant="body1">
                    { isPricePerPerson ? (
                      <Trans t={t}
                        defaults="{{aPaymentType}} of <price/> per person is required to finalise your booking."
                        values={{aPaymentType}}
                        components={{ price: <Price calcPrice={calcPrice} />}}
                      />
                    ) : (
                      <Trans t={t}
                        defaults="{{aPaymentType}} of <price/> is required to finalise your booking."
                        values={{aPaymentType}}
                        components={{ price: <Price calcPrice={calcPrice} />}}
                      />
                    )}
                  </Typography>
                );
              })()
            ))
          ))
        ))
      ))}
    </div>
  )
}
