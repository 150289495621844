import { connect } from 'react-redux';
import CustomerDetails from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';

const NS = 'CustomerDetailsContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {
  const {wrapperStyle, activeService, activeVenue, standbyData} = widget;

  const hasPolicyAg: boolean = !standbyData && activeService && activeService.policyAgreement === 'true';

  return {
    wrapperStyle,
    policyAgreementText: hasPolicyAg ? activeService.policyAgreementText : null,
    venueName: activeVenue ? activeVenue.name : null,
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {

  }
};

const CustomerDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails as any);

export default CustomerDetailsContainer;
