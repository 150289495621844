import { handleActions } from 'redux-actions';
import { getInitialWidgetState, RootState } from './state';
import {
  blockNavType,
  IAppQueryStringParams,
  IAppSettings,
  IVenue,
  IWidgetModel,
  IWidgetTheme,
  modeType,
  themeTypes,
} from 'app/models';
import { WidgetActionsNS } from 'app/actions/widget';
import { ThemeColorsService } from 'app/services/theme/themeColors.service';
import { SetupActionsNS } from 'app/actions/setup/setupActions';
import { IAppLoadedPayload, IVenueLoadedPayload } from "app/actions/setup/interfaces";
import { AccountService } from 'app/services/account/account.service';
import { sortBy } from 'lodash';
import { ISimpleVenues } from 'app/components/Venues/interfaces';
import { IActionGen, loadStatus } from 'app/types/common.types';
import { BookingService } from 'app/services/booking/booking.service';
import {
  bookingStatusType,
  IBooking,
  IBookingMenuOption,
  IBookingPayment,
  IBookingResponseData,
  ICoversOrServiceChange
} from 'app/services/booking/booking.types';
import moment, { Moment } from 'moment';
import { IPaymentType } from 'app/services/client/client.types';
import { ErrorService } from 'app/services/error/error.service';
import { MockActionsNS } from 'app/actions/mocks';
import {
  IBookingErrorMinimal,
} from 'app/services/error/error.types';
import { TimeFilterService } from 'app/services/timeFilter/timeFilter.service';
import {
  IBookingErrorAction,
  ICancelBookingFail,
  IChangedActiveServiceUpdate,
  IChangedBookingTime,
  IChangedCalenderView,
  IChangedUpsellPresent,
  IConfirmBookingSuccess,
  IEwayPaymentSuccess,
  IEwayPreAuthSuccess,
  IManageBookingFail,
  IPaymentSummaryFail,
  IPrepareEwayPaymentFail,
  IPrepareEwayPaymentSuccess,
  IStripeLoaded,
  IStripePaymentFail,
  IStripePaymentSuccess
} from 'app/actions/booking/interfaces';
import { IWidgetPalette } from 'app/services/theme/theme.types';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import { ROUTE_NAMES, STEP_ROUTES } from 'app/services/route/route.types';
import { UtilsService } from 'app/services/utils/utils.service';
import { MocksService } from 'app/services/mocks/mocks.service';
import { ISessionTime } from "app/services/session/session.types";
import changedCoversCount from './widget/changedCoversCount/index';
import serviceScheduleSuccess from './widget/serviceScheduleSuccess/index';
import changedCustomerDetails from './widget/changedCustomerDetails/index';
import changedActiveSection from './widget/changedActiveSection/index';
import changedStandbyMode from './widget/changedStandbyMode/index';
import selectedStandbyTime from './widget/selectedStandbyTime/index';
import bookingOptions from './widget/bookingOptions/index';
import promoCode from './widget/promoCode/index';
import gaw from './widget/gaw/index';
import {StandbyActionsNS} from "app/actions/standby/standbyActions";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {
  IScheduleTime,
  IScheduleService,
  bookingErrorType,
  IErrorResponse,
  bookingErrorMessageType,
  IBookedByCustomerDetail
} from "shared-types/index";
import appValues from "app/constants/appValues";
import { SectionsAvailableService } from "app/services/sectionsAvailable/sectionsAvailable.service";
import { MessageService } from 'app/services/message/message.service';

const NS = 'WidgetReducer';


const getVenueIdAsNum = (_venueId: string): number => {
  const venueId: number = parseInt(_venueId, 10);
  return isNaN(venueId) ? null : venueId;
}

/**
 * @todo: look into using https://redux-toolkit.js.org/api/createReducer for easy immutable updates
 */
export const widgetReducer = handleActions<RootState.WidgetState, any>(
  {
    /**
     * SETUP ACTIONS
     */

    /**
     * Gets called just before APP_LOAD_SUCCESS because some error messaging requires `activeVenue` to be set
     */
    [SetupActionsNS.Type.VENUE_LOAD_SUCCESS]: (state, action): IWidgetModel => {
      const payload = action.payload as IVenueLoadedPayload;
      const appSettings = action.payload.appSettings;

      const isStyleGuide = appSettings.isStyleGuide && !payload.overrideStyleGuide;
      const isPreviewMode = appSettings.mode === modeType.preview;
      const venues: ISimpleVenues = isStyleGuide || isPreviewMode ? null : {
        ...state.venues,
        selectedVenueId: appSettings.venueId
      }

      const accountDetails = payload.accountDetails;

      if (!isStyleGuide && !isPreviewMode && accountDetails) {
        accountDetails.ownedVenues = sortBy(accountDetails.ownedVenues, 'name');
        venues.venuesList = accountDetails.ownedVenues;
      }

      const activeVenue: IVenue = isStyleGuide || isPreviewMode
        ? MocksService.getVenue(1, 'mock-venue') // used for style guide
        : AccountService.getVenue(accountDetails, appSettings.venueId) as IVenue;

      return {
        ...state,
        appSettings,
        accountDetails,
        venues,
        activeVenue
      }
    },


    [SetupActionsNS.Type.VENUE_READY]: (state, action): IWidgetModel => {
      /**
       * NBI-8205: only gets called if a venue has been either specified in the url params, or after a venue has been
       * selected in the venue selector (when no venueId exists in url params).
       */

      const booking: IBooking = state.booking;
      booking.isPerBookingSmsOptIn = state.activeVenue.widgetSettings.defaultPerBookingSmsOptInCheckedState;

      return {
        ...state,
        booking
      }
    },


    [SetupActionsNS.Type.SET_APP_THEME]: (state, action: IActionGen<IAppQueryStringParams>): IWidgetModel => {
      const params: IAppQueryStringParams = action.payload;
      let theme = state.theme;

      if (params) {
        const themeType = params.theme as themeTypes;
        const {palette, defaultColors} = ThemeColorsService.getColorsFromQueryString(params.colors || params.accent, theme.palette, themeType);
        theme.palette = palette as IWidgetPalette;
        theme.defaultColors = defaultColors;

        theme = ThemeColorsService.setThemeValues(themeType, theme);
      }

      return {
        ...state,
        theme: {
          ...theme
        },
      }
    },


    [SetupActionsNS.Type.APP_LOAD_SUCCESS]: (state, action): IWidgetModel => {

      const payload = action.payload as IAppLoadedPayload;
      const appSettings: IAppSettings = payload.appSettings;
      const booking: IBooking = state.booking;

      booking.isGaw = false;
      if (appSettings.time) { // only if time is present in the URL that GAW should be true
        booking.isGaw = true;
        BookingService.addUrlParamsToBookingForGAW(booking, appSettings);
      }

      if (appSettings.date) {
        BookingService.addDateParamToBooking(booking, appSettings.date);
      }

      const maxPeoplePerBooking = state.activeService ? BookingService.getMaxPeoplePerBooking(state.activeVenue as IVenue, state.activeService): appValues.MAX_PEOPLE_PER_BOOKING;
      appSettings.covers = UtilsService.rangeCheck(appSettings.covers, maxPeoplePerBooking, 0);

      if (BookingService.isStandardEvent(appSettings)) {
        BookingService.addUrlParamsToBooking(booking, appSettings);
      }

      return {
        ...state,
        appSettings,
        booking
      }
    },

    [SetupActionsNS.Type.LOADED_PRIVATE_FUNCTION_PAYMENT_SUCCESS]: (state, action: SetupActionsNS.ILoadPrivateFunctionSuccess): IWidgetModel => {
      return {
        ...state,
        booking: BookingService.getBookingObj(action.payload)
      }
    },

    [SetupActionsNS.Type.LOADED_BOOKING]: (state, action: SetupActionsNS.IBookingLoadedSuccess): IWidgetModel => {

      const booking: IBooking = BookingService.getBookingObj(action.payload);

      // Setting needed to change payment per person message
      booking.isFromDiary = (action.payload?.method === "online" ? false : true);

      return {
        ...state,
        booking,
        savedBooking: BookingService.getSavedBookingObj(action.payload),
      }
    },

    [SetupActionsNS.Type.STANDBY_HAD_PAYMENT]: (state: IWidgetModel): IWidgetModel => {

      const bookingError = ErrorService.getBookingErrorFromType(bookingErrorType.STANDBY_HAD_PAYMENT, state.activeVenue as IVenue);

      return {
        ...state,
        bookingError,
      }
    },

    /**
     * MOCK ACTIONS
     */

    [MockActionsNS.Type.UPDATE_APP_SETTINGS]: (state, action: MockActionsNS.IUpdateAppSettings): IWidgetModel => {

      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          ...action.payload
        }
      }
    },

    /**
     * WIDGET ACTIONS
     */

    [WidgetActionsNS.Type.CHANGED_THEME_COLORS]: (state, action): IWidgetModel => {
      const payload = action.payload as string;
      let theme = state.theme;

      const {palette, defaultColors} = ThemeColorsService.getColorsFromQueryString(payload, theme.palette, theme.type);
      theme.palette = palette as IWidgetPalette;
      theme.defaultColors = defaultColors;

      // outlined themes need to change colors manually, so needs to run this after colors changed
      if (theme.type === themeTypes.outlinedLight || theme.type === themeTypes.outlinedDark) {
        theme = ThemeColorsService.setThemeValues(theme.type, theme);
      }
      return {
        ...state,
        theme: {
          ...theme
        }
      };
    },

    [WidgetActionsNS.Type.CHANGED_THEME_STYLE]: (state, action): IWidgetModel => {
      const themeType = action.payload as themeTypes;
      let theme = state.theme;
      if (themeType) {

        /**
         * If the `themeType` is outlined (light or dark) and `defaultColors` are set to true, then we need to update the colors
         * as well.
         */
        if (theme.defaultColors && (themeType === themeTypes.outlinedLight || themeType === themeTypes.outlinedDark)) {
          theme.palette = ThemeColorsService.getColorsFromQueryString(null, theme.palette, themeType).palette as IWidgetPalette;
        }
        theme = ThemeColorsService.setThemeValues(themeType, theme);
      }
      return {
        ...state,
        theme: {
          ...theme
        }
      };
    },

    /**
     * Note: not currently handling 'CHANGED_FONT_FAILED' because this shouldn't
     * prevent the app from loading
     */
    [WidgetActionsNS.Type.CHANGED_FONT_LOADED]: (state, action): IWidgetModel => {
      const fontFamilyName = action.payload as string;
      const theme: IWidgetTheme = state.theme;

      theme.typography = {
        ...theme.typography,
        fontFamily: [
          `"${fontFamilyName}"`,
          '"Helvetica"',
          '"Arial"',
          'sans-serif'
        ].join(',')
      };

      return {
        ...state,
        theme: {
          ...theme
        }
      }
    },

    [WidgetActionsNS.Type.CHANGED_SIZE]: (state, action): IWidgetModel => {
      return {
        ...state,
        wrapperStyle: action.payload
      };
    },

    /**
     * BOOKING ACTIONS
     */

    ...changedCoversCount,
    ...serviceScheduleSuccess,
    ...changedCustomerDetails,
    ...changedActiveSection,
    ...promoCode,
    ...gaw,
    ...bookingOptions,

    [BookingActionsTypes.SERVICE_SCHEDULE_LOADING]: (state): IWidgetModel => {
      return {
        ...state,
        schedule: null,
        scheduleLoadStatus: loadStatus.loading
      }
    },

    [BookingActionsTypes.SERVICE_SCHEDULE_FAILED]: (state, action: IActionGen<loadStatus>): IWidgetModel => {
      return {
        ...state,
        schedule: null,
        blockNav: blockNavType.becauseScheduleError,
        scheduleLoadStatus: action.payload // should be either loadStatus.failed or loadStatus.failedTemp
      }
    },

    [BookingActionsTypes.CHANGED_ACTIVE_SERVICE]: (state, action: IActionGen<IChangedActiveServiceUpdate>): IWidgetModel => {
      const activeService = state.schedule.services.find(s => s.id === action.payload.serviceId);

      const sections: ICoversOrServiceChange = SectionsAvailableService.getSectionsOnCoversOrServiceChange(
        activeService,
        state.activeVenue as IVenue,
        state.booking,
        state.activeSection,
        state.manuallyChosenSectionId
      );

      let {booking} = sections;
      const {filteredSections, activeSection} = sections;

      const selectedMenuOptions = BookingService.correctSelectedMenuOptions({
        activeService, booking
      })
      booking = {
        ...booking,
        selectedMenuOptions,
        viewTime: null,
        utcTime: null,
        serviceIds: []
      }

      if (action.payload.clearSelectionMenuOptions) {
        booking.selectedMenuOptions = [];
      }
      const needToReloadSchedule = activeService.minPaxPerBooking && booking.covers < activeService.minPaxPerBooking;
      const filteredTimes = activeService && !needToReloadSchedule
        ? TimeFilterService.getFilteredTimesAndMutateOriginal(activeService.times, true, activeSection, booking.utcTime)
        : null;

      return {
        ...state,
        activeService,
        activeSection,
        isTimeViewShown: !!activeService,
        booking,
        filteredSections,
        filteredTimes,
        timeError: null,
        triedNext: null,
        blockNav: null,
        standbyData: null
      }
    },


    [BookingActionsTypes.CHANGED_BOOKING_DATE]: (state, action: IActionGen<Moment>): IWidgetModel => {
      const _moment: Moment = action.payload;

      const clearTime = !_moment;

      const booking: IBooking = {
        ...state.booking,
        moment: _moment,
        viewDate: BookingService.getViewDateFromMoment(_moment || moment()), // sets day to today
        utcTime: clearTime ? null : _moment.format(),
        viewTime: null
      }

      // clears selected time
      let filteredTimes = state.filteredTimes;
      if (filteredTimes && clearTime) {
        filteredTimes = filteredTimes.slice();
        filteredTimes.forEach(t => t.isSelected = false);
      }

      return {
        ...state,
        editBookingDisabled: false,
        booking,
        timeError: null,
        triedNext: clearTime ? ROUTE_NAMES.SITTING : null, // triggers validation errorPanel in time selector
        blockNav: null,
        filteredTimes,
        standbyData: null
      }
    },

    [BookingActionsTypes.CHANGED_BOOKING_TIME]: (state, action: IChangedBookingTime): IWidgetModel => {
      const utcTime: string = action.payload;
      const selectedTime: IScheduleTime = utcTime && state.filteredTimes ? state.filteredTimes.find(t => t.time === utcTime && !t.isDisabled && !t.isBlocked) : null;

      const filteredTimes = state.filteredTimes.slice();
      if (filteredTimes) {
        if (selectedTime) {
          filteredTimes.forEach(t => {
            t.isSelected = t.time === selectedTime.time;
          });
        } else {
          filteredTimes.forEach(t => {
            t.isSelected = t.time === utcTime
          })
        }
      }

      const booking: IBooking = {
        ...state.booking,
        viewTime: selectedTime
          ? moment(selectedTime.time).format('h:mma')
          : null,
        utcTime
      };

      return {
        ...state,
        editBookingDisabled: false,
        booking,
        filteredTimes,
        timeError: null,
        triedNext: null,
        blockNav: null,
        standbyData: null
      }
    },

    [BookingActionsTypes.CHANGED_CALENDER_VIEW]: (state, action: IChangedCalenderView): IWidgetModel => {
      const isTimeViewShown: boolean = action.payload;

      return {
        ...state,
        isTimeViewShown
      }
    },

    [BookingActionsTypes.IS_UPSELL_PRESENT]: (state, action: IChangedUpsellPresent): IWidgetModel => {
      const isUpsellPresent: boolean = action.payload;

      return {
        ...state,
        isUpsellPresent
      }
    },

    [BookingActionsTypes.ORIGINAL_PAYMENT_DETAILS]: (state, action: IActionGen<IBookingPayment>): IWidgetModel => {
      return {
        ...state,
        originalPaymentDetails: action.payload
      }
    },


    [BookingActionsTypes.CONFIRM_BOOKING_SUCCESS]: (state, action: IConfirmBookingSuccess): IWidgetModel => {

      return {
        ...state,
        booking: action.payload ? BookingService.updateBookingValues(state.booking, action.payload) : state.booking
      }
    },

    [BookingActionsTypes.CONFIRM_BOOKING_FAIL]: (state, action: IActionGen<IErrorResponse>): IWidgetModel => {

      return {
        ...state,
        bookingError: ErrorService.getBookingErrorFromStatus(action.payload.status, state.activeVenue as IVenue)
      }
    },

    [BookingActionsTypes.TIME_EXPIRED]: (state, action: IActionGen<string>): IWidgetModel => {

      return {
        ...state,
        bookingError: {
          heading: 'Booking Time Expired',
          messageType: bookingErrorMessageType.timeNoLongerAvailableMessage,
          name: bookingErrorType.timeExpired,
          message: MessageService.getMessage(state.activeVenue.widgetSettings.timeNoLongerAvailableMessage, state.activeVenue, null, state.booking),
          buttonText: 'Book again?'
        }
      }
    },


    [BookingActionsTypes.EDIT_BOOKING_FAIL]: (state, action: IManageBookingFail): IWidgetModel => {
      return {
        ...state,
        manageBookingErrorMessage: action.payload
      }
    },

    [BookingActionsTypes.SAVE_BOOKING_SUCCESS]: (state, action: IActionGen<IBookingResponseData>): IWidgetModel => {

      const booking: IBooking = {
        ...state.booking,
        _id: action.payload._id, // needed for getLatestBookingPaymentDetails
        customer: {
          ...state.booking.customer,
          _id: action.payload.customer._id
        },
        payment: action.payload.paymentPending,
        serviceName: action.payload.serviceName
      };

      const appSettings: IAppSettings = {
        ...state.appSettings,
        bookingId: action.payload._id,
        canDeleteBooking: true // needed for cancel payment button
      }

      return {
        ...state,
        booking,
        appSettings,
        // appLoadStatus: loadStatus.success,
        bookingError: null
      }
    },

    [BookingActionsTypes.SAVE_BOOKING_FAIL]: (state, action: IActionGen<IErrorResponse>): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: action.payload
          ? ErrorService.getBookingErrorFromStatus(action.payload.status, state.activeVenue as IVenue, state.activeService.paymentDetails.paymentType)
          : {
            heading: '',
            messageType: null,
            name: null,
            message: '',
            buttonText: ''
          }
      }
    },

    [BookingActionsTypes.CANCEL_BOOKING_SUCCESS]: (state, action: IActionGen<boolean>): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: null,
        bookingCancelled: {
          isCancelled: true,
        },
        booking: {
          ...state.booking,
          status: bookingStatusType.cancelled,
        },
      }
    },

    // @todo: check this is needed, as it never gets called. severity: low
    [BookingActionsTypes.BOOKING_EDIT_EXPIRED]: (state): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: null,
        bookingCancelled: {
          isCancelled: true,
        }
      }
    },

    [BookingActionsTypes.CANCEL_BOOKING_FAIL]: (state, action: ICancelBookingFail): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: ErrorService.getBookingErrorFromType(action.payload, state.activeVenue as IVenue)
      }
    },

    // @todo: check this is needed, as it never gets called. severity: low
    [BookingActionsTypes.PREPARE_EWAY_PAYMENT_SUCCESS]: (state, action: IPrepareEwayPaymentSuccess): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: null
      }
    },

    [BookingActionsTypes.DELETE_BOOKING_SUCCESS]: (state): IWidgetModel => {
      return {
        ...state,
        booking: {
          ...state.booking,
          status: bookingStatusType.cancelled,
        },
        // appLoadStatus: loadStatus.success,
        bookingError: null
      }
    },
    [BookingActionsTypes.UPDATE_BOOKEDBY]: (state, action: IActionGen<IBookedByCustomerDetail>): IWidgetModel => {
      return {
        ...state,
        booking: {
          ...state.booking,
          bookedBy: {
            ...state.booking.bookedBy,
            ...action.payload
          },
        },
      } as IWidgetModel
    },

    [BookingActionsTypes.IS_BOOKEDBY]: (state, action: IActionGen<boolean>): IWidgetModel => {
      return {
        ...state,
        booking: {
          ...state.booking,
          isBookedBy: action.payload,
        },
      } as IWidgetModel
    },

    [BookingActionsTypes.IS_SMSOPTIN]: (state, action: IActionGen<boolean>): IWidgetModel => {
      return {
        ...state,
        booking: {
          ...state.booking,
          isPerBookingSmsOptIn: action.payload,
        },
      } as IWidgetModel
    },

    [BookingActionsTypes.HAVE_ACCEPTED_VERIFICATION]: (state: IWidgetModel): IWidgetModel => {
      return {
        ...state,
        haveAcceptedVerification: true
      }
    },

    [BookingActionsTypes.HAVE_MINIMUM_PAX]: (state: IWidgetModel, action:IActionGen<boolean>): IWidgetModel => {
      return {
        ...state,
        hasMinimumPax: action.payload
      }
    },

    [BookingActionsTypes.PREPARE_EWAY_PAYMENT_FAIL]: (state, action: IPrepareEwayPaymentFail): IWidgetModel => {

      const {status, data} = action.payload;
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: ErrorService.getPaymentErrorFromResponse(status, data, state.activeVenue as IVenue)
      }
    },

    [BookingActionsTypes.EWAY_PAYMENT_SUCCESS]: (state, action: IEwayPaymentSuccess): IWidgetModel => {

      const payment: IBookingPayment = {
        ...state.booking.payment,
        transactionId: action.payload.transactionId,
        amountPaid: action.payload.amountPaid
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          payment
        },
        // appLoadStatus: loadStatus.success,
        bookingError: null
      }
    },

    [BookingActionsTypes.BOOKING_ERROR]: (state, action: IBookingErrorAction): IWidgetModel => {

      return {
        ...state,
        bookingError: ErrorService.getBookingErrorFromType(action.payload, state.activeVenue as IVenue)
      }
    },


    [BookingActionsTypes.PREAUTH_SUCCESS]: (state, action: IEwayPreAuthSuccess): IWidgetModel => {

      const payment: IBookingPayment = state.booking.payment;
      if (action.payload.amountPaid) {
        payment.amountPaid = action.payload.amountPaid;
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          payment
        },
        // appLoadStatus: loadStatus.success,
        bookingError: null
      }
    },

    [BookingActionsTypes.STRIPE_PAYMENT_SUCCESS]: (state, action: IStripePaymentSuccess): IWidgetModel => {

      const payment: IBookingPayment = {
        ...state.booking.payment,
        transactionId: action.payload.transactionId,
        amountPaid: action.payload.amountPaid
      }

      return {
        ...state,
        booking: {
          ...state.booking,
          payment
        },
        bookingError: null
      }
    },

    [BookingActionsTypes.STRIPE_PAYMENT_FAIL]: (state, action: IStripePaymentFail): IWidgetModel => {
      const resp = action.payload.backEndError;
      const status = resp ? resp.status : null;
      const data = resp ? resp.data : null;
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: action.payload.stripeError
          ? {
            heading: 'Payment Error',
            message: action.payload.stripeError.message,
            buttonText: 'Try Again',
            messageType: bookingErrorMessageType.paymentError,
            name: bookingErrorType.paymentError
          } as IBookingErrorMinimal
          : ErrorService.getPaymentErrorFromResponse(status, data, state.activeVenue as IVenue)
      }
    },

    [BookingActionsTypes.PAYMENT_SUMMARY_FAIL]: (state, action: IPaymentSummaryFail): IWidgetModel => {

      const {status, data} = action.payload;
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: ErrorService.getPaymentErrorFromResponse(status, data, state.activeVenue as IVenue)
      }
    },

    [BookingActionsTypes.STRIPE_LOADED]: (state, action: IStripeLoaded): IWidgetModel => {
      return {
        ...state,
        stripe: action.payload
      }
    },

    [BookingActionsTypes.SESSION_REMAINING_TIME_UPDATED]: (state, action: IActionGen<ISessionTime>): IWidgetModel => {

      return {
        ...state,
        sessionRemainingTime: action.payload.isActive ? action.payload.viewTime : null
      }
    },

    [BookingActionsTypes.SESSION_FINISHED]: (state, action: IActionGen<bookingErrorType>): IWidgetModel => {

      return {
        ...state,
        sessionRemainingTime: null,
        bookingError: action.payload ? ErrorService.getBookingErrorFromType(action.payload, state.activeVenue as IVenue) : null
      }
    },

    /**
     * STANDBY ACTIONS
     */

    ...changedStandbyMode,
    ...selectedStandbyTime,


    [StandbyActionsNS.Type.SAVE_TO_STANDBY_LIST_FAIL]: (state, action: IActionGen<IBookingErrorMinimal>): IWidgetModel => {
      return {
        ...state,
        // appLoadStatus: loadStatus.success,
        bookingError: action.payload
      }
    },

    /**
     * NAVIGATION ACTIONS
     */

    [NavigationActionsNS.Type.CHANGED_VENUE]: (state, action): IWidgetModel => {

      const venueId: number = getVenueIdAsNum(action.payload);
      const activeVenue: IVenue = AccountService.getVenue(state.accountDetails, venueId) as IVenue;

      /**
       * For some reason making a copy of appSettings causes a full rerender of Venues component, which resets the scroll
       * position of list to the top, which is slightly annoying. But just modify the existing is all ok.
       */
      const appSettings = state.appSettings;
      appSettings.venueId = venueId;

      return {
        ...state,
        appSettings,
        venues: {
          ...state.venues,
          selectedVenueId: venueId
        },
        activeVenue,
        triedNext: null
      }
    },

    [NavigationActionsNS.Type.TRIED_NEXT]: (state, action: IActionGen<string>): IWidgetModel => {
      const triedNext = STEP_ROUTES.find(n => n === action.payload);
      const isCustomerForm = triedNext === ROUTE_NAMES.CUSTOMER_DETAILS
      return {
        ...state,
        triedNext,
        forcedValidate: isCustomerForm ? (state.forcedValidate || 0) + 1 : 0,
        // if there is an active service and "next" is attempted, should always show time view, as there could be a validation message
        isTimeViewShown: triedNext === ROUTE_NAMES.SITTING && !!state.activeService
      }
    },

    [NavigationActionsNS.Type.UPDATED_PAYMENT_TYPE]: (state, action: IActionGen<IPaymentType>): IWidgetModel => {

      const payload = action.payload;
      const activeService: IScheduleService = {
        ...state.activeService,
        paymentDetails: {
          ...state.activeService.paymentDetails,
          price: payload ? payload.amount : null,
          paymentType: payload ? payload.paymentTypeName : null
        }
      };
      return {
        ...state,
        activeService
      }
    },

    [NavigationActionsNS.Type.UPDATED_BOOKING_PAYMENT_DETAILS]: (state, action: IActionGen<IPaymentType>): IWidgetModel => {

      const payload = action.payload;
      const booking: IBooking = {
        ...state.booking,
        payment: {
          ...state.booking.payment,
          price: payload ? payload.amount : null,
          paymentType: payload ? payload.paymentTypeName : null,
          amountDue: payload ? payload.amountDue : null,
          discountAmount: payload ? payload.discountAmount : null
        }
      };
      return {
        ...state,
        booking
      }
    },

    [NavigationActionsNS.Type.BLOCK_NAV_BECAUSE_OF_PAYMENT]: (state, action: IActionGen<boolean>): IWidgetModel => {

      return {
        ...state,
        blockNav: action.payload ? blockNavType.becauseOfPayment : null
      }
    },

    [NavigationActionsNS.Type.BLOCK_NAV_BECAUSE_OF_COVERS_PENDING]: (state): IWidgetModel => {
      let blockNav = state.blockNav;
      if (!blockNav) {
        blockNav = blockNavType.becauseOfCoversPending;
      }
      return {
        ...state,
        blockNav
      }
    },

    [BookingActionsTypes.HIDE_UPSELL_POPUP]: (state, action: IActionGen<{ hideUpsell: boolean }>): IWidgetModel => {
      return {
        ...state,
        hideUpsell: action.payload.hideUpsell
      }
    },

    [BookingActionsTypes.SEGREGATE_SELECTED_MENU_OPTION_UPSELL]: (state, action: IActionGen<{ selectedUpsellOptions: IBookingMenuOption[], updatedSelectedMenuOption: IBookingMenuOption[] }>): IWidgetModel => {

      const booking: IBooking = {
        ...state.booking,
        selectedMenuOptions: action.payload.updatedSelectedMenuOption,
        selectedUpsellOptions: action.payload.selectedUpsellOptions
      };

      return {
        ...state,
        booking,
      }
    },

    [BookingActionsTypes.SHOW_PAYMENT_MISMATCH_ERROR]: (state, action: IActionGen<string>): IWidgetModel => {

      return {
        ...state,
        bookingError: {
          heading: 'Payment Error',
          messageType: bookingErrorMessageType.paymentError,
          name: bookingErrorType.paymentError,
          message: `${action.payload}`,
          buttonText: ''
        }
      }
    },

    [BookingActionsTypes.SET_PREVIOUS_SB_STATE]: (state, action: IActionGen<boolean>): IWidgetModel => {
      const {
        utcTime, isFlexibleTime
      } = state.standbyData;

      const restrictDate: string = isFlexibleTime ? state.activeService.times[0].time : utcTime

      const standbyData = {
        ...state.standbyData,
        restrictCustomer: state.standbyData.customer,
        restrictDate: restrictDate,
      }

      return {
        ...state,
        isDetectedPreviousSB: action.payload,
        standbyData: standbyData,
      }
    },
  },

  getInitialWidgetState()
);
