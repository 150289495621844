import { combineReducers } from 'redux';
import { RootState, IRootState } from './state';
import { widgetReducer as widget } from './widget';
import { navigationReducer as navigation } from './navigation';
import { setupReducer as setup } from './setup';

export { RootState, IRootState };

export const rootReducer = combineReducers<IRootState>({
  widget,
  navigation,
  setup,
});
