import React, { ComponentType } from 'react';
import { IPolicyAggreemment } from './types';
import style from './style.module.scss';
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogContentText, Button, DialogActions, Typography } from '@material-ui/core';
import appValues from 'app/constants/appValues';
import { wrapperStyleType } from 'app/models';
import { withStyles } from '@material-ui/styles';
import { UtilsService } from 'app/services/utils/utils.service';
import { Trans, useTranslation } from 'react-i18next';

// Note: import didn't work due to missing type definitions
const ReactMarkdown = require('react-markdown/with-html');

const NS = 'PolicyAggreemment';


export default function PolicyAggreemment({wrapperStyle, venueName, doShow, policyAgreementText, handleClose}: IPolicyAggreemment) {
  const { t } = useTranslation("customer");

  const Dialog: ComponentType<DialogProps> = withStyles(theme => {
    const { palette: { type, common } } = theme
    return {
      root: {
        "& .MuiPaper-root": {
          backgroundColor: type === 'light' ? common.white : common.black,
        }
      },
      paper: {
        minWidth: (() => {
          switch (wrapperStyle) {
            case wrapperStyleType.standard:
              return `${appValues.STACKED_BP - 20}px`;

            case wrapperStyleType.wide:
              return `600px`;
          }

          return null;
        })()
      }
  }})(MuiDialog);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paper: style.paper,
      }}
      maxWidth="md"
      open={doShow}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          classes={{
            root: style.title
          }}>
          <Typography variant="h2">
            <Trans t={t}>
              {{venueName}} Terms &amp; Conditions
            </Trans>
          </Typography>
        </DialogTitle>

        <DialogContent
          classes={{
            root: style.content
          }} >
          <DialogContentText id="alert-dialog-description" component="div" className={style.markdownText}>
            <ReactMarkdown
              source={policyAgreementText}
              renderers={{
                link: UtilsService.reactMarkDownBlankTargets
              }}
              escapeHtml={false}
              />
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{
          root: style.btnWrap
        }} >
          <Button onClick={handleClose} color="primary" autoFocus variant="contained" className={[style.btn, 'error-bg'].join(' ')}>
            <Trans t={t}>
              I ACCEPT THE TERMS&nbsp;AND&nbsp;CONDITIONS
            </Trans>
          </Button>
        </DialogActions>
      </Dialog>
  )
}
