import React from 'react';
import { IJoinStandbyButton } from './types';
import {makeStyles, Typography, withStyles} from "@material-ui/core";
import MuiChip from "@material-ui/core/Chip";
import { Trans, useTranslation } from 'react-i18next';

const NS = 'JoinStandbyButton';

const useStyles = makeStyles({
  standbyMsg: {
    padding: '10px 0 15px'
  },
  message: {
    display: 'inline-block',
    marginBottom: 5
  }
});

const StyleChip = withStyles({
  root: {
    color: '#fff',
    borderColor: 'white',
    lineHeight: 1,
    height: 31,
    backgroundColor: '#4669ac',
    '&:hover, &:focus': {
      backgroundColor: '#38558d !important'
    }
  }
})(MuiChip);

export default function JoinStandbyButton({handleClicked}: IJoinStandbyButton) {
  const { t } = useTranslation("standby");
  const classes = useStyles();

  return (
    <div
      data-testid="standby-message"
      data-e2e="standby-message"
      className={classes.standbyMsg} >
      <Typography variant="body1" component="div">
        <span className={classes.message}>
          <Trans t={t}>
            Can’t see a preferred time?
          </Trans>
        </span>
        &nbsp;&nbsp;
        <StyleChip
          data-testid="standby-button"
          label={t("Join Standby")}
          aria-label={t("Join Standby")}
          clickable
          size="medium"
          variant={'outlined'}
          onClick={handleClicked}
        />
      </Typography>
    </div>
  )
}
