import { bookingErrorMessageType, bookingErrorType } from "shared-types/index";

export interface IErrorResponseData {
  message: string; // comma separated string of errorCodes (eg V6101,V6106,V6110)
}

export enum bookingSuccessType {
  bookingConfirmed = 'bookingConfirmed'
}

export interface IBookingErrorMinimal {
  heading: string;
  messageType: bookingErrorMessageType;
  name: bookingErrorType;
  message: string;
  buttonText: string;
}

export interface IBookingError extends IBookingErrorMinimal {
  data: string;
  status: number;
}

export interface IEwayErrorCode {
  code: string;
  description: string;
}