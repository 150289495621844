// remove this when you no longer need to support ie11
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {AppLoadService} from 'app/services/appLoad.service';
import ReduxStoreContainer from './containers/App/ReduxStoreContainer';

const NS = 'main';

// Hot Module Replacement
declare let module: { hot: any };

// I dont understand the logic here. the code in the logic expression already performed redirect.
// so it will always return false ... (faceplam)
if (!AppLoadService.redirectOnNoAccountId()) {

  ReactDOM.render(
    <ReduxStoreContainer />,
    document.getElementById('root')
  );

  /**
   * Hot Module Replacement is broken, so removed all implementations, but if we ever need them back
   * see below commented out code.
   */
  // if (module.hot) {
  //   module.hot.accept("./containers/App/ReduxStoreContainer", () => {
  //     const NewContainer = require("./containers/App/ReduxStoreContainer").default;
  //
  //     ReactDOM.render(
  //       <NewContainer />,
  //       document.getElementById("root"));
  //   });
  // }

}

