import { WithTranslation } from "react-i18next";
import { IWidgetTheme, wrapperStyleType } from "shared-types/index";

export interface ICoverInput extends WithTranslation {
  wrapperStyle: wrapperStyleType;
  theme: IWidgetTheme;
  coversPrefill?: number;
  maxPeoplePerBooking: number;
  minPeoplePerBooking: number;
  variant: coverInputVariant;
  omitMaxReachedMsg?: boolean;
  hasDelay: boolean;
  allowMaxBreach: boolean;
  minPaxPerBookingMessage?:  string;
  getMaxBreachMessage?: (maxPeoplePerBooking: number) => string;
  handleChange: (coversCount: number, isShowingMessage: boolean) => void;
  handlePendingChange?: () => void;
}

export enum coverInputVariant {
  large = 'large',
  small = 'small',
}

export const classPrefix = 'CoverInput';
