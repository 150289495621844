import React from 'react';
import style from './style.module.scss';
import {IStateFromProps, IDispatchFromProps} from './types';
import {Grid, SimplePaletteColorOptions, Typography} from "@material-ui/core";
import GawVenueList from "app/components/GroupAvailabilityWidget/GawVenueList";
import {renderIf} from "app/services/utils/utils.service";
import {BookingService} from "app/services/booking/booking.service";
import {flatten, isEmpty, uniqBy} from 'lodash';
import {makeStyles} from "@material-ui/core/styles";
import {IScheduleTime} from "shared-types/index";
import { useTranslation, Trans } from "react-i18next";

const NS = 'GroupAvailabilityWidget';

const useStyles = makeStyles((theme) => ({
  venueName: {
    fontWeight: 200,
    fontSize: '1.7rem',
    marginBottom: '3px'
  },
  venueInfo: {
    marginTop: '15px',
    marginBottom: '5px'
  },
  venueUnderline: {
    height: '3px',
    margin: '0 -3px 3px',
    backgroundColor: (theme.palette.primary as SimplePaletteColorOptions).main,
  },
  venueNameWrap: {
    marginBottom: '5px',
    width: '80%'
  },
  noAvailability: {
    textAlign: "center",
    fontStyle: "italic"
  },
}));

export default function GroupAvailabilityWidget({allVenueSchedule, selectedTimeForOtherVenues, venueList, handleTimeSelection, allVenueApiCall}: IStateFromProps & IDispatchFromProps) {
  const { t } = useTranslation("sitting");
  const allTimes: IScheduleTime[][] = [];
  const classes = useStyles();

  return (
    <div data-testid="container">
      {selectedTimeForOtherVenues && allVenueSchedule.map((schedule, index) => {
        const venue = venueList && venueList.find((venue => venue.id === schedule.venueId));
        const filteredTimes = BookingService.getFilteredTimesForVenue(schedule.services, selectedTimeForOtherVenues);
        allTimes.push(filteredTimes);
        return (
          <div key={index} data-testid="map-list-container">
            {renderIf(!isEmpty(filteredTimes), () => (
              <Grid container className={classes.venueInfo} data-testid="filtered-times-container">
                {renderIf(venue?.name, () => (
                  <div className={classes.venueNameWrap} data-testid="venue-name">
                    <Typography variant='h4' className={classes.venueName}>
                      {venue.name}
                    </Typography>
                    <div className={classes.venueUnderline}/>
                  </div>

                ))}

                <GawVenueList venueName={venue?.name} selectedTimeForOtherVenues={selectedTimeForOtherVenues}
                              filteredTimes={filteredTimes}
                              handleTimeSelection={(time) => handleTimeSelection(schedule, time)}/>
              </Grid>
            ))}
          </div>
        )
      })}
      {renderIf((allVenueApiCall && isEmpty(uniqBy(flatten(allTimes), 'time'))), () => (
        <Typography data-testid="erroe-message" className={classes.noAvailability} variant='h4' data-e2e="gaw-no-times-available">
          <Trans t={t}>
            No availability
          </Trans>
        </Typography>
      ))}

    </div>
  )
}

