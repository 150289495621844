import React from 'react';
import { connect } from 'react-redux';
import PaymentComplete from './index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IDispatchFromProps, IStateFromProps } from './types';
import { ThunkDispatch } from 'redux-thunk';
import { MessageService } from 'app/services/message/message.service';
import { IVenue } from 'app/models';
import { sortBy } from 'lodash';
import { IGroupedTablesBoxItem } from '../GroupedTablesBox/types';
import { IntlService } from 'app/services/intl/intlService';
import {servicePaymentType} from "shared-types/index";

const NS = 'PaymentCompleteContainer';

const mapStateToProps = ({widget}: IRootState): IStateFromProps => {

  const {payment} = widget.booking;
  const venueCurrency: string = widget.activeVenue ? (widget.activeVenue as IVenue).currency : null;
  const isPreauth = payment.paymentType === servicePaymentType.preAuth;
  // const currency: string = isPreauth ? payment.currency : venueCurrency;

  const amountValue: IGroupedTablesBoxItem = {
    order: isPreauth ? 2 : 0,
    name: `Amount`,
    value: (
      IntlService.currencyValue(isPreauth ? payment.amountDue : payment.amountPaid, venueCurrency)
    )
  }

  return {
    wrapperStyle: widget.wrapperStyle,
    theme: widget.theme,
    message: MessageService.get('finalWidgetScreenMessage', widget.activeVenue as IVenue),
    currency: venueCurrency,
    payment,
    tableData: {
      items: sortBy([
        amountValue,
        { order: 1, name: 'Transaction ID', value: payment.transactionId }
      ], 'order')
    }
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
  return {
    handleSomeClick: () => {
      // dispatch(WidgetActionsNS.xxx());
    }
  }
};

const PaymentCompleteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentComplete as any);

export default PaymentCompleteContainer;
